import Repository from "../../repository";

export default {
    GetListStation: (payload) => Repository.post(`/api/Stations/GetListStation`, payload),
    GetListStationActive: (payload) => Repository.post(`/api/Stations/GetListStationActive`, payload),
    GetStationById: (payload) => Repository.post(`/api/Stations/GetStationById`, payload),
    Add: (payload) => Repository.post(`/api/Stations/Add`, payload),
    Edit: (payload) => Repository.post(`/api/Stations/Edit`, payload),
    Delete: (payload) => Repository.post(`/api/Stations/Delete`, payload),
    ChangeStatus: (payload) => Repository.post(`/api/Stations/ChangeStatus`, payload),
    GetExport: (payload) => Repository.post(`/api/Stations/GetExport`, payload),
    GetByDataType: (payload) => Repository.post(`/api/Stations/GetByDataType`, payload),
}
