import moment from "moment/moment";
import momentTimeZone from "moment-timezone";
import { defaultDirection } from "../constants/config";
// import * as XLSX from 'xlsx';

export const checkArray = (array) => {
    return Array.isArray(array);
};
export const addCommas = (nStr) => {
    nStr += "";
    var x = nStr.split(".");
    var x1 = x[0];
    var x2 = x.length > 1 ? "." + x[1] : "";
    var rgx = /(\d+)(\d{3})/;
    while (rgx.test(x1)) {
        x1 = x1.replace(rgx, "$1" + "," + "$2");
    }
    return x1 + x2;
};

export const mapOrder = (array, order, key) => {
    array.sort(function (a, b) {
        var A = a[key];
        var B = b[key];
        if (order.indexOf(A + "") > order.indexOf(B + "")) {
            return 1;
        } else {
            return -1;
        }
    });
    return array;
};

export const getDateWithFormat = () => {
    const today = new Date();
    let dd = today.getDate();
    let mm = today.getMonth() + 1; // January is 0!

    var yyyy = today.getFullYear();
    if (dd < 10) {
        dd = "0" + dd;
    }
    if (mm < 10) {
        mm = "0" + mm;
    }
    return dd + "." + mm + "." + yyyy;
};

export const getCurrentTime = () => {
    const now = new Date();
    return now.getHours() + ":" + now.getMinutes();
};

export const ThemeColors = () => {
    let rootStyle = getComputedStyle(document.body);
    return {
        themeColor1: rootStyle.getPropertyValue("--theme-color-1").trim(),
        themeColor2: rootStyle.getPropertyValue("--theme-color-2").trim(),
        themeColor3: rootStyle.getPropertyValue("--theme-color-3").trim(),
        themeColor4: rootStyle.getPropertyValue("--theme-color-4").trim(),
        themeColor5: rootStyle.getPropertyValue("--theme-color-5").trim(),
        themeColor6: rootStyle.getPropertyValue("--theme-color-6").trim(),
        themeColor1_10: rootStyle.getPropertyValue("--theme-color-1-10").trim(),
        themeColor2_10: rootStyle.getPropertyValue("--theme-color-2-10").trim(),
        themeColor3_10: rootStyle.getPropertyValue("--theme-color-3-10").trim(),
        themeColor4_10: rootStyle.getPropertyValue("--theme-color-3-10").trim(),
        themeColor5_10: rootStyle.getPropertyValue("--theme-color-3-10").trim(),
        themeColor6_10: rootStyle.getPropertyValue("--theme-color-3-10").trim(),
        primaryColor: rootStyle.getPropertyValue("--primary-color").trim(),
        foregroundColor: rootStyle.getPropertyValue("--foreground-color").trim(),
        separatorColor: rootStyle.getPropertyValue("--separator-color").trim(),
    };
};

export const chartTooltip = {
    backgroundColor: ThemeColors().foregroundColor,
    titleFontColor: ThemeColors().primaryColor,
    borderColor: ThemeColors().separatorColor,
    borderWidth: 0.5,
    bodyFontColor: ThemeColors().primaryColor,
    bodySpacing: 10,
    xPadding: 15,
    yPadding: 15,
    cornerRadius: 0.15,
};

export const centerTextPlugin = {
    afterDatasetsUpdate: function (chart) { },
    beforeDraw: function (chart) {
        var width = chart.chartArea.right;
        var height = chart.chartArea.bottom;
        var ctx = chart.chart.ctx;
        ctx.restore();

        var activeLabel = chart.data.labels[0];
        var activeValue = chart.data.datasets[0].data[0];
        var dataset = chart.data.datasets[0];
        var meta = dataset._meta[Object.keys(dataset._meta)[0]];
        var total = meta.total;

        var activePercentage = parseFloat(((activeValue / total) * 100).toFixed(1));
        activePercentage = chart.legend.legendItems[0].hidden ? 0 : activePercentage;

        if (chart.pointAvailable) {
            activeLabel = chart.data.labels[chart.pointIndex];
            activeValue = chart.data.datasets[chart.pointDataIndex].data[chart.pointIndex];

            dataset = chart.data.datasets[chart.pointDataIndex];
            meta = dataset._meta[Object.keys(dataset._meta)[0]];
            total = meta.total;
            activePercentage = parseFloat(((activeValue / total) * 100).toFixed(1));
            activePercentage = chart.legend.legendItems[chart.pointIndex].hidden ? 0 : activePercentage;
        }

        ctx.font = "36px Nunito, sans-serif";
        ctx.fillStyle = ThemeColors().primaryColor;
        ctx.textBaseline = "middle";

        var text = activePercentage + "%";
        var textX = Math.round((width - ctx.measureText(text).width) / 2);
        var textY = height / 2;
        ctx.fillText(text, textX, textY);

        ctx.font = "14px Nunito, sans-serif";
        ctx.textBaseline = "middle";

        var text2 = activeLabel;
        var textX2 = Math.round((width - ctx.measureText(text2).width) / 2);
        var textY2 = height / 2 - 30;
        ctx.fillText(text2, textX2, textY2);

        ctx.save();
    },
    beforeEvent: function (chart, event, options) {
        var firstPoint = chart.getElementAtEvent(event)[0];

        if (firstPoint) {
            chart.pointIndex = firstPoint._index;
            chart.pointDataIndex = firstPoint._datasetIndex;
            chart.pointAvailable = true;
        }
    },
};
export const getDirection = () => {
    let direction = defaultDirection;
    if (localStorage.getItem("direction")) {
        const localValue = localStorage.getItem("direction");
        if (localValue == "rtl" || localValue == "ltr") {
            direction = localValue;
        }
    }
    return {
        direction,
        isRtl: direction == "rtl",
    };
};

export const setDirection = (localValue) => {
    let direction = "ltr";
    if (localValue == "rtl" || localValue == "ltr") {
        direction = localValue;
    }
    localStorage.setItem("direction", direction);
};

/** Đệ quy */
/**
 * It takes an array of objects, and returns a new array of objects with a new property called "Subs"
 * that contains an array of objects that are children of the parent object.
 * @param defaultArray - The array that you want to convert to a tree structure.
 * @param parentID - The parent ID of the current array
 * @param resultArray - The array that will be returned.
 * @param parentKey - The key that holds the parent ID
 * @param childrenKey - The key that holds the children of the current element
 * @returns An array of objects with the following structure:
 * [
 *   {
 *     "ID": 1,
 *     "Name": "A",
 *     "ParentID": null,
 *     "Subs": [
 *       {
 *         "ID": 2,
 *         "Name": "B",
 *         "ParentID": 1,
 *         "Subs": [
 *           {
 */
export const recursiveArray = (defaultArray, parentID, resultArray, parentKey, childrenKey) => {
    if (!defaultArray || defaultArray?.length == 0) return;
    defaultArray.forEach((element) => {
        if (element[parentKey] != parentID) return;
        let newArray = [];
        recursiveArray(defaultArray, element[childrenKey], newArray, parentKey, childrenKey);
        resultArray.push({
            ...element,
            Subs: newArray,
        });
    });
};


export const recursiveTreeselect = (defaultArray, parentID, resultArray, parentKey, childrenKey, valueLabel, keyLabel = "RegionName") => {
    if (!defaultArray || defaultArray?.length == 0) return;
    defaultArray.forEach((element) => {
        if (element[parentKey] != parentID) return;
        let newArray = [];
        recursiveTreeselect(defaultArray, element[childrenKey], newArray, parentKey, childrenKey, valueLabel, keyLabel);
        let object = {};
        if (!newArray || newArray?.length == 0) {
            object = {
                id: element[childrenKey],
                parent: element[parentKey],
                label: valueLabel && element[valueLabel] > 0 ? `${element[keyLabel]} (${element[valueLabel]})` : element[keyLabel],
            };
        } else {
            object = {
                id: element[childrenKey],
                parent: element[parentKey],
                label: valueLabel && element[valueLabel] > 0 ? `${element[keyLabel]} (${element[valueLabel]})` : element[keyLabel],
                children: newArray,
            };
        }
        resultArray.push(object);
    });
};
/**Hàm tương tự dành cho RouteID */
export const recursiveTreeselectRouteName = (defaultArray, parentID, resultArray, parentKey, childrenKey, value = null) => {
    if (!defaultArray || defaultArray?.length == 0) return;
    defaultArray.forEach((element) => {
        if (element[parentKey] != parentID) return;
        let newArray = [];
        recursiveTreeselectRouteName(defaultArray, element[childrenKey], newArray, parentKey, childrenKey, value);
        let object = {};
        if (!newArray || newArray?.length == 0) {
            object = {
                id: element[childrenKey],
                parent: element[parentKey],
                label: value && element[value] > 0 ? `${element["TreeRouteName"]} (${element[value]})` : element["TreeRouteName"],
            };
        } else {
            object = {
                id: element[childrenKey],
                parent: element[parentKey],
                label: value && element[value] > 0 ? `${element["TreeRouteName"]} (${element[value]})` : element["TreeRouteName"],
                children: newArray,
            };
        }
        resultArray.push(object);
    });
};

export const recursiveTreeTable = (defaultArray, parentID, resultArray, parentKey, childrenKey, keyCollapse, moduleTable = null) => {
    if (!defaultArray || defaultArray?.length == 0) return;
    defaultArray.forEach((element) => {
        if (element[parentKey] != parentID) return;
        let newArray = [];
        recursiveTreeTable(defaultArray, element[childrenKey], newArray, parentKey, childrenKey, keyCollapse == "" ? keyCollapse + resultArray.length : keyCollapse + "-" + resultArray.length, moduleTable);
        if (moduleTable == "TREE-REGION-DASHBOARD") {
            resultArray.push({
                key: keyCollapse == "" ? keyCollapse + resultArray.length : keyCollapse + "-" + resultArray.length,
                data: {
                    id: element["ID"],
                    name: element["Name"],
                    count: element["Counting"],
                },
                children: newArray,
            });
        } else {
            resultArray.push({
                key: keyCollapse == "" ? keyCollapse + resultArray.length : keyCollapse + "-" + resultArray.length,
                data: {
                    id: element["RegionID"],
                    name: element["RegionName"],
                    note: element["Note"] ? element["Note"] : null,
                    isActive: element["IsActive"] ? element["IsActive"] : true,
                },
                children: newArray,
            });
        }
    });
};
/**Hàm tương tự dành cho RouteID */
export const recursiveTreeTableRouteParent = (defaultArray, parentID, resultArray, parentKey, childrenKey, keyCollapse, moduleTable = null) => {
    if (!defaultArray || defaultArray?.length == 0) return;
    defaultArray.forEach((element) => {
        if (element[parentKey] != parentID) return;
        let newArray = [];
        recursiveTreeTableRouteParent(defaultArray, element[childrenKey], newArray, parentKey, childrenKey, keyCollapse == "" ? keyCollapse + resultArray.length : keyCollapse + "-" + resultArray.length, moduleTable);
        if (moduleTable == "TREE-REGION-DASHBOARD") {
            resultArray.push({
                key: keyCollapse == "" ? keyCollapse + resultArray.length : keyCollapse + "-" + resultArray.length,
                data: {
                    id: element["ID"],
                    name: element["Name"],
                    count: element["Counting"],
                },
                children: newArray,
            });
        } else {
            resultArray.push({
                key: keyCollapse == "" ? keyCollapse + resultArray.length : keyCollapse + "-" + resultArray.length,
                data: {
                    id: element["TreeRouteID"],
                    name: element["TreeRouteName"],
                    note: element["Note"] ? element["Note"] : null,
                    isActive: element["IsActive"] ? element["IsActive"] : 0,
                },
                children: newArray,
            });
        }
    });
};
/** Đệ quy input select parentid trong form group customer **/
export const recursiveTreeSelectParentCustomerGroup = (a, i, r, c) => {
    if (!a) return
    a.forEach((element) => {
        if (element['ParentID'] != i || element['IsActive'] == false) return
        let n = []
        recursiveTreeSelectParentCustomerGroup(
            a,
            element['GroupID'],
            n,
            c + 1,
        )
        if (n.length > 0) {
            r.push({
                id: element['GroupID'],
                label: element['GroupName'],
                parent: element['ParentID'],
                key: c,
                children: n,
            })
        } else {
            r.push({
                id: element['GroupID'],
                label: element['GroupName'],
                parent: element['ParentID'],
                key: c,
            })
        }
    })
};
/** Khử đệ quy */
/**
 * The function is recursive because it calls itself.
 * @param defaultArray - the array that you want to de-recurse
 * @param resultArray - The array that will be returned.
 * @returns The resultArray is being returned.
 */
export const deRecursive = (defaultArray, resultArray) => {
    if (!defaultArray || defaultArray?.length == 0) return;
    defaultArray.forEach((element) => {
        resultArray.push(element);
        deRecursive(element["Subs"], resultArray);
    });
};

/** MomentJS function */
/**
 * The list function convert using moment.js
 */
export const getDurationDay = (now, end) => moment(now).diff(end, "days");

export const getSubtractNumber = (now, number) => moment(now).subtract(number, "days");

export const getTimeNow = () => moment(new Date()).format("HH:mm");

export const getDateNow = () => moment(new Date()).format("DD/MM/YYYY");

export const getMinutes = () => moment(new Date()).format("mm");

export const getHours = () => moment(new Date()).format("HH");

export const getDay = () => moment(new Date()).format("DD");

export const getMonth = () => moment(new Date()).format("MM");

export const getDateTimeNow = () => moment(new Date()).format("HH:mm DD/MM/YYYY");

export const convertDay = (data) => moment(data, "YYYY-MM-DD'T'HH:mm:ss").format("DD");

export const convertMonth = (data) => moment(data, "YYYY-MM-DD'T'HH:mm:ss").format("MM");

export const convertYear = (data) => moment(data, "YYYY-MM-DD'T'HH:mm:ss").format("YYYY");

export const convertDate = (data) => moment(data, "YYYY-MM-DD'T'HH:mm:ss").format("DD/MM/YYYY");

export const convertDateTime = (string) => moment(string, "YYYY-MM-DD'T'HH:mm:ss").format("HH:mm:ss DD/MM/YYYY")

export const convertDateTimeFull = (data) => moment(data, "YYYY-MM-DD'T'HH:mm:ss").format("HH:mm:ss DD/MM/YYYY");

export const convertDateTimeFullToDatetimeLocal = (data) => moment(data, "YYYY-MM-DD'T'HH:mm:ss").format("YYYY-MM-DDTHH:MM");

export const convertDatetimeLocalToDateTimeAPI = (data) => moment(data, "YYYY-MM-DDTHH:MM").format("YYYY-MM-DD HH:MM");

export const convertDateTimeNonSecond = (data) => moment(data, "YYYY-MM-DD'T'HH:mm:ss").format("HH:mm DD/MM/YYYY");

export const convertDateToTimeStamp = (data) => momentTimeZone(data, "YYYY/MM/DD").tz("Asia/Ho_Chi_Minh").format().valueOf();

export const convertDateToPicker = (data) => moment(data, "DD/MM/YYYY").format("YYYY-MM-DD");

export const convertPickerToDate = (data) => moment(data, "YYYY-MM-DD").format("DD/MM/YYYY");

export const convertPickerToYear = (data) => moment(data, "YYYY-MM-DD").format("YYYY/MM/DD");

export const convertMomentDynamic = (data, string, _string) => moment(data, string).format(_string);

/**Thứ hiện tại trong tuần */
export const getDayName = () => {
    var date = new Date();
    var current_day = date.getDay();
    if (current_day == 0) {
        return "Chủ nhật";
    } else {
        var increase_index = current_day + 1;
        return "Thứ " + increase_index;
    }
};
/** Convert tiền tệ **/
export const convertFloat = (n) => {
    Number(n)
        .toFixed()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
}
export const convertFloatWithLegion = (n, l) => {
    Number(n)
        .toFixed()
        .replace(/\B(?=(\d{3})+(?!\d))/g, l && l.toUpperCase() == "VN" ? "." : ",")
}
export const convertMoney = (n, c) => {
    if (!n) return 0;
    return c?.currency === "VN" ? Number(n).toLocaleString("vi-VN") : Number(n).toLocaleString("en-US");
}
export const revertFloat = (n) => { Number(n.replace(/[^0-9.-]+/g, "")) }

export const revertFloatWithLegion = (n, l) => (l && l.toUpperCase() == "VN" ? Number(n.replace(/[^0-9,-]+/g, "")) : Number(n.replace(/[^0-9.-]+/g, "")))

/** Chuyển đổi Tiếng Việt có dấu thành không dấu */

/** Convert type function */
/**
 * The list function convert number, string, decimal, money,..
 */
export const convertBooleanToBit = (value) => (value ? 1 : 0);

export const convertBitToBoolean = (value) => !!value;

export const convertFloatByCurrency = (number, currency, decimal) =>
    currency == "vn"
        ? Number(number)
            .toFixed(decimal)
            .replace(/\B(?=(\d{3})+(?!\d))/g, ".")
        : Number(number)
            .toFixed(decimal)
            .replace(/\B(?=(\d{3})+(?!\d))/g, ",");

export const revertFloatByCurrency = (number, currency) => (currency == "vn" ? Number(number.replace(/[^0-9,-]+/g, "")) : Number(number.replace(/[^0-9.-]+/g, "")));

export const convertMoneyByCurrency = (number, currency) => {
    if (!number) return 0;
    return currency?.currency == "VN" ? Number(number).toLocaleString("vi-VN") : Number(number).toLocaleString("en-US");
};
/** hàm chuyển từ number về lại sang String dạng tiền tệ , truyền vào isDecima bằng true nếu muốn nhập số thập phân **/
export const convertMoneyInput = (value = 0, isDecimal = false) => {
    let format = JSON.parse(localStorage.getItem("user")) ? JSON.parse(localStorage.getItem("user")).FormatCurrency : "vn";
    if (format == "vn") {
        if (isDecimal == false) {
            const numericValue = value?.toString()?.replace(/[\.,]/g, "");
            return numericValue?.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        } else {
            const numericValue = value?.toString()?.replace(/[\,]/g, "");
            return numericValue?.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        }
    } else {
        if (isDecimal == false) {
            const numericValue = value?.toString()?.replace(/[\.,]/g, "");
            return numericValue?.replace(/\B(?=(\d{3})+(?!\d))/g, ".");
        } else {
            const numericValue = value?.toString()?.replace(/[\.]/g, "");
            return numericValue?.replace(/\B(?=(\d{3})+(?!\d))/g, ".");
        }
    }
};
/** hàm chuyển từ string tiền tệ về lại number, truyền vào isDecima bằng true nếu muốn đổi ra số thập phân **/
export const convertCurrencyToNumber = (value = 0, isDecimal = false) => {
    let format = JSON.parse(localStorage.getItem("user")) ? JSON.parse(localStorage.getItem("user")).FormatCurrency : "vn";
    if (format == "vn") {
        // Loại bỏ dấu phẩy trong chuỗi
        const numericString = value.replace(/[\,]/g, "");
        if (isDecimal) {
            // Chuyển chuỗi thành số thực và trả về
            return parseFloat(numericString);
        } else {
            // Chuyển chuỗi thành số thực và trả về
            return parseInt(numericString);
        }
    } else {
        // Loại bỏ dấu chấm trong chuỗi
        const numericString = value.replace(/[\.]/g, "");
        if (isDecimal) {
            // Chuyển chuỗi thành số thực và trả về
            return parseFloat(numericString.replace(/[\,]/g, "."));
        } else {
            // Chuyển chuỗi thành số thực và trả về
            return parseInt(numericString);
        }
    }
};
export const showExtensionFormEdit = (data) => {
    if (!data) return;
    let nObject = {};
    if (data["Properties"].length > 0) {
        data["Properties"].forEach((item) => {
            if (item["IsHide"] === 0) {
                let key = item["ClName"];
                let formula = item["Formula"] && item["Formula"].length > 0 ? item["Formula"] : null;
                switch (item["TypeCol"].toUpperCase()) {
                    case "DATE":
                        nObject[key] = [item["ShwName"], handleCustom.convertDateForm(data[key]), item["TypeCol"], formula];
                        break;
                    case "DATETIME":
                        nObject[key] = [item["ShwName"], handleCustom.convertDateTimeNonSecond(data[key]), item["TypeCol"], formula];
                        break;
                    case "DATETIMEHHMM":
                        nObject[key] = [item["ShwName"], handleCustom.getDateTimeNow(data[key]), item["TypeCol"], formula];
                        break;
                    default:
                        if (item["TypeCol"].toUpperCase().search("NUMBER") > -1) {
                            nObject[key] = [item["ShwName"], parseInt(data[key]), item["TypeCol"], formula];
                        } else {
                            nObject[key] = [item["ShwName"], data[key], item["TypeCol"], formula];
                        }
                        break;
                }
            }
        });
    }
    return nObject;
};
export const fieldTableWithKey = (array) => {
    if (!array || array.length == 0) return;

    const classByType = {
        NUMBER: "text-right pt-3",
        MUTEDNUMBER: "text-right text-muted pt-3",
        STRING: "text-left pt-3 position-relative",
        "STRING-TREE": "text-left pt-3 position-relative",
        MUTEDSTRING: "text-left text-muted pt-3",
        PHONE: "text-center text-muted pt-3",
        EMAIL: "text-center text-muted pt-3",
        MUTEDNUMBERCENTER: "text-center text-muted pt-3",
        MUTEDSTRINGCENTER: "text-center text-muted pt-3",
    };

    return array
        .filter((item) => item)
        .map((item) => ({
            key: item["ClName"],
            label: item["ShwName"],
            sortable: true,
            sortNum: item["SortNum"],
            typeValue: item["TypeCol"],
            tdClass: classByType[item["TypeCol"].toUpperCase()] || (item["TypeCol"].toUpperCase().includes("FLOAT") || item["TypeCol"].toUpperCase().includes("MONEY") ? "text-right pt-3" : "text-center"),
        }));
};
export const fieldTableWithKeyAndData = (array = [], _array = []) => {
    if (!_array || _array.length === 0) return [];
    const mapField = (item, _item) => {
        const typeCol = (_item && _item["TypeCol"]) || "STRING";

        const commonProps = {
            key: item,
            label: item?.length > 0 && item[0] === "*" ? item.slice(1) : (_item && _item["ShwName"]) || `cl.[${item}]`,
            sortable: !_item || (!_item.IsHide && _item.IsHide !== 1),
            sortNum: _item && _item["SortNum"],
            typeCol,
        };

        switch (typeCol.toUpperCase()) {
            case "NUMBER":
                commonProps.tdClass = "text-right";
                break;
            case "MUTEDNUMBER":
                commonProps.tdClass = "text-right text-muted";
                break;
            case "STRING":
            case "STRING-TREE":
                commonProps.tdClass = "text-left position-relative";
                break;
            case "STRINGRIGHT":
                commonProps.tdClass = "text-right pt-2";
                break;
            case "MUTEDSTRING":
                commonProps.tdClass = "text-left text-muted";
                break;
            case "PHONE":
            case "EMAIL":
            case "MUTEDNUMBERCENTER":
            case "MUTEDSTRINGCENTER":
                commonProps.tdClass = "text-center text-muted";
                break;
            case "DATETIME":
                commonProps.tdClass = "text-center text-muted text-small";
                break;
            default:
                if (typeCol.toUpperCase().includes("FLOAT") || typeCol.toUpperCase().includes("MONEY")) {
                    commonProps.tdClass = "text-right";
                } else {
                    commonProps.tdClass = "text-center";
                    commonProps.sortable = false;
                }
                break;
        }

        return commonProps;
    };

    if (array?.length > 0) {
        return array.reduce((acc, item) => {
            const match = _array.find((_item) => _item["ClName"].toUpperCase() === item.toUpperCase());
            if (match) {
                if (!match.IsHide || match.IsHide !== 1) {
                    acc.push(mapField(item, match));
                }
            } else if (item.toUpperCase() !== "OID" && item.toUpperCase() !== "ID") {
                acc.push(mapField(item, null));
            }
            return acc;
        }, []);
    } else {
        return _array.reduce((acc, x) => {
            if (!x.IsHide || x.IsHide !== 1) {
                acc.push(mapField(x["ClName"], x));
            }
            return acc;
        }, []);
    }
};


export const convertTypeWithNameColumn = (data = [], action = false, actionIndex = null) => {
    if (data.length == 0) return;
    const arrayCutAndInsert = (array, cutIndex, insertIndex) => {
        if (array.length < 2 || cutIndex < 0 || cutIndex >= array.length || insertIndex < 0 || insertIndex >= array.length) return array;
        const insertElement = (_array, index, element) => {
            if (index < 0 || index > _array.length) {
                return _array;
            }
            _array.splice(index, 0, element);
            return _array;
        };
        return insertElement(array.slice(0, cutIndex), insertIndex, array[cutIndex]);
    };
    const _data = data.map((item) => {
        const obj = { ...item };
        if (!item.key.toUpperCase().includes("IS")) {
            if (item.key.toUpperCase().includes("ADDRESS") || item.key.toUpperCase().includes("ROUTE") || item.key.toUpperCase().includes("DESCRIPTION")) {
                if (item.key.toUpperCase().includes("DATE") || item.key.toUpperCase().includes("CODE")) {
                    obj.thStyle = { "min-width": "15em" };
                } else if (item.key.toUpperCase().includes("BROADCAST") || item.key.toUpperCase().includes("IP") || item.key.toUpperCase().includes("PORT")) {
                    obj.thStyle = { "min-width": "7em" };
                } else {
                    obj.thStyle = { "min-width": "25em" };
                }
            } else if ((item.key.toUpperCase().includes("CUSTOMER") && item.key.toUpperCase().includes("NAME")) || (item.key.toUpperCase().includes("CUS") && item.key.toUpperCase().includes("NAME")) || (item.key.toUpperCase().includes("DEPOT") && item.key.toUpperCase().includes("NAME")) || (item.key.toUpperCase().includes("CONTACT") && item.key.toUpperCase().includes("NAME")) || (item.key.toUpperCase().includes("FEE") && item.key.toUpperCase().includes("NAME")) || (item.key.toUpperCase().includes("SPECIAL") && item.key.toUpperCase().includes("NAME"))) {
                obj.thStyle = { "min-width": "15em" };
            } else if (item.key.toUpperCase().includes("LICENSEPLATES")) {
                obj.thStyle = { "min-width": "8em" };
            } else if (item.key.toUpperCase().includes("NOTE")) {
                obj.thStyle = { "min-width": "12em" };
            } else if ((item.key.toUpperCase().includes("DRIVER") && item.key.toUpperCase().includes("NAME")) || item.key.toUpperCase().includes("ENTRYNAME") || (item.key.toUpperCase().includes("DEVICE") && item.key.toUpperCase().includes("NAME"))) {
                obj.thStyle = { "min-width": "12em" };
            } else if ((item.key.toUpperCase().includes("STATION") && item.key.toUpperCase().includes("NAME"))) {
                obj.thStyle = { "min-width": "20em" };
            } else if ((item.key.toUpperCase().includes("BUS") && item.key.toUpperCase().includes("NAME"))) {
                obj.thStyle = { "min-width": "25em" };
            } else if (item.key.toUpperCase().includes("BILLNOEMPTY")) {
                obj.thStyle = { "min-width": "12em" };
            } else if (["STATUSNAME", "STATUSTIME"].includes(item.key.toUpperCase())) {
                obj.thStyle = { "min-width": "13em" };
            } else if (item.key.toUpperCase().includes("OID")) {
                obj.thStyle = { "width": "12em" };
            } else if (item.key.toUpperCase().includes("STATIONPHONE")) {
                obj.thStyle = { "width": "10em" };
            } else if (["RECEIPT_AMNT", "TOTALAMNT"].includes(item.key.toUpperCase())) {
                obj.thStyle = { "min-width": "11em" };
            } else if (item.key.toUpperCase().includes("DATE")) {
                if (item.key.toUpperCase() == "CREATEDATE") {
                    obj.thStyle = { "width": "13em" };
                } else {
                    obj.thStyle = { "width": "9em" };
                }
            } else {
                obj.thStyle = { "min-width": "5em" };
            }
        } else {
            if (["ISACTIVE", "ISDEPLOY", "ISSTATION", "ISCHECK", "ISLOCK", "ISCLOSE", "ISSUPPORT"].includes(item.key.toUpperCase())) {
                obj.thStyle = { "width": "4em" };
            }
        }
        return obj;
    });
    if (action) {
        _data.push({
            key: "Actions",
            sortable: false,
            label: "Thao tác",
            sortNum: 9999,
            typeCol: "action",
            tdClass: "text-center",
        });
    }
    if (actionIndex != null) {
        return arrayCutAndInsert(_data, _data.length - 1, actionIndex);
    }
    return _data;
};

export const mergeKeyDynamic = (arrKey, arrType, sortable = true) => {
    if (!arrKey || !arrType) return;
    let result = [];
    arrKey.map((key) => {
        let obj = {
            key: key,
            sortable: sortable,
        };
        let objType = arrType.find((e) => e["ClName"].toUpperCase() == key.toUpperCase());
        if (objType && objType["IsHide"] == 0) {
            obj.label = objType["ShwName"];
            obj.sortNum = objType["SortNum"];
            obj.typeCol = objType["TypeCol"];
            switch (objType["TypeCol"].toUpperCase()) {
                case "NUMBER":
                    obj.tdClass = "text-right pt-2";
                    break;
                case "NUMBERBOLDVN":
                case "NUMBERBOLD":
                    obj.tdClass = "text-right pt-2 font-weight-bold";
                    break;
                case "NUMBERBOLDCENTER":
                    obj.tdClass = "text-center pt-2 font-weight-bold";
                    break;
                case "MUTEDNUMBER":
                    obj.tdClass = "text-right text-muted pt-3";
                    break;
                case "NOTE":
                    obj.tdClass = "text-left pt-2";
                    break;
                case "STRING":
                    obj.tdClass = "text-left pt-2";
                    break;
                case "STRINGCENTER":
                    obj.tdClass = "text-center pt-2";
                    break;
                case "STRINGRIGHT":
                    obj.tdClass = "text-right pt-2";
                    break;
                case "STRING-TREE":
                    obj.tdClass = "text-left pt-2 position-relative";
                    break;
                case "MUTEDSTRING":
                    obj.tdClass = "text-left text-muted pt-2";
                    break;
                case "AVATAR":
                case "IMAGE":
                    obj.tdClass = "text-center p-1";
                    break;
                case "PHONE":
                case "EMAIL":
                case "MUTEDNUMBERCENTER":
                case "MUTEDSTRINGCENTER":
                    obj.tdClass = "text-center text-muted pt-2";
                    break;
                default:
                    if (objType["TypeCol"].toUpperCase().search("FLOAT") > -1 || objType["TypeCol"].toUpperCase().search("MONEY") > -1) {
                        obj.tdClass = "text-right pt-2";
                    } else {
                        obj.tdClass = "text-center";
                        obj.sortable = false;
                    }
                    break;
            }
            result.push(obj);
        } else if (objType && objType["IsHide"] == 1) {
        } else {
            if (key.toUpperCase() != "OID" && key.toUpperCase() != "ID") {
                obj.label = "cl." + key;
                obj.sortNum = 9999;
                obj.typeCol = "STRING";
                obj.tdClass = "text-left";
                obj.sortable = false;
                result.push(obj);
            }
        }
    });
    return result;
};

export const recursiveRegionParent = (a, i, r, c) => {
    if (!a) return;
    a.forEach((element) => {
        if (element["RegionParentID"] != i || element["IsActive"] == false) return;
        let n = [];
        recursiveRegionParent(a, element["RegionID"], n, c + 1);
        if (n.length > 0) {
            r.push({
                id: element["RegionID"],
                label: element["RegionName"],
                parent: element["RegionParentID"],
                key: c,
                children: n,
            });
        } else {
            r.push({
                id: element["RegionID"],
                label: element["RegionName"],
                parent: element["RegionParentID"],
                key: c,
            });
        }
    });
};

/** Tương tự hàm trên nhưng do nam API trả về khác tên  */
export const recursiveTreeRouteParent = (a, i, r, c) => {
    if (!a) return;
    a.forEach((element) => {
        if (element["ParentID"] != i || element["IsActive"] == false) return;
        let n = [];
        recursiveTreeRouteParent(a, element["TreeRouteID"], n, c + 1);
        if (n.length > 0) {
            r.push({
                id: element["TreeRouteID"],
                label: element["TreeRouteName"],
                parent: element["ParentID"],
                note: element["Note"],
                key: c,
                children: n,
            });
        } else {
            r.push({
                id: element["TreeRouteID"],
                label: element["TreeRouteName"],
                parent: element["ParentID"],
                note: element["Note"],
                key: c,
            });
        }
    });
};

export const convertJSONTable = (fieldTable, dataTable) => {
    if (fieldTable?.length > 0 && dataTable?.length > 0) {
        let data = [];
        dataTable.forEach((x) => {
            let obj = {};
            fieldTable.forEach((y) => {
                let _obj = {};
                switch (y.typeCol.toUpperCase()) {
                    case "DATE":
                        _obj = {
                            [y.label]: convertDate(x[y.key]),
                        };
                        break;
                    case "DATE-TIME":
                    case "DATETIME":
                        _obj = {
                            [y.label]: convertDateTimeNonSecond(x[y.key]),
                        };
                        break;
                    case "BIT":
                    case "ISCHECK":
                        if (y.key.toUpperCase() === "ISACTIVE") {
                            _obj = {
                                [y.label]: x[y.key] === 1 ? "Đang dùng" : "Không dùng",
                            };
                        } else {
                            _obj = {
                                [y.label]: x[y.key] === 1 ? "Có" : "Không",
                            };
                        }
                        break;
                    default:
                        _obj = {
                            [y.label]: x[y.key],
                        };
                        break;
                }
                Object.assign(obj, _obj);
            });
            data.push(obj);
        });
        return data;
    }
    return [];
}

export const exportExcel = (nameFile = "ExcelFileExport", dataFile, nameSheet = "Sheet1") => {
    const worksheet = XLSX.utils.json_to_sheet(dataFile)
    const workbook = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(workbook, worksheet, nameSheet)
    XLSX.writeFile(workbook, nameFile + '.xlsx')
};

export const exportExcelWidthJSON = (fieldTable, dataTable, nameFile = "ExcelFileExport", nameSheet = "Sheet1") => {
    const dataFile = convertJSONTable(fieldTable, dataTable)
    const worksheet = XLSX.utils.json_to_sheet(dataFile)
    const workbook = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(workbook, worksheet, nameSheet)
    XLSX.writeFile(workbook, nameFile + '.xlsx')
};

/**Xuất file excel
 * coder: Nguyễn Thư
 * used: smart led report
 */

export const printExcel = (table, name, from = null, to = null, page = null) => {
    let nameFile;
    if (!from || !to) {
        if (page) {
            nameFile = name + " " + page + ".xls";
        } else {
            nameFile = name + ".xls";
        }
    } else {
        if (page) {
            nameFile = name + " " + from + "-" + to + " " + page + ".xls";
        } else {
            nameFile = name + " " + from + "-" + to + ".xls";
        }
    }
    let uri = "data:application/vnd.ms-excel;base64,",
        template = '<html xmlns:o="urn:schemas-microsoft-com:office:office" xmlns:x="urn:schemas-microsoft-com:office:excel" xmlns="http://www.w3.org/TR/REC-html40">' + "<head>" + "<!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>{worksheet}</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]-->" + '<meta http-equiv="content-type" content="text/plain; charset=UTF-8"/>' + "</head>" + "<body><table>{table}</table></body>" + "</html>",
        base64 = function (s) {
            return window.btoa(unescape(encodeURIComponent(s)));
        },
        format = function (s, c) {
            return s.replace(/{(\w+)}/g, function (m, p) {
                return c[p];
            });
        };
    if (!table.nodeType) table = document.getElementById(table);
    let ctx = { worksheet: name || "Worksheet", table: table.innerHTML };
    let a = document.createElement("a");
    a.href = uri + base64(format(template, ctx));
    a.download = nameFile;
    a.click();
};

/** Chuyển đổi Tiếng Việt có dấu thành không dấu */
export const removeVietnameseAccent = (str) => {
    str = str.toLowerCase();
    // Chuyển các ký tự có dấu thành các ký tự không dấu
    str = str.replace(/[áàảãạâấầẩẫậăắằẳẵặ]/g, "a");
    str = str.replace(/[éèẻẽẹêếềểễệ]/g, "e");
    str = str.replace(/[iíìỉĩị]/g, "i");
    str = str.replace(/[óòỏõọôốồổỗộơớờởỡợ]/g, "o");
    str = str.replace(/[úùủũụưứừửữự]/g, "u");
    str = str.replace(/[ýỳỷỹỵ]/g, "y");
    str = str.replace(/[đ]/g, "d");
    // // Loại bỏ các ký tự đặc biệt và khoảng trắng
    // str = str.replace(/[^a-z0-9\s]/g, '')
    // // Loại bỏ khoảng trắng thừa
    // str = str.replace(/\s+/g, ' ').trim()
    return str;
};

/** Kiểm tra Tiếng Việt có dấu */
export const hasVietnameseCharacters = (str) => {
    const vietnameseRegex = /[\u00C0-\u1EF9\u1EFA-\u1EFF]/; // Biểu thức chính quy để phát hiện ký tự tiếng Việt
    return vietnameseRegex.test(str);
};

/** Hàm lấy giá trị độ sáng của màu từ mã hex và tên màu **/
export const getBrightness = (value) => {
    let brightness;
    if (value[0] != "#" && !value.includes("rgb")) {
        let colours = {
            aliceblue: "#f0f8ff",
            antiquewhite: "#faebd7",
            aqua: "#00ffff",
            aquamarine: "#7fffd4",
            azure: "#f0ffff",
            beige: "#f5f5dc",
            bisque: "#ffe4c4",
            black: "#000000",
            blanchedalmond: "#ffebcd",
            blue: "#0000ff",
            blueviolet: "#8a2be2",
            brown: "#a52a2a",
            burlywood: "#deb887",
            cadetblue: "#5f9ea0",
            chartreuse: "#7fff00",
            chocolate: "#d2691e",
            coral: "#ff7f50",
            cornflowerblue: "#6495ed",
            cornsilk: "#fff8dc",
            crimson: "#dc143c",
            cyan: "#00ffff",
            darkblue: "#00008b",
            darkcyan: "#008b8b",
            darkgoldenrod: "#b8860b",
            darkgray: "#a9a9a9",
            darkgreen: "#006400",
            darkkhaki: "#bdb76b",
            darkmagenta: "#8b008b",
            darkolivegreen: "#556b2f",
            darkorange: "#ff8c00",
            darkorchid: "#9932cc",
            darkred: "#8b0000",
            darksalmon: "#e9967a",
            darkseagreen: "#8fbc8f",
            darkslateblue: "#483d8b",
            darkslategray: "#2f4f4f",
            darkturquoise: "#00ced1",
            darkviolet: "#9400d3",
            deeppink: "#ff1493",
            deepskyblue: "#00bfff",
            dimgray: "#696969",
            dodgerblue: "#1e90ff",
            firebrick: "#b22222",
            floralwhite: "#fffaf0",
            forestgreen: "#228b22",
            fuchsia: "#ff00ff",
            gainsboro: "#dcdcdc",
            ghostwhite: "#f8f8ff",
            gold: "#ffd700",
            goldenrod: "#daa520",
            gray: "#808080",
            green: "#008000",
            greenyellow: "#adff2f",
            honeydew: "#f0fff0",
            hotpink: "#ff69b4",
            indianred: "#cd5c5c",
            indigo: "#4b0082",
            ivory: "#fffff0",
            khaki: "#f0e68c",
            lavender: "#e6e6fa",
            lavenderblush: "#fff0f5",
            lawngreen: "#7cfc00",
            lemonchiffon: "#fffacd",
            lightblue: "#add8e6",
            lightcoral: "#f08080",
            lightcyan: "#e0ffff",
            lightgoldenrodyellow: "#fafad2",
            lightgrey: "#d3d3d3",
            lightgreen: "#90ee90",
            lightpink: "#ffb6c1",
            lightsalmon: "#ffa07a",
            lightseagreen: "#20b2aa",
            lightskyblue: "#87cefa",
            lightslategray: "#778899",
            lightsteelblue: "#b0c4de",
            lightyellow: "#ffffe0",
            lime: "#00ff00",
            limegreen: "#32cd32",
            linen: "#faf0e6",
            magenta: "#ff00ff",
            maroon: "#800000",
            mediumaquamarine: "#66cdaa",
            mediumblue: "#0000cd",
            mediumorchid: "#ba55d3",
            mediumpurple: "#9370d8",
            mediumseagreen: "#3cb371",
            mediumslateblue: "#7b68ee",
            mediumspringgreen: "#00fa9a",
            mediumturquoise: "#48d1cc",
            mediumvioletred: "#c71585",
            midnightblue: "#191970",
            mintcream: "#f5fffa",
            mistyrose: "#ffe4e1",
            moccasin: "#ffe4b5",
            navajowhite: "#ffdead",
            navy: "#000080",
            oldlace: "#fdf5e6",
            olive: "#808000",
            olivedrab: "#6b8e23",
            orange: "#ffa500",
            orangered: "#ff4500",
            orchid: "#da70d6",
            palegoldenrod: "#eee8aa",
            palegreen: "#98fb98",
            paleturquoise: "#afeeee",
            palevioletred: "#d87093",
            papayawhip: "#ffefd5",
            peachpuff: "#ffdab9",
            peru: "#cd853f",
            pink: "#ffc0cb",
            plum: "#dda0dd",
            powderblue: "#b0e0e6",
            purple: "#800080",
            rebeccapurple: "#663399",
            red: "#ff0000",
            rosybrown: "#bc8f8f",
            royalblue: "#4169e1",
            saddlebrown: "#8b4513",
            salmon: "#fa8072",
            sandybrown: "#f4a460",
            seagreen: "#2e8b57",
            seashell: "#fff5ee",
            sienna: "#a0522d",
            silver: "#c0c0c0",
            skyblue: "#87ceeb",
            slateblue: "#6a5acd",
            slategray: "#708090",
            snow: "#fffafa",
            springgreen: "#00ff7f",
            steelblue: "#4682b4",
            tan: "#d2b48c",
            teal: "#008080",
            thistle: "#d8bfd8",
            tomato: "#ff6347",
            turquoise: "#40e0d0",
            violet: "#ee82ee",
            wheat: "#f5deb3",
            white: "#ffffff",
            whitesmoke: "#f5f5f5",
            yellow: "#ffff00",
            yellowgreen: "#9acd32",
        };
        if (typeof colours[value.toLowerCase()] != "undefined") {
            value = colours[value.toLowerCase()];
        } else {
            return false;
        }
    }
    if (value[0] == "#") {
        let r = parseInt(value.slice(1, 3), 16);
        let g = parseInt(value.slice(3, 5), 16);
        let b = parseInt(value.slice(5, 7), 16);
        // Tính độ sáng (brightness) của mã màu theo công thức YIQ
        brightness = (r * 299 + g * 587 + b * 114) / 1000;
    }
    if (value.includes("rgb")) {
        let rgb = value.substring(value.indexOf("(") + 1, value.indexOf(")")).split(",");
        brightness = (rgb[0] * 299 + rgb[1] * 587 + rgb[2] * 114) / 1000;
    }
    return brightness > 128 ? 'light' : 'dark';
};

export const getImageProperties = (url) => {
    return new Promise((resolve, reject) => {
        const img = new Image();
        // img.crossOrigin = 'Anonymous'
        img.onload = async () => {
            const properties = {
                url: url,
                width: img.width,
                height: img.height,
                naturalWidth: img.naturalWidth,
                naturalHeight: img.naturalHeight,
                size: await getImageSize(url)
            };
            resolve(properties);
        };
        img.onerror = () => {
            reject(new Error('Failed to load image'))
        };
        img.src = url;
    });
}

export const getImageSize = async (url) => {
    try {
        const response = await fetch(url, { method: 'HEAD' }); // mode: 'no-cors' not using content-length
        const contentLength = response.headers.get('content-length');
        const sizeInKB = contentLength / 1024;
        return parseFloat(sizeInKB.toFixed(2));
    } catch (error) {
        console.error('Failed to retrieve image size:', error);
        return null;
    }
}

export const isBase64 = (imageString) => {
    if (imageString.startsWith('data:image/')) {
        return true;
    } else {
        return false;
    }
}

export const urlToBase64 = async (url) => {
    try {
        const response = await fetch(url);
        const blob = await response.blob();
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onloadend = () => resolve(reader.result);
            reader.onerror = reject;
            reader.readAsDataURL(blob);
        });
    } catch (error) {
        console.error('Failed to convert URL to base64:', error);
        return null;
    }
}

export const imageToBase64 = (imageFile) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => {
            resolve(reader.result);
        };
        reader.onerror = () => {
            reject(new Error('Không thể chuyển đổi hình ảnh sang base64.'));
        };
        reader.readAsDataURL(imageFile);
    });
}


export const getCurrency = (key) => {
    let string
    switch (key.toUpperCase()) {
        case 'VN':
            string = 'VNĐ'
            break
        case 'EN':
            string = 'USD'
            break
        default:
            string = 'EUR'
            break
    }
    return string
}