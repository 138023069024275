<template>
  <div style="margin-top: 120px">
    <div class="custom-container">
      <div
        v-for="(item, index) in content"
        :key="index"
        class="feature-card "
      >
        <template v-if="index % 2 === 0">
          <div class="row">
            <div class="col-lg-5">
              <h3 class="ma-24-semi text-green">
                {{ item.title }}
              </h3>
              <div class="in-16-re">
                {{ item.content }}
              </div>
            </div>
            <div class="col-lg-7">
              <div class="reveal  d-flex justify-content-end" style="position: relative;">
                <div class="curtain reveal-child"></div>
                <img class="w-100" :src="item.img" />
              </div>
            </div>
          </div>
        </template>
        <template v-else>
          <div class="row">
            <div class="col-lg-7">
              <div class="reveal " style="position: relative;">
                <div class="curtain reveal-child"></div>
                <img class="w-100" :src="item.img" />
              </div>
            </div>
            <div class="col-lg-5">
              <h3 class="ma-24-semi text-green">
                {{ item.title }}
              </h3>
              <div class="in-16-re">
                {{ item.content }}
              </div>
            </div>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      content: [
        {
          title: this.$t('home-feature.feature-1'),
          img: "/assets/image/bg/bg_19.png",
          content: this.$t('home-feature.feature-decription-1'),
        },
        {
          title: this.$t('home-feature.feature-2'),
          img: "/assets/image/bg/img_5.png",
          content: this.$t('home-feature.feature-decription-2'),
        },
        {
          title: this.$t('home-feature.feature-3'),
          img: "/assets/image/bg/bg_18.png",
          content: this.$t('home-feature.feature-decription-3'),
        },
      ],
    };
  },
  created() {
    localStorage.setItem("currentUrl", "HOME");
    window.addEventListener("scroll", this.handleScroll);
  },
  unmounted() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    handleScroll(value) {
      let reveals = document.querySelectorAll(".reveal-child");
      let height = window.innerHeight;
      for (let i = 0; i < reveals.length; i++) {
        let windowHeight = window.innerHeight;
        let elementTop = reveals[i].getBoundingClientRect().top;
        let elementVisible = 300;

        if (elementTop < windowHeight - elementVisible) {
          reveals[i].classList.add("active");
        } else {
          reveals[i].classList.remove("active");
        }
      }
    },
  },
};
</script>

<style scoped>
.feature-card {
  display: flex;
  flex-direction: row;
  overflow: hidden;
  margin: 0 auto 120px;
}
.img-card {
  width: 522px;
  height: 400px;
  background-position: center;
  background-size: cover;
}
.content-slogan {
  color: #c71313;
  font-size: 48px !important;
  border-bottom: 1px solid lightgrey;
  margin: 16px 0 !important;
}
.title-secondary {
  font-size: 16px;
  font-weight: 300;
  color: #636466;
  margin: 24px 0 0;
}
.m-40 {
  margin: 40px;
  width: 50%;
}
.reveal-child {
  position: relative;
  transform: translateX(0);
  opacity: 1;
  transition: 0.7s all ease;
}
.reveal-child.active {
  /* transform: translateX(100%); */
  opacity: 1;
  visibility: hidden;
  width: 0%;
}
.curtain {
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  background-color: #fafafa;
  /* box-shadow: 0px 0px 8px 2px #fbfbfb; */
}
</style>
