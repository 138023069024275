<template>
	<div>
		<div>
			<div class="page-header clear-filter" style="height: 864px; color: #fcfcfc; position: relative">
				<parallax class="page-header-image" style="background-image: url('/assets/image/bg/bg_1.png')"> </parallax>
				<div class="container px-0 d-flex justify-content-center align-items-center">
					<div class="title-content">
						<h1 class="ma-56-semi text-uppercase m-0">
							{{ $t("home-title") }}
						</h1>
						<h3 class="mb-4 mx-auto in-20-re">
							{{ $t("home-title-decription") }}
						</h3>
						<div class="mt-4">
							<a href="/" target="_blank" class="m-0 btn-down text-nowrap in-14-semi text-uppercase"
								role="button">
								{{ $t("try-now") }}
							</a>
						</div>
					</div>
				</div>
				<div :class="{ active: !showScroll }" class="scroll-downs">
					<div class="mousey">
						<div class="scroller"></div>
					</div>
				</div>
			</div>
			<div class="custom-container">
				<div class="row">
					<!--  -->
					<div class="col-lg-2 col-md-0"></div>
					<div id="anime" class="col-lg-8 text-center content-block reveal">
						<h5 class="text-green ma-28-semi text-uppercase">
							{{ $t("home-title-1") }}
						</h5>
						<p>
							{{ $t("home-decrtiption-1") }}
						</p>
					</div>
					<div class="col-lg-2 col-md-0"></div>
					<!-- số hóa cây xanh -->
				</div>
			</div>
			<digitizing></digitizing>
			<div class="bg-img d-flex align-items-center">
				<div class="custom-container">
					<div class="row">
						<div class="col-lg-2"></div>
						<div class="col-lg-8 ma-28-semi text-light text-center">
							{{ $t("home-title-3") }}
						</div>
						<div class="col-lg-2"></div>
					</div>
				</div>
			</div>
			<slide></slide>
			<parner></parner>
		</div>
	</div>
</template>
<script>
import anime from 'animejs/lib/anime.es.js';

import { Parallax } from "@/components";
import slide from "./components/slide.vue";
import digitizing from "./components/digitizing.vue";
import parner from "./components/parner.vue";
export default {
	name: "index",
	bodyClass: "index-page",
	components: {
		Parallax,
		slide,
		digitizing,
		parner,
	},
	data() {
		return {
			windowHeight: window.innerHeight,
			showScroll: true,
			animation: null,
		};
	},
	watch: {
		windowHeight() { },
	},
	created() {
		localStorage.setItem("currentUrl", "HOME");
		window.addEventListener("scroll", this.handleScroll);
	},
	mounted() {
		this.animation = anime({
			targets: '.anime',
			translateY: 250,
			opacity: [1, 0],
			direction: 'reverse',
			easing: 'linear',
			// duration: 1000,
			// delay: function(el, i) { return i * 100; },
			// delay: 1000,
			autoplay: false,
		});
	},
	unmounted() {
		window.removeEventListener("scroll", this.handleScroll);
	},
	methods: {
		animateOnScroll(section2, speed = 100, offset = 0) {
			const div = document.querySelector(`.${section2}`);
			const scrollPercent = window.pageYOffset - div.offsetTop;
			return (scrollPercent + offset) / speed;
		},
		calculateVisiblePercentage(id) {
			const element = document.getElementById(id); // Thay thế 'your-element-id' bằng ID cụ thể của phần tử bạn muốn kiểm tra
			const rect = element.getBoundingClientRect();
			const windowHeight = window.innerHeight;

			// Tính phần trăm chiều cao của phần tử đang được hiển thị trong viewport
			const visiblePercentage = (Math.min(rect.bottom, windowHeight) - Math.max(rect.top, 0)) / rect.height * 100;

			return visiblePercentage;
		},
		handleScroll(value) {
			// console.log(this.calculateVisiblePercentage('anime'));
			this.showScroll = window.scrollY === 0; // Show the button after scrolling 300px
			let reveals = document.querySelectorAll(".reveal");
			let animes = document.querySelectorAll(".anime");
			let height = window.innerHeight;
			this.animation.seek(this.animation.duration * (1 - (this.calculateVisiblePercentage('anime') / 100)));
			for (let i = 0; i < reveals.length; i++) {
				let windowHeight = window.innerHeight;
				let elementTop = reveals[i].getBoundingClientRect().top;
				const rect = reveals[i].getBoundingClientRect();
				const visiblePercentage = (Math.min(rect.bottom, windowHeight) - Math.max(rect.top, 0)) / rect.height * 100;
				let elementVisible = 0;

				if (elementTop < windowHeight - elementVisible) {
					// reveals[i].classList.add("active");
					reveals[i].style.transform = `translateY(${this.map_range(visiblePercentage,0,100,150,0)}px)`
					reveals[i].style.opacity = `${this.map_range(visiblePercentage,0,100,0,1)}`
					// console.log('transform', this.map_range(visiblePercentage,0,100,150,0) );
				} else {
					reveals[i].style.transform = 'translateY(150px)'
					reveals[i].style.opacity = '0'
					// reveals[i].classList.remove("active");
				}
			}
		},
		map_range(value, low1, high1, low2, high2) {
			return low2 + (high2 - low2) * (value - low1) / (high1 - low1);
		}
	},
};
</script>
<style scoped>
.scroll-downs {
	position: absolute;
	/* top: 0; */
	right: 0;
	bottom: 20px;
	left: 0;
	margin: auto;

	width: 34px;
	height: 55px;
	transition: 0.5s;
}

.scroll-downs.active {
	opacity: 0;
}

.mousey {
	width: 3px;
	padding: 10px 9px;
	height: 24px;
	border: 2px solid #fff;
	border-radius: 25px;
	opacity: 0.75;
	box-sizing: content-box;
}

.scroller {
	width: 4px;
	height: 10px;
	border-radius: 25%;
	background-color: #fff;
	animation-name: scroll;
	animation-duration: 2.2s;
	animation-timing-function: cubic-bezier(0.15, 0.41, 0.69, 0.94);
	animation-iteration-count: infinite;
}

@keyframes scroll {
	0% {
		opacity: 0;
	}

	10% {
		transform: translateY(0);
		opacity: 1;
	}

	100% {
		transform: translateY(24px);
		opacity: 0;
	}
}

.bg-img {
	background-image: url("/assets/image/bg/bg_26.png");
	background-attachment: fixed;
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	min-height: 600px;
}

.custom-button {
	border-radius: 40px;
	border: 1.5px solid var(--hover, #063);
	padding: 8px 16px;
	background: none;
}

.content-block {
	padding: 80px 0;
}

.title-content {
	margin: 100px 0 80px 0;
	text-align: center;
}

.btn-down {
	border: 1px solid white !important;
	border-radius: 30px;
	padding: 9px 16px !important;
	color: white !important;
	transition: 500ms;
	background-color: transparent !important;
	text-decoration: none;
	/* width: 218px; */
}

.btn-down:hover {
	background-color: #006633 !important;
	border: 1px solid #006633 !important;
}

.reveal {
	position: relative;
	transform: translateY(150px);
	opacity: 0;
	transition: 1s all ease;
}

.reveal.active {
	transform: translateY(0);
	opacity: 1;
}

@media screen and (max-width: 470px) {
	.btn-down {
		width: 118px;
	}
}
</style>
