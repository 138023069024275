import Repository from "../../repository";

export default {
    Get: (payload) => Repository.post(`/api/DashboardFires/Get`, payload),
    GetDetails: (payload) => Repository.post(`/api/DashboardFires/GetDetails`, payload),
    GetFireWarningStatus: (payload) => Repository.post(`/api/DashboardFires/GetFireWarningStatus`, payload),
    GetHistoryFire: (payload) => Repository.post(`/api/DashboardFires/GetHistoryFire`, payload),
    GetListDataCode: (payload) => Repository.post(`/api/Dashboard/GetListDataCode`, payload),
    GetDashboard_ByMonitor: (payload) => Repository.post(`/api/Dashboard/GetDashboard_ByMonitor`, payload),
    GetDashboard_ByAquaculture: (payload) => Repository.post(`/api/Dashboard/GetDashboard_ByAquaculture`, payload),
    GetDashboard_ByWIM: (payload) => Repository.post(`/api/Dashboard/GetDashboard_ByWIM`, payload),
    GetDashboard_ByTree: (payload) => Repository.post(`/api/Dashboard/GetDashboard_ByTree`, payload),
    GetDashboard_ByCollect: (payload) => Repository.post(`/api/Dashboard/GetDashboard_ByCollect`, payload),
    GetDashboard_ByLighting: (payload) => Repository.post(`/api/Dashboard/GetDashboard_ByLighting`, payload),
    GetDashboard_ByLightingTest: (payload) => Repository.post(`/api/Dashboard/GetDashboard_ByLightingTest`, payload),
    GetDashboard_ByWaterway: (payload) => Repository.post(`/api/Dashboard/GetDashboard_ByWaterway`, payload),
    GetDashboard_ByClearanceHeight: (payload) => Repository.post(`/api/Dashboard/GetDashboard_ByClearanceHeight`, payload),
    GetDashboard_ByKiosk: (payload) => Repository.post(`/api/Dashboard/GetDashboard_ByKiosk`, payload),
    GetDashboard_ByCameraThermal: (payload) => Repository.post(`/api/Dashboard/GetDashboard_ByCameraThermal`, payload),
    GetDashboard_ByGasFamily: (payload) => Repository.post(`/api/Dashboard/GetDashboard_ByGasFamily`, payload),
    GetDashboard_ByGasRestaurent: (payload) => Repository.post(`/api/Dashboard/GetDashboard_ByGasRestaurent`, payload),
    GetDashboard_ByGasFoodCourt: (payload) => Repository.post(`/api/Dashboard/GetDashboard_ByGasFoodCourt`, payload),
    GetDashboard_ByGasRestaurentForSale: (payload) => Repository.post(`/api/Dashboard/GetDashboard_ByGasRestaurent_ForSale`, payload),
    GetDashboard_TreeMaintenance: (payload) => Repository.post(`/api/Dashboard/GetDashboard_TreeMaintenance`, payload),
    GetDashboard_TreeDataCurrents: (payload) => Repository.post(`/api/Dashboard/GetDashboard_TreeDataCurrents`, payload),
    GetDashboard_Wind: (payload) => Repository.post(`/api/Dashboard/GetDashboard_Wind`, payload),
    GetCCTV: (payload) => Repository.post(`/api/Dashboard/GetCCTV`, payload),
    GetDashboard_Radio: (payload) => Repository.post(`/api/Dashboard/GetDashboard_Radio`, payload),
}
