import Repository from "../../repository";

export default {
    Get: (payload) => Repository.post(`/api/MonitorLocations/Get`, payload),
    GetActive: (payload) => Repository.post(`/api/MonitorLocations/GetActive`, payload),
    GetByID: (payload) => Repository.post(`/api/MonitorLocations/GetByID`, payload),
    Add: (payload) => Repository.post(`/api/MonitorLocations/Add`, payload),
    Edit: (payload) => Repository.post(`/api/MonitorLocations/Edit`, payload),
    Delete: (payload) => Repository.post(`/api/MonitorLocations/Delete`, payload),
    ChangeStatus: (payload) => Repository.post(`/api/MonitorLocations/ChangeStatus`, payload),
}
