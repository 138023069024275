import Repository from "../../repository";

export default {
    GetMonitor: (payload) => Repository.post(`/api/Monitors/GetMonitor`, payload),
    GetAquaculture: (payload) => Repository.post(`/api/Monitors/GetAquaculture`, payload),
    GetMonitorGasFamily: (payload) => Repository.post(`/api/MonitorGasFamily/GetMonitor`, payload),
    RestartStation: (payload) => Repository.post(`/api/MonitorGasFamily/RestartStation`, payload),
    SetRestart: (payload) => Repository.post(`/api/GasFoodCourtMonitor/SetRestart`, payload),
    SetRefresh: (payload) => Repository.post(`/api/GasFoodCourtMonitor/SetRefresh`, payload),
    SetLock: (payload) => Repository.post(`/api/GasFoodCourtMonitor/SetLock`, payload),
    GetDetectorByStationID: (payload) => Repository.post(`/api/GasDetectorMonitors/GetDetectorByStationID`, payload),
    GetMonitorGasRestaurent: (payload) => Repository.post(`/api/GasRestaurentMonitor/GetMonitor`, payload),
    SetRestartGasRestaurent: (payload) => Repository.post(`/api/GasRestaurentMonitor/SetRestart`, payload),
    SetRefreshGasRestaurent: (payload) => Repository.post(`/api/GasRestaurentMonitor/SetRefresh`, payload),
    SetLockGasRestaurent: (payload) => Repository.post(`/api/GasRestaurentMonitor/SetLock`, payload),
    SetGasUse: (payload) => Repository.post(`/api/GasRestaurentMonitor/SetGasUse`, payload),
    Get: (payload) => Repository.post(`/api/LightMonitors/Get`, payload),
    Waterway: (payload) => Repository.post(`/api/WaterwayMonitors/Waterway`, payload),
    ClearanceHeight: (payload) => Repository.post(`/api/WaterwayMonitors/ClearanceHeight`, payload),
    GetCameraThermals: (payload) => Repository.post(`/api/MonitorCameraThermals/Get`, payload),
    GetKiosks: (payload) => Repository.post(`/api/MonitorKiosks/Get`, payload),
    SetRestartCameraThermals: (payload) => Repository.post(`/api/StationMonitorByCameraThermals/SetRestart`, payload),
    SetRestartKiosks: (payload) => Repository.post(`/api/StationMonitorByKiosks/SetRestart`, payload),
    SetCallBack: (payload) => Repository.post(`/api/StationMonitorByKiosks/SetCallBack`, payload),
}
