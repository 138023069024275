import Repository from "../../repository";

export default {
    Get: (payload) => Repository.post(`/api/RequestVehicles/Get`, payload),
    GetById: (payload) => Repository.post(`/api/RequestVehicles/GetById`, payload),
    Copy: (payload) => Repository.post(`/api/RequestVehicles/Copy`, payload),
    Add: (payload) => Repository.post(`/api/RequestVehicles/Add`, payload),
    Edit: (payload) => Repository.post(`/api/RequestVehicles/Edit`, payload),
    Delete: (payload) => Repository.post(`/api/RequestVehicles/Delete`, payload),
    ChangeStatus: (payload) => Repository.post(`/api/RequestVehicles/ChangeStatus`, payload),
}