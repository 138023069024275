import Repository from "../../repository";

export default {
    Get: (payload) => Repository.post(`/api/RadioStations/Get`, payload),
    GetActive: (payload) => Repository.post(`/api/RadioStations/GetActive`, payload),
    GetExport: (payload) => Repository.post(`/api/RadioStations/GetExport`, payload),
    GetStationById: (payload) => Repository.post(`/api/RadioStations/GetStationById`, payload),
    Export: (payload) => Repository.post(`/api/RadioStations/Export`, payload),
    Deploy: (payload) => Repository.post(`/api/RadioStations/Deploy`, payload),
    Delete: (payload) => Repository.post(`/api/RadioStations/Delete`, payload),
    ChangeStatus: (payload) => Repository.post(`/api/RadioStations/ChangeStatus`, payload),
    GetByRegionID: (payload) => Repository.post(`/api/RadioStations/GetByRegionID`, payload),
    GetStationRight: (payload) => Repository.post(`/api/RadioStations/GetStationRight`, payload)
}
