import Repository from "../../repository";

export default {
    Get: (payload) => Repository.post(`/api/BusRoutes/Get`, payload),
    GetActive: (payload) => Repository.post(`/api/BusRoutes/GetActive`, payload),
    GetById: (payload) => Repository.post(`/api/BusRoutes/GetById`, payload),
    Add: (payload) => Repository.post(`/api/BusRoutes/Add`, payload),
    Edit: (payload) => Repository.post(`/api/BusRoutes/Edit`, payload),
    Delete: (payload) => Repository.post(`/api/BusRoutes/Delete`, payload),
    ChangeStatus: (payload) => Repository.post(`/api/BusRoutes/ChangeStatus`, payload),
    ImportRoute: (payload) => Repository.post(`/api/BusRoutes/ImportRoute`, payload),
}