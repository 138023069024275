import Repository from "../../repository";

export default {
    Get: (payload) => Repository.post(`/api/GasDeliverys/Get`, payload),
    GetByStoreId: (payload) => Repository.post(`/api/GasDeliverys/GetByStoreId`, payload),
    GetById: (payload) => Repository.post(`/api/GasDeliverys/GetById`, payload),
    Add: (payload) => Repository.post(`/api/GasDeliverys/Add`, payload),
    Edit: (payload) => Repository.post(`/api/GasDeliverys/Edit`, payload),
    Delete: (payload) => Repository.post(`/api/GasDeliverys/Delete`, payload),
    Detail_GetById: (payload) => Repository.post(`/api/GasDeliverys/Detail_GetById`, payload),
}
