import Repository from "../../repository";

export default {
    Get: (payload) => Repository.post(`/api/FireStations/Get`, payload),
    GetDeploy: (payload) => Repository.post(`/api/FireStations/GetDeploy`, payload),
    Get_ByLocationID: (payload) => Repository.post(`/api/FireStations/Get_ByLocationID`, payload),
    GetActive: (payload) => Repository.post(`/api/FireStations/GetActive`, payload),
    GetListExport: (payload) => Repository.post(`/api/FireStations/GetListExport`, payload),
    ExportProduct: (payload) => Repository.post(`/api/FireStations/ExportProduct`, payload),
    GetById: (payload) => Repository.post(`/api/FireStations/GetById`, payload),
    EditDeploy: (payload) => Repository.post(`/api/FireStations/EditDeploy`, payload),
    Add: (payload) => Repository.post(`/api/FireStations/Add`, payload),
    Edit: (payload) => Repository.post(`/api/FireStations/Edit`, payload),
    Delete: (payload) => Repository.post(`/api/FireStations/Delete`, payload),
    ChangeStatus: (payload) => Repository.post(`/api/FireStations/ChangeStatus`, payload),
    EditTest: (payload) => Repository.post(`/api/FireStations/EditTest`, payload),
    EditLocation: (payload) => Repository.post(`/api/FireStations/EditLocation`, payload),
    DelLocation: (payload) => Repository.post(`/api/FireStations/DelLocation`, payload),
}