<template>
  <div class="totop" v-show="showButton" @click="scrollToTop">
    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="4" y="4" width="40" height="40" rx="20" fill="#029834" />
      <path d="M30 26L24 20L18 26" stroke="#FAFAFA" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
      <rect x="1" y="1" width="46" height="46" rx="23" stroke="#029834" stroke-width="2" style="stroke-dasharray: 143px;" :style="`stroke-dashoffset: ${scrollValue};`"/>
    </svg>
  </div>
</template>

<script>
export default {
  data() {
    return {
      showButton: false,
      scrollValue: 0,
    };
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    handleScroll() {
      this.showButton = window.scrollY > 1; // Show the button after scrolling 300px
      this.scrollValue = this.convertRange(
        window.scrollY,
        0,
        document.body.scrollHeight - window.innerHeight,
        143,
        0
      );
    },
    scrollToTop() {
      window.scrollTo({
        top: 0,
        behavior: "smooth", // Smooth scrolling
      });
    },
    convertRange(value, fromMin, fromMax, toMin, toMax) {
      // Đảm bảo giá trị nằm trong phạm vi từ fromMin đến fromMax
      value = Math.min(Math.max(value, fromMin), fromMax);
      // Tính tỷ lệ tương ứng trong phạm vi từ 0 đến 1
      const normalizedValue = (value - fromMin) / (fromMax - fromMin);
      // Chuyển đổi sang phạm vi mới từ toMin đến toMax
      const convertedValue = normalizedValue * (toMax - toMin) + toMin;
      return convertedValue;
    },
  },
};
</script>

<style scoped>
.totop {
  z-index: 999;
  position: fixed;
  bottom: 60px;
  right: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

@media screen and (max-width: 360px) {
  .totop {
    display: none;
  }
}
</style>
