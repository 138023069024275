import Repository from "../../repository";

export default {
    Get: (payload) => Repository.post(`/api/ETCCosts/Get`, payload),
    GetById: (payload) => Repository.post(`/api/ETCCosts/GetById`, payload),
    ImportExcel: (payload) => Repository.post(`/api/ETCCosts/ImportExcel`, payload),
    Add: (payload) => Repository.post(`/api/ETCCosts/Add`, payload),
    Edit: (payload) => Repository.post(`/api/ETCCosts/Edit`, payload),
    Delete: (payload) => Repository.post(`/api/ETCCosts/Delete`, payload),
    ChangeStatus: (payload) => Repository.post(`/api/ETCCosts/ChangeStatus`, payload),
}
