import Repository from "../../repository";

export default {
    Get: (payload) => Repository.post(`/api/Positions/Get`, payload),
    GetActive: (payload) => Repository.post(`/api/Positions/GetActive`, payload),
    GetById: (payload) => Repository.post(`/api/Positions/GetById`, payload),
    Add: (payload) => Repository.post(`/api/Positions/Add`, payload),
    Edit: (payload) => Repository.post(`/api/Positions/Edit`, payload),
    Delete: (payload) => Repository.post(`/api/Positions/Delete`, payload),
    ChangeStatus: (payload) => Repository.post(`/api/Positions/ChangeStatus`, payload),
}
