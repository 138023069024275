<template>
  <div v-if="items" class="row px-2 mt-3">
    <div
      v-for="(item, index) in items.Images"
      :key="index"
      @click="showWallpaper(items.Images,index)"
      style="cursor: pointer;"
      class="col-lg-6 px-2 mb-3"
    >
      <img :src="item.Image" />
    </div>
    <div id="fullscreen-wallpaper-tree">
      <div class="button-close-wallpaper m-3">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="40"
          height="40"
          viewBox="0 0 40 40"
          fill="none"
          class="btn-close"
          @click="hideWallpaper"
        >
          <path d="M0 40L40 0" stroke="white" stroke-linecap="round" />
          <path d="M0 0L40 40" stroke="white" stroke-linecap="round" />
        </svg>
      </div>
      <div class="image-wallpaper" @keydown="closeSlide($event)">
        <BCarousel :list-image="listImage" :slideindex="slide" />
      </div>
    </div>
  </div>
</template>

<script>
import BCarousel from "@/components/BCarouselComponent";
export default {
  components: {
    BCarousel,
  },
  props: {
    items: {
      type: Object,
      default: null,
    },
  },
  data(){
    return{
      listImage: [],
      slide: null,
    }
  },
  methods: {
    closeSlide(event) {
      let x = event.keyCode;
      if (x == 27) {
        alert("You pressed the Escape key!");
      }
    },
    handleClick(index, list) {
      this.$emit("clickImg", index, list);
    },
    showWallpaper(list, key) {
      let result = [];
      this.listImage = null;
      list.map((item, index) => {
        let obj = {
          ...item,
          ID: index,
          Image: item.Image, //img
        };
        result.push(obj);
      });
      // result.forEach((item, index) => {
      //     if (index == key) {
      //         const tmp = { ...result[0] };
      //         result[0] = result[index];
      //         result[index] = tmp;
      //     }
      // });
      this.slide = key;
      this.listImage = result;
      let targetDiv = document.getElementById("fullscreen-wallpaper-tree");
      if (
        targetDiv.style.display === "none" ||
        targetDiv.style.display === ""
      ) {
        targetDiv.style.display = "block";
      }
    },
    hideWallpaper() {
      this.listImage = null;
      let targetDiv = document.getElementById("fullscreen-wallpaper-tree");
      targetDiv.style.display = "none";
    },
  },
};
</script>

<style scoped>
#fullscreen-wallpaper-tree {
  display: none;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100vh;
  z-index: 9999;
  background-color: rgba(0, 0, 0, 0.95);
  cursor: pointer;
}

#fullscreen-wallpaper-tree .image-wallpaper {
  width: 100%;
  height: 100%;
}

#fullscreen-wallpaper-tree .button-close-wallpaper {
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 9999;
}

#fullscreen-wallpaper-tree .button-close-wallpaper .btn {
  border-radius: 50%;
  width: 40px;
  height: 40px;
  padding: 0.6rem;
}

#fullscreen-wallpaper-tree .button-close-wallpaper .btn i {
  font-size: 20px;
}
</style>
