import Vue from "vue";
import Router from "vue-router";
import Index from "./pages/Home/index.vue";
import Map from "./pages/WebApp/index.vue";
import Introduce from "./pages/Guideline/index.vue";
import Contact from "./pages/Contact/index.vue";
import Feature from "./pages/Feature/index";
import MainNavbar from "./layout/MainNavbar";
import AppNavbar from "./layout/AppNavbar";
import MainFooter from "./layout/MainFooter";
import Scrolltotop from "./components/Button/Scrolltotop";
import Libary from "./pages/Libary/index";

import routerGuideline from "./pages/Guideline/router";
Vue.use(Router);

export default new Router({
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
  linkExactActiveClass: "active",
  mode: "history",
  routes: [
    {
      path: "/",
      name: "index",
      components: {
        default: Index,
        header: MainNavbar,
        footer: MainFooter,
        totop: Scrolltotop,
      },
      props: {
        header: { colorOnScroll: 400 },
        footer: { backgroundColor: "black" },
      },
    },
    {
      path: "/map",
      name: "map",
      components: { default: Map, header: AppNavbar },
      props: {
        header: { colorOnScroll: 400 },
        footer: { backgroundColor: "black" },
      },
    },
    {
      path: "/guideline",
      name: "guideline",
      components: {
        default: Introduce,
        header: MainNavbar,
        footer: MainFooter,
        totop: Scrolltotop,
      },
      redirect: "/guideline/main",
      children: routerGuideline,
      props: {
        header: { colorOnScroll: 400 },
        footer: { backgroundColor: "black" },
      },
    },
    {
      path: "/contact",
      name: "contact",
      components: {
        default: Contact,
        header: MainNavbar,
        footer: MainFooter,
        totop: Scrolltotop,
      },
      props: {
        header: { colorOnScroll: 400 },
        footer: { backgroundColor: "black" },
      },
    },
    {
      path: "/feature",
      name: "feature",
      components: {
        default: Feature,
        header: MainNavbar,
        footer: MainFooter,
        totop: Scrolltotop,
      },
      props: {
        header: { colorOnScroll: 400 },
        footer: { backgroundColor: "black" },
      },
    },
    {
      path: "/gallery",
      name: "libary",
      components: {
        default: Libary,
        header: MainNavbar,
        footer: MainFooter,
        totop: Scrolltotop,
      },
      props: {
        header: { colorOnScroll: 400 },
        footer: { backgroundColor: "black" },
      },
    },
  ],
  scrollBehavior: (to) => {
    if (to.hash) {
      return { selector: to.hash };
    } else {
      return { x: 0, y: 0 };
    }
  },
});
