import Repository from "../../repository";

export default {
    GetWaitingForCoordination: (payload) => Repository.post(`/api/Shippings/GetWaitingForCoordination`, payload),
    GetMonitor: (payload) => Repository.post(`/api/Shippings/GetMonitor`, payload),
    GetDetailByOID: (payload) => Repository.post(`/api/Shippings/GetDetailByOID`, payload),
    GetTotalContractByReferenceID: (payload) => Repository.post(`/api/Shippings/GetTotalContractByReferenceID`, payload),
    Add: (payload) => Repository.post(`/api/Shippings/Add`, payload),
    Edit: (payload) => Repository.post(`/api/Shippings/Edit`, payload),
    GetDetailByOIDAndReferenceID: (payload) => Repository.post(`/api/Shippings/GetDetailByOIDAndReferenceID`, payload),
    Delete: (payload) => Repository.post(`/api/Shippings/Delete`, payload),
    ChangeStatus: (payload) => Repository.post(`/api/Shippings/ChangeStatus`, payload),
    GetVehicle: (payload) => Repository.post(`/api/Shippings/GetVehicle`, payload),
    GetVehicleHasCrane: (payload) => Repository.post(`/api/Shippings/GetVehicleHasCrane`, payload),
    GetDriver: (payload) => Repository.post(`/api/Shippings/GetDriver`, payload),
    GetDepotContract: (payload) => Repository.post(`/api/Shippings/GetDepotContract`, payload),
    GetCargoCraneType: (payload) => Repository.post(`/api/Shippings/GetCargoCraneType`, payload),
    GetListContByReferenceID: (payload) => Repository.post(`/api/Shippings/GetListContByReferenceID`, payload),
    GetListContractByDriverID: (payload) => Repository.post(`/api/Shippings/GetListContractByDriverID`, payload),
}
