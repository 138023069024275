<template>
  <div style="margin-top: 0; margin-bottom: 48px">
    <div class="custom-container">
      <div class="row justify-content-center">
        <div class="col-lg-8 text-center mb-80" style="margin-bottom: 80px">
          <h5 class="ma-28-semi text-green">{{ $t('parner') }}</h5>
          <p class="in-16-re">
            {{ $t('parner-de') }}
          </p>
        </div>
      </div>
      <div class="row justify-content-center">
        <div class="col-lg-10">
          <div class="grid-group">
            <div
              v-for="(e, index) in listLogo"
              :key="index"
              class="text-center"
            >
              <img :src="e" class="mb-80" height="120px" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      listLogo: [
        "/assets/image/logo/Logo-footer.svg",
        "/assets/image/logo/nlt_logo.png",
        "/assets/image/logo/tayninh_logo.png",
        "/assets/image/logo/hcm_logo.png",
        "/assets/image/logo/hcm_logo.png",
        "/assets/image/logo/tayninh_logo.png",
        "/assets/image/logo/nlt_logo.png",
        "/assets/image/logo/Logo-footer.svg",
      ],
    };
  },
};
</script>

<style scoped>
@media screen and (min-width: 1024px) {
  .grid-group {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr !;
    grid-gap: 10px;
    padding: 10px;
  }
}
@media screen and (min-width: 1440px) {
  .grid-group {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr !important;
    grid-gap: 10px;
    padding: 10px;
  }
}
.grid-group {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px;
  padding: 10px;
}
.mb-80 {
  margin-bottom: 80px;
}
.logo-group {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 72px;
}
.reveal {
  position: relative;
  transform: translateY(150px);
  opacity: 0;
  transition: 1s all ease;
}
.reveal.active {
  transform: translateY(0);
  opacity: 1;
}
</style>
