<template>
	<div style="position: relative">
		<div id="map" class="map"></div>
		<div id="planningNav" class="planning-nav">
			<div class="header-planning-nav">
				<p class="font-weight-bold" style="color: var(--website-brand-color, #029834); font-size: 24px">Thông tin quy hoạch</p>
				<template v-if="mapPlanning && mapPlanning.length > 0">
					<div class="p-3 text-right" style="width: 100%; background-color: white; border-radius: 12px;box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05);">
						<VSelect class="mb-3" v-model="selectedTypePlanning" :options="mapPlanning"></VSelect>
						<p class="text-left mb-1" style="font-size: 14px">Bản đồ QH/KH:</p>
						<div class="d-flex">
							<VSelect v-if="selectedTypePlanning" style="width: 80%" class="mb-2" v-model="selectedPlanning" :options="selectedTypePlanning.detail && selectedTypePlanning.detail.length > 0 ? selectedTypePlanning.detail.filter((x) => x.type == 'PLANNING' || x.type == 'ALL') : []" @input="selectedOptionPlanning(selectedPlanning, statePlanning)" :disabled="!statePlanning"></VSelect>
							<b-form-input v-else style="width: 80%; height: 39px" class="form-tab-data-screen mb-2" value="" disabled></b-form-input>
							<b-form-checkbox class="ml-2 mt-2" v-model="statePlanning" :value="true" :unchecked-value="false" @change="selectedOptionPlanning(selectedPlanning, statePlanning)" :disabled="!selectedTypePlanning"></b-form-checkbox>
							<button size="sm" class="p-2 mb-2 mt-0 mb-0 btn-config" :disabled="!selectedPlanning" @click="isShowSliderOpacityPlanning = !isShowSliderOpacityPlanning"><i class="fas fa-sliders-h"></i></button>
						</div>
						<div class="d-flex" v-if="isShowSliderOpacityPlanning">
							<p class="text-left text-muted mb-2 mr-2" style="width: 100px; font-size: 12px">Trong suốt: {{ opacityPlanning }}%</p>
							<VueSlider ref="opacityPlanning" v-model="opacityPlanning" :min="0" :max="100" :tooltip-formatter="'{value}%'" :width="215" @change="changeOpacityPlanning(opacityPlanning, selectedPlanning)"></VueSlider>
						</div>
						<p class="text-left mb-1" style="font-size: 14px">Bản đồ hiện trạng:</p>
						<div class="d-flex">
							<VSelect v-if="selectedTypePlanning" style="width: 80%" class="mb-2" v-model="selectedCurrent" :options="selectedTypePlanning.detail && selectedTypePlanning.detail.length > 0 ? selectedTypePlanning.detail.filter((x) => x.type == 'CURRENT' || x.type == 'ALL') : []" @input="selectedOptionCurrent(selectedCurrent, stateCurrent)" :disabled="!stateCurrent"></VSelect>
							<b-form-input v-else style="width: 80%; height: 39px" class="form-tab-data-screen mb-2" value="" disabled></b-form-input>
							<b-form-checkbox class="ml-2 mt-2" v-model="stateCurrent" :value="true" :unchecked-value="false" @change="selectedOptionCurrent(selectedCurrent, stateCurrent)" :disabled="!selectedTypePlanning"></b-form-checkbox>
							<button size="sm" class="p-2 mb-2 mt-0 mb-0 btn-config" :disabled="!selectedCurrent" @click="isShowSliderOpacityCurrent = !isShowSliderOpacityCurrent"><i class="fas fa-sliders-h"></i></button>
						</div>
						<div class="d-flex" v-if="isShowSliderOpacityCurrent">
							<p class="text-left text-muted mb-2 mr-2" style="width: 100px; font-size: 12px">Trong suốt: {{ opacityCurrent }}%</p>
							<VueSlider ref="opacityCurrent" v-model="opacityCurrent" :min="0" :max="100" :tooltip-formatter="'{value}%'" :width="215" @change="changeOpacityCurrent(opacityCurrent, selectedCurrent)"></VueSlider>
						</div>
						<p class="text-left text-danger mb-2" style="font-size: 12px">(Bấm vào vùng quy hoạch trên xem thông tin chi tiết)</p>
						<b-button class="pl-3 pr-3 pt-2 pb-2 mt-0 mb-0" size="sm" style="height: 39px; border-radius: 12px; background: var(--Brand, #029834)" @click="resetDataPlanning" :disabled="!selectedTypePlanning"><strong>Làm mới</strong></b-button>
					</div>
				</template>
				<template v-else>
					<p class="font-italic text-small text-muted mb-0" style="font-size: 12px">Chưa có thông tin quy hoạch</p>
				</template>
			</div>
			<b-row style="padding: 8px 16px 16px 16px">
				<b-col xl="12" lg="12">
					<div  style="background: white; border-top-left-radius: 12px; border-top-right-radius: 12px; border-bottom: none !important;box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05);">
						<b-button v-b-toggle.collapseNote variant="link" class="pl-3 mt-1 mb-1" style="font-size: 14px"><i class="fas fa-question-circle mr-2"></i>Chú giải</b-button>
						<b-collapse id="collapseNote" accordion="my-accordion">
							<div class="pl-3 pr-3 pb-2 pt-1">
								<template v-if="selectedTypePlanning">
									<template v-if="selectedTypePlanning.value == 'TREE-PLANNING'">
										<div class="d-flex mb-2">
											<div class="mr-3" style="width: 30px; height: 17px; background-color: #e9ffbe"></div>
											<p class="text-left mb-1" style="font-size: 12px">Đất cây xanh cảnh quan bảo tồn</p>
										</div>
										<div class="d-flex mb-2">
											<div class="mr-3" style="width: 30px; height: 17px; background-color: #38a800"></div>
											<p class="text-left mb-1" style="font-size: 12px">Đất cây xanh công viên chuyên đề</p>
										</div>
										<div class="d-flex mb-2">
											<div class="mr-3" style="width: 30px; height: 17px; background-color: #d1ff73"></div>
											<p class="text-left mb-1" style="font-size: 12px">Đất cây xanh công viên theo tuyến</p>
										</div>
										<div class="d-flex mb-2">
											<div class="mr-3" style="width: 30px; height: 17px; background-color: #37a600"></div>
											<p class="text-left mb-1" style="font-size: 12px">Đất du lịch sinh thái</p>
										</div>
										<div class="d-flex mb-2">
											<div class="mr-3" style="width: 30px; height: 17px; background-color: #b6fcb6"></div>
											<p class="text-left mb-1" style="font-size: 12px">Đất nông nghiệp công nghệ cao</p>
										</div>
										<div class="d-flex mb-2">
											<div class="mr-3" style="width: 30px; height: 17px; background-color: #a5f57a"></div>
											<p class="text-left mb-1" style="font-size: 12px">Đất nông nghiệp</p>
										</div>
									</template>
								</template>
								<template v-else>
									<p class="font-italic text-muted text-small mb-0" style="font-size: 12px">Chưa có thông tin</p>
								</template>
							</div>
						</b-collapse>
					</div>
					<div class="border-top" style="background: white; border-bottom-left-radius: 12px; border-bottom-right-radius: 12px">
						<b-button v-b-toggle.collapseInfo variant="link" class="pl-3 mt-1 mb-1" style="font-size: 14px"><i class="fas fa-info-circle mr-2"></i>Thông tin quy hoạch</b-button>
						<b-collapse id="collapseInfo" visible accordion="my-accordion">
							<div class="pl-3 pr-3 pb-2 pt-1">
								<template v-if="targetPlanningData && targetPlanningData.length > 0">
									<div v-for="(target, index) in targetPlanningData" :key="index">
										<p class="mb-2" style="font-size: 14px"><strong class="mr-2">Tiêu đề:</strong>{{ target.properties.folders }}</p>
										<p class="mb-2" style="font-size: 14px"><strong class="mr-2">Quy hoạch:</strong>{{ target.properties.name }}</p>
										<template v-if="target.properties.area.includes('Reference')">
											<p class="mb-1" style="font-size: 14px"><strong class="mr-2">Diện tích:</strong>{{ target.properties.area.split("-")[1] }} m²</p>
											<p class="mb-2 text-right text-small text-danger font-italic" style="font-size: 12px">Giá trị đo đạc mang tính chất tham khảo</p>
										</template>
										<template v-else>
											<p class="mb-2" style="font-size: 14px"><strong class="mr-2">Diện tích:</strong>{{ target.properties.area }} m²</p>
										</template>
									</div>
								</template>
								<template v-else>
									<p class="font-italic text-muted text-small mb-0" style="font-size: 12px">Chưa có thông tin</p>
								</template>
							</div>
						</b-collapse>
					</div>
				</b-col>
			</b-row>
		</div>
	</div>
</template>

<script>
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import "leaflet-fullscreen/dist/leaflet.fullscreen.css";
import "leaflet-fullscreen/dist/Leaflet.fullscreen";
import "leaflet-contextmenu/dist/leaflet.contextmenu";
import "leaflet-contextmenu/dist/leaflet.contextmenu.css";

import VSelect from "vue-select";
import "vue-select/dist/vue-select.css";

import {convertDateTimeNonSecond} from "@/utils";
import {defaultRegionCode, urlImage, defaultMap, defaultRegionWMS} from "@/constants/config";
import {listRegion} from "@/constants/region";
import {rootURL} from "@/utils/wfs";

import WMSCapabilities from "wms-capabilities";
import VueSlider from "vue-slider-component";
import "vue-slider-component/theme/antd.css";
import * as turf from "@turf/turf";

export default {
	components: {
		VSelect,
		VueSlider,
	},
	props: {
		dataMap: {
			type: Array,
		},
		cqlFilter: {
			type: Array,
		},
	},
	data() {
		return {
			defaultMap,
			defaultRegionCode,
			listRegion,
			defaultRegionWMS,
			urlImage,
			rootGeoserverURL: rootURL,
			windowWidth: window.innerWidth,
			map: null,
			streetLayerState: false,
			streetLayer: null,
			satelliteLayerState: false,
			satelliteLayer: null,
			terrainLayerState: false,
			terrainLayer: null,
			drawItems: null,
			drawWMSs: null,
			coordinates: null,
			bounds: null,
			currentZoomLevel: null,
			showPlanningNav: false,
			isHidePlanning: false,
			mapPlanning: [
				{
					label: "Quy hoạch tổng thể cây xanh",
					value: "TREE-PLANNING",
					detail: [
						{
							id: 1,
							label: "HTSDD Cây xanh",
							value: "TreeCurrent",
							type: "CURRENT",
							file: "http://file.nlt-group.com/GISLayer/CanTho/Hientrang.kml",
						},
						{
							id: 3,
							label: "QHSDD Cây xanh đến 2030",
							value: "TreeZone2030",
							type: "PLANNING",
							file: "http://file.nlt-group.com/GISLayer/CanTho/Quyhoach2030.kml",
						},
					],
				},
			],
			selectedTypePlanning: null,
			selectedPlanning: null,
			statePlanning: true,
			isShowSliderOpacityPlanning: false,
			opacityPlanning: 100,
			targetPlanning: null,
			targetPlanningData: null,
			selectedCurrent: null,
			isShowSliderOpacityCurrent: false,
			stateCurrent: true,
			opacityCurrent: 70,
			drawFilePlanning: null,
			drawFileCurrent: null,
			serverName: "SXDCT",
			highlightMarker: null,
		};
	},
	watch: {
		currentZoomLevel(to, from) {
			if (to && to < 17) {
				if (this.drawItems) this.drawItems.clearLayers();
				if (this.cqlFilter) this.toggleWMSLayer(this.drawWMSs, this.defaultRegionWMS, "TREE", "ADD", this.cqlFilter);
			} else {
				this.toggleWMSLayer(this.drawWMSs, this.defaultRegionWMS, "TREE", "REMOVE");
			}
		},
		bounds(to, from) {
			if (this.currentZoomLevel >= 17) {
				const array = this.returnDataBoudingBox(this.dataMap, to);
				this.$nextTick(() => {
					this.drawMarkerLevelZoom(array);
				});
			}
		},
		isHidePlanning(to, from) {
			if (to) {
				this.selectedOptionPlanning(this.selectedPlanning, false);
				this.selectedOptionCurrent(this.selectedCurrent, false);
			} else {
				if (this.selectedTypePlanning) {
					if (this.selectedPlanning) this.selectedOptionPlanning(this.selectedPlanning, true);
					if (this.selectedCurrent) this.selectedOptionCurrent(this.selectedCurrent, true);
				}
			}
		},
		selectedTypePlanning(to, from) {
			if (!to) {
				this.resetDataPlanning();
			}
		},
		selectedCurrent(to, from) {
			if (!to) {
				this.selectedOptionCurrent(to, false);
			}
		},
		selectedPlanning(to, from) {
			if (!to) {
				this.selectedOptionPlanning(to, false);
			}
		},
		cqlFilter(to, from) {
			if (to) this.loadLayer(to);
		},
	},
	mounted() {
		this.map = this.initMap(this.defaultRegionCode, this.defaultMap);
		this.$nextTick(() => {
			this.getBoudingBox();
			this.currentZoomLevel = this.map.getZoom();
			this.map.addEventListener("moveend", this.onResize);
			window.addEventListener("resize", this.onResize);
			var restrictedDiv = document.getElementById("map");
			restrictedDiv.addEventListener("contextmenu", function (event) {
				event.preventDefault();
			});
		});
	},
	methods: {
		returnDataBoudingBox(data, bounds) {
			if (!data || data?.length == 0) return;
			let array = [];
			data.forEach((x) => {
				var latLng = L.latLng(x.Lat != null && x.Lat != undefined ? x.Lat : x.StationLat, x.Long != null && x.Long != undefined ? x.Long : x.StationLong);
				if (bounds.contains(latLng)) {
					array.push(x);
				}
			});
			return array;
		},
		drawMarkerLevelZoom(data) {
			try {
				if (!data || data?.length == 0) {
					return;
				}
				if (this.drawItems) this.drawItems.clearLayers();
				data.forEach((x) => {
					let color_icon = x["StatusColor"] ? x["StatusColor"] : "blue";
					let name_icon = x["StationIcon"] ? x["StationIcon"].toUpperCase() : "TREE";
					let icon = new L.divIcon({
                                html: `<div class="custom-icon-wrapper text-center">
                                            <div class=" text-nowrap font-weight-bold">${x.TreeNumber}</div>
                                            <img src="${this.urlImage}Icon/${color_icon}_${name_icon}.svg" style="width: 36px;" />
                                        </div>`,
                                className: "hide-square-icon",
                                iconAnchor: [18, 36],
                                popupAnchor: [0, -36],
                            });
					let marker = L.marker([x["Lat"], x["Long"]], {icon: icon, StationID: x.StationID ? x.StationID : null, StationType: x.StationType ? x.StationType : null});
					if (x.StationType != "Tree") {
						let popupHTML = this.popupDataHover(x);
						let popup = L.popup({
							closeButton: false,
						}).setContent(popupHTML);
						marker.bindPopup(popup);
						marker.on("mouseover", function (e) {
							marker.openPopup();
						});
						marker.on("mouseout", function (e) {
							marker.closePopup();
						});
					}
					marker.on("click", () => this.$emit("pointClickOnMap", x));
					this.drawItems.addLayer(marker);
				});
			} catch (error) {
				console.log(error);
			}
		},
		popupDataHover(item) {
			let image_default = `${this.urlImage}No_Image_Available.jpg`;
			let image = item.TreeImage ? item.TreeImage : image_default;
			let name = item.StationName ? item.StationName : "";
			let address = item.StationAddress ? item.StationAddress : "";
			let time = item.UpdateTime ? this.convertDateTimeNonSecond(item.UpdateTime) : "";
			let html = `<div style="display: flex; width: 300px; font-family:'Inter', sans-serif !important">
                                <img style="max-width: 150px; height: 200px;" src="${image}"/>
                                <div class="pl-3">
                                    <p style="font-size: 13px; line-height:1.2;">
                                    <strong>${name}</strong>
                                    </p>
                                    <p class="font-italic text-small" style="margin-top: -10px;">${address}</p>
                                    <p class="pl-3 pr-3 font-italic" style="margin-top: -10px;">
                                        <div>
                                            <span class="text-muted">Cập nhật lúc:</span>
                                        </div>
                                        <span class="text-primary font-weight-bold">${time}</span>
                                    </p>
                                </div>
                            </div>`;
			return html;
		},
		initMap(code, defaultMap) {
			var self = this;
			let mapDefault = L.tileLayer(defaultMap.url, {minZoom: defaultMap.min, maxZoom: defaultMap.max, transparent: false, subdomains: ["mt0", "mt1", "mt2", "mt3"]});
			let mapSatelliteLayer = L.tileLayer("https://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}", {minZoom: defaultMap.min, maxZoom: defaultMap.max, transparent: false, subdomains: ["mt0", "mt1", "mt2", "mt3"]});
			let mapTerrainLayer = L.tileLayer("https://{s}.google.com/vt/lyrs=p&x={x}&y={y}&z={z}", {minZoom: defaultMap.min, maxZoom: defaultMap.max, transparent: false, subdomains: ["mt0", "mt1", "mt2", "mt3"]});
			let map = L.map("map", {
				center: [parseFloat(this.listRegion[code].lat), parseFloat(this.listRegion[code].lng)],
				zoom: defaultMap.zoom,
				layers: [mapDefault],
				attributionControl: false,
				contextmenu: true,
				contextmenuWidth: 150,
				contextmenuItems: [
					{
						text: "Trung tâm",
						callback: this.centerMap,
						icon: require("@/assets/image/street-view.png"),
					},
					{
						text: "Vị trí",
						callback: this.getCoordinates,
						icon: require("@/assets/image/navigation.png"),
					},
					{
						text: "Khung nhìn",
						callback: this.getBoudingBox,
						icon: require("@/assets/image/fullscreen.png"),
					},
				],
			});
			var baseMaps = {
				Street: mapDefault,
				Satellite: mapSatelliteLayer,
				Terrain: mapTerrainLayer,
			};
			L.control.layers(baseMaps).addTo(map);
			L.control.scale({position: "bottomright"}).addTo(map);
			L.control.fullscreen({position: "topright"}).addTo(map);
			map.zoomControl.setPosition("topright");
			L.Control.layerPlanningGroup = L.Control.extend({
				options: {
					position: "topright",
				},
				onAdd: function (map) {
					var container = L.DomUtil.create("div", "leaflet-bar");
					this._layerPlanningButton = this._createButton('<i class="fas fa-search"></i>', "Planning", "gin-control-planning", container, this._layerPlanning);
					return container;
				},
				_layerPlanning: function (e) {
					e.preventDefault();
					this._statePlanningNav(!this.showPlanningNav);
				},
				_statePlanningNav(state) {
					this.showPlanningNav = state;
					if (this.showPlanningNav) {
						if (window.innerWidth > 355 && window.innerWidth < 385) {
							document.getElementById("planningNav").style.width = "395px";
							document.getElementById("map").style.marginRight = "395px";
						} else if (window.innerWidth < 356) {
							document.getElementById("planningNav").style.width = "395px";
							document.getElementById("map").style.marginRight = "395px";
						} else {
							document.getElementById("planningNav").style.width = "395px";
							document.getElementById("map").style.marginRight = "395px";
						}
					} else {
						document.getElementById("planningNav").style.width = "0";
						document.getElementById("map").style.marginRight = "0";
					}
					self.resetDataPlanning();
					setTimeout(function () {
						map.invalidateSize();
					}, 200);
				},
				_createButton: function (html, title, className, container, fn) {
					var link = L.DomUtil.create("a", className, container);
					link.innerHTML = html;
					link.href = "#";
					link.title = title;
					L.DomEvent.on(link, "mousedown dblclick", L.DomEvent.stopPropagation).on(link, "click", L.DomEvent.stop).on(link, "click", fn, this).on(link, "click", this._refocusOnMap, this);
					return link;
				},
			});
			var layerPlanningGroup = new L.Control.layerPlanningGroup();
			layerPlanningGroup.addTo(map);
			this.drawFilePlanning = new L.layerGroup();
			map.addLayer(this.drawFilePlanning);
			this.targetPlanning = new L.layerGroup();
			map.addLayer(this.targetPlanning);
			this.targetPlanning.setZIndex(6);
			this.drawFileCurrent = new L.layerGroup();
			map.addLayer(this.drawFileCurrent);
			this.drawItems = new L.featureGroup();
			map.addLayer(this.drawItems);
			this.drawWMSs = new L.layerGroup();
			map.addLayer(this.drawWMSs);
			map.on("click", function (event) {
				const latlng = event.latlng;
				const layers = self.drawFilePlanning.getLayers();
				if (layers?.length > 0) {
					for (const x of layers) {
						const wmsUrl = `${self.rootGeoserverURL}${self.defaultRegionWMS}/wms`;
						const wmsParams = {
							service: "WMS",
							version: "1.1.0",
							request: "GetFeatureInfo",
							layers: `${self.defaultRegionWMS}:${x.options.id}`,
							transparent: true,
							format: "image/png",
							info_format: "application/json",
							query_layers: `${self.defaultRegionWMS}:${x.options.id}`,
							feature_count: 1,
							bbox: self.map.getBounds().toBBoxString(),
							width: self.map.getSize().x,
							height: self.map.getSize().y,
							x: event.containerPoint.x,
							y: event.containerPoint.y,
							srs: "EPSG:4326",
						};
						fetch(wmsUrl + "?" + new URLSearchParams(wmsParams))
							.then((response) => {
								if (!response.ok) {
									throw new Error(`Network response was not ok: ${response.status}`);
								}
								return response.json();
							})
							.then((data) => {
								self.targetPlanningData = data.features ? data.features : null;
								if (self.targetPlanningData?.length > 0) {
									const layers = self.targetPlanning.getLayers();
									if (layers?.length > 0) {
										for (const x of layers) {
											self.targetPlanning.removeLayer(x._leaflet_id);
										}
									}
									self.targetPlanningData.forEach((x) => {
										const newCoordinates = x.geometry.coordinates[0][0].map((pair) => [pair[1], pair[0]]);
										const polyLayers = L.polygon([newCoordinates], {
											color: "red",
											fillOpacity: 0.5,
										});
										var polygon = turf.polygon([newCoordinates]);
										var area = turf.area(polygon);
										if (!x.properties.area) {
											x.properties.area = `Reference-${area}`;
										}
										self.targetPlanning.addLayer(polyLayers);
									});
								}
							})
							.catch((error) => {
								console.error("Lỗi khi gửi yêu cầu GetFeatureInfo:", error);
							});
					}
				}
			});
			map.invalidateSize();
			return map;
		},
		onResize() {
			this.getBoudingBox();
			this.currentZoomLevel = this.map.getZoom();
			this.windowWidth = window.innerWidth;
		},
		centerMap(e) {
			this.map.panTo(e.latlng);
		},
		getCoordinates(e) {
			const latlng = e.latlng;
			this.coordinates = `${latlng.lat.toFixed(7)},${latlng.lng.toFixed(7)}`;
			this.$nextTick(() => {
				this.copyText(this.coordinates);
			});
		},
		getBoudingBox() {
			this.bounds = this.map.getBounds();
			let bbox = this.bounds ? `${this.bounds._northEast.lat.toFixed(7)},${this.bounds._northEast.lng.toFixed(7)},${this.bounds._southWest.lat.toFixed(7)},${this.bounds._southWest.lng.toFixed(7)}` : "0,0,0,0";
			this.$nextTick(() => {
				this.copyText(bbox);
			});
		},
		copyText(text) {
			const el = document.createElement("textarea");
			el.value = text;
			document.body.appendChild(el);
			el.select();
			document.execCommand("copy");
			document.body.removeChild(el);
		},
		async loadLayer(cqlFilter) {
			await this.toggleWMSLayer(this.drawWMSs, this.defaultRegionWMS, "TREE", "REMOVE");
			this.$nextTick(async () => {
				await this.toggleWMSLayer(this.drawWMSs, this.defaultRegionWMS, "TREE", "ADD", cqlFilter);
			});
		},
		toggleWMSLayer(drawWMSs, workspace, layer, type, cqlFilter = null) {
			const layers = drawWMSs.getLayers();
			switch (type) {
				case "ADD":
					this.drawWMS(workspace, layer, cqlFilter);
					break;
				case "REMOVE":
					const selectedLayer = layers.filter((x) => x.options.id === layer);
					if (selectedLayer) {
						if (selectedLayer.length > 0) {
							for (const x of selectedLayer) {
								drawWMSs.removeLayer(x._leaflet_id);
							}
						}
					}
					break;
			}
		},
		drawWMS(workspace, layer, cqlFilter = []) {
			var wmsLayer;
			if (cqlFilter?.length > 0) {
				cqlFilter.forEach((x) => {
					wmsLayer = L.tileLayer.wms(`${this.rootGeoserverURL}${workspace}/wms`, {
						layers: `${workspace}:${layer}`,
						format: "image/png",
						transparent: true,
						version: "1.1.0",
						minZoom: 1,
						maxZoom: 21,
						zIndex: 4,
						id: `${layer}`,
						CQL_FILTER: x,
					});
					this.drawWMSs.addLayer(wmsLayer);
				});
			} else {
				wmsLayer = L.tileLayer.wms(`${this.rootGeoserverURL}${workspace}/wms`, {
					layers: `${workspace}:${layer}`,
					format: "image/png",
					transparent: true,
					version: "1.1.0",
					minZoom: 1,
					maxZoom: 21,
					zIndex: 4,
					id: `${layer}`,
				});
				this.drawWMSs.addLayer(wmsLayer);
			}
		},
		changeOpacityPlanning(opacity, data) {
			const layers = this.drawFilePlanning.getLayers();
			const layer = layers?.length > 0 ? (layers.filter((x) => x.options.id == data.value)?.length > 0 ? layers.filter((x) => x.options.id == data.value)[0] : null) : null;
			if (layer) {
				layer.setOpacity(opacity / 100);
			}
		},
		changeOpacityCurrent(opacity, data) {
			const layers = this.drawFileCurrent.getLayers();
			const layer = layers?.length > 0 ? (layers.filter((x) => x.options.id == data.value)?.length > 0 ? layers.filter((x) => x.options.id == data.value)[0] : null) : null;
			if (layer) {
				layer.setOpacity(opacity / 100);
			}
		},
		selectedOptionPlanning(data, state) {
			if (!state) {
				const layers = this.drawFilePlanning.getLayers();
				for (const x of layers) {
					this.drawFilePlanning.removeLayer(x._leaflet_id);
				}
			} else {
				if (!data) return;
				const layer = L.tileLayer.wms(`${this.rootGeoserverURL}${this.defaultRegionWMS}/wms`, {
					layers: `${this.defaultRegionWMS}:${data.value}`,
					format: "image/png",
					transparent: true,
					version: "1.1.0",
					minZoom: 1,
					maxZoom: 21,
					zIndex: 5,
					opacity: this.opacityPlanning / 100,
					id: `${data.value}`,
				});
				this.drawFilePlanning.addLayer(layer);
				const wmsCapabilitiesURL = `${this.rootGeoserverURL}${this.defaultRegionWMS}/wms?service=WMS&version=1.3.0&request=GetCapabilities`;
				fetch(wmsCapabilitiesURL)
					.then((response) => response.text())
					.then((res) => {
						const json = new WMSCapabilities(res).toJSON();
						const layers = json?.Capability?.Layer?.Layer;
						const layer = layers?.filter((x) => x.Name === `${data.value}`)[0];
						const bbox = layer?.BoundingBox.filter((y) => y.crs == "EPSG:4326")[0].extent;
						this.map.fitBounds([
							[bbox[0], bbox[1]],
							[bbox[2], bbox[3]],
						]);
					})
					.catch((error) => {
						console.error("Lỗi khi tải thông tin GetCapabilities:", error);
					});
			}
		},
		selectedOptionCurrent(data, state) {
			if (!state) {
				const layers = this.drawFileCurrent.getLayers();
				for (const x of layers) {
					this.drawFileCurrent.removeLayer(x._leaflet_id);
				}
			} else {
				if (!data) return;
				const layer = L.tileLayer.wms(`${this.rootGeoserverURL}${this.defaultRegionWMS}/wms`, {
					layers: `${this.defaultRegionWMS}:${data.value}`,
					format: "image/png",
					transparent: true,
					version: "1.1.0",
					minZoom: 1,
					maxZoom: 21,
					zIndex: 4,
					opacity: this.opacityCurrent / 100,
					id: `${data.value}`,
				});
				this.drawFileCurrent.addLayer(layer);
				const wmsCapabilitiesURL = `${this.rootGeoserverURL}${this.defaultRegionWMS}/wms?service=WMS&version=1.3.0&request=GetCapabilities`;
				fetch(wmsCapabilitiesURL)
					.then((response) => response.text())
					.then((res) => {
						const json = new WMSCapabilities(res).toJSON();
						const layers = json?.Capability?.Layer?.Layer;
						const layer = layers?.filter((x) => x.Name === `${data.value}`)[0];
						const bbox = layer?.BoundingBox.filter((y) => y.crs == "EPSG:4326")[0].extent;
						this.map.fitBounds([
							[bbox[0], bbox[1]],
							[bbox[2], bbox[3]],
						]);
					})
					.catch((error) => {
						console.error("Lỗi khi tải thông tin GetCapabilities:", error);
					});
			}
		},
		resetDataPlanning() {
			this.selectedTypePlanning = null;
			this.selectedPlanning = null;
			this.statePlanning = true;
			this.isShowSliderOpacityPlanning = false;
			this.opacityPlanning = 100;
			this.targetPlanningData = null;
			this.selectedCurrent = null;
			this.stateCurrent = true;
			this.isShowSliderOpacityCurrent = false;
			this.opacityCurrent = 70;
			const layers = this.drawFilePlanning.getLayers();
			for (const x of layers) {
				this.drawFilePlanning.removeLayer(x._leaflet_id);
			}
			const _layers = this.drawFileCurrent.getLayers();
			for (const x of _layers) {
				this.drawFileCurrent.removeLayer(x._leaflet_id);
			}
			const __layers = this.targetPlanning.getLayers();
			if (__layers?.length > 0) {
				for (const x of __layers) {
					this.targetPlanning.removeLayer(x._leaflet_id);
				}
			}
		},
		flyToPoint(lat, lng, isHLight = true) {
			if (!lat || lat == 0 || lat == "" || !lng || lng == 0 || lng == "") {
				this.$notify("warning", this.$t("warning"), this.$t("no-coordinates"), {
					duration: 3000,
					permanent: false,
				});
				return;
			}
			if(isHLight) {
				if (this.highlightMarker) {
					this.map.removeLayer(this.highlightMarker);
				}
				let icon = new L.divIcon({
					html: `<div class="marker-blink"><div class="marker-border" style="border: 1px solid red !important;"></div></div>`,
					className: "hide-square-icon",
					iconSize: [30, 30],
					iconAnchor: [27, 15],
					popupAnchor: [0, -36],
				});
				this.highlightMarker = L.marker([lat, lng],{icon: icon}).addTo(this.map);
			}
			this.map.flyTo([parseFloat(lat), parseFloat(lng)], 21, {
				animate: true,
				duration: 2,
			});
		},
		convertDateTimeNonSecond(string) {
			return convertDateTimeNonSecond(string);
		},
	},
};
</script>
<style lang="scss">
.location-btn {
	padding: 8px;
	cursor: pointer;
	border-radius: 8px;
	border: 1px solid var(--website-brand-color, #c71313) !important;
	/* App/Shadow 1 App */
	box-shadow: 0px 1px 4px 0px rgba(66, 71, 76, 0.12), 0px 0px 0.5px 0px rgba(198, 204, 198, 0.32);
}
.custom-popup .leaflet-popup-content-wrapper .leaflet-popup-content {
	margin: 10px;
}
.route-name {
	color: var(--website-brand-color, #c71313);
	font-size: 17px;
	letter-spacing: -0.4px;
	font-weight: 500;
	padding-bottom: 2px;
	margin: 0px 0px 10px 0px !important;
}
.detail {
	border-radius: 8px;
	border: 1px solid transparent;
	background: var(--system-background-primary, #c71313);
	color: var(--website-brand-color, #fff);
	font-size: 17px;
	/* App/Shadow 1 App */
	box-shadow: 0px 1px 4px 0px rgba(66, 71, 76, 0.12), 0px 0px 0.5px 0px rgba(198, 204, 198, 0.32);
	padding: 8px;
}
.detail:hover {
	background: var(--system-background-primary, #a11616);
	color: var(--website-brand-color, #fff);
}
.detail:focus {
	background: var(--system-background-primary, #a11616);
	color: var(--website-brand-color, #fff);
}
.vs__selected {
	font-size: 13px;
}
.vs__dropdown-menu {
	font-size: 13px;
}
.vs__selected-options {
	padding: 2px;
}
.vs__actions {
	padding: 5px 6px 0 3px;
	.vs__clear {
		margin-top: -2px;
	}
}
.vue-slider-process {
	background-color: var(--website-brand-color, #029834) !important;
}
.vue-slider-dot-handle {
	border: 2px solid var(--website-brand-color, #029834) !important;
}
.leaflet-control-layers-toggle {
	width: 30px !important;
	height: 30px !important;
}
.marker-blink,.marker-border-blue,.marker-border-ff2d55,.marker-border-ff9500,.marker-border-gray,.marker-border {
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.marker-blink strong{
    position: absolute;
    top: -45%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: gray;
}
.icon-marker {
    height: 30px;
    width: 30px;
    /* background: #5292CC; */
    animation-name: col;
    animation-duration: 2s;
    animation-iteration-count: infinite;
}
.marker-border {
    height: 30px;
    width: 30px;
    animation-name: bord-pop;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    box-shadow: 2px 2px 5px #ccc, -2px -2px 5px #ccc;
}
@keyframes bord-pop {
    0% {
        transform: translate(-50%, -50%);
    }

    50% {
        transform: translate(-50%, -50%) scale(1.9);
        opacity: 0.1;
    }

    100% {
        transform: translate(-50%, -50%) scale(1.9);
        opacity: 0;
    }
}
</style>
<style lang="scss" scoped>
.btn-config{
	height: 39px; 
	width: 39px; 
	border-radius: 10px; 
	color: #fafafa;
	border: none;
	background: var(--Brand, #029834) !important;
}
.btn-config:hover{
	height: 39px; 
	width: 39px; 
	border-radius: 10px; 
	background: var(--Brand, #029834) !important;
}
.btn-config:disabled,
.btn-config[disabled]{
	opacity: 0.65;
}

#map {
	height: calc(100vh - 64px);
	z-index: 0;
	background-color: white;
}
.planning-nav {
	height: calc(100vh - 64px);
	position: absolute;
	z-index: 1;
	width: 0;
	top: 0;
	right: 0;
	background-color: #F2F2F7;
	overflow-x: hidden;

	.header-planning-nav {
		padding: 24px 16px 8px 16px;
	}
}
</style>
