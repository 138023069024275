import Vue from 'vue';
import App from './App.vue';
import router from './router';
import NowUiKit from './plugins/now-ui-kit';
import store from './store'

// VueFileAgent 
import VueFileAgent from 'vue-file-agent';
// BootstrapVue add
import BootstrapVue from "bootstrap-vue";
//Muti
import VueI18n from 'vue-i18n'
import en from './locales/en.json'
import vn from './locales/vn.json'
import { defaultLocale, firebaseConfig } from './constants/config'

Vue.use(VueFileAgent);
Vue.config.productionTip = false;
Vue.use(BootstrapVue);
Vue.use(NowUiKit);
Vue.use(VueI18n);
const messages = { vi: vn, en: en }
const locale = (localStorage.getItem('currentLanguage') && JSON.parse(localStorage.getItem('listLanguage'))?.length > 0 && JSON.parse(localStorage.getItem('listLanguage')).filter(x => x.id === localStorage.getItem('currentLanguage')).length > 0) ? localStorage.getItem('currentLanguage') : defaultLocale
const i18n = new VueI18n({
  locale: locale,
  fallbackLocale: locale,
  messages     
})
export default new Vue({
  i18n,
  router,
  store,
  render: h => h(App)
}).$mount('#app');
