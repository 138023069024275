import Repository from "../../repository";

export default {
    Get: (payload) => Repository.post(`/api/ReceiveShipRequests/Get`, payload),
    GetByCustomer: (payload) => Repository.post(`/api/ReceiveShipRequests/GetByCustomer`, payload),
    GetListCustomerByCusID: (payload) => Repository.post(`/api/ReceiveShipRequests/GetListCustomerByCusID`, payload),
    GetListContactByCusID: (payload) => Repository.post(`/api/ReceiveShipRequests/GetListContactByCusID`, payload),
    GetListCustomerByUserID: (payload) => Repository.post(`/api/ReceiveShipRequests/GetListCustomerByUserID`, payload),
    GetCancelReasonsByCustomer: (payload) => Repository.post(`/api/ReceiveShipRequests/GetCancelReasonsByCustomer`, payload),
    GetCancelReasonsByCoordinator: (payload) => Repository.post(`/api/ReceiveShipRequests/GetCancelReasonsByCoordinator`, payload),
    GetCancelReasonsBySale: (payload) => Repository.post(`/api/ReceiveShipRequests/GetCancelReasonsBySale`, payload),
    GetActive: (payload) => Repository.post(`/api/ReceiveShipRequests/GetActive`, payload),
    GetById: (payload) => Repository.post(`/api/ReceiveShipRequests/GetById`, payload),
    Copy: (payload) => Repository.post(`/api/ReceiveShipRequests/Copy`, payload),
    Add: (payload) => Repository.post(`/api/ReceiveShipRequests/Add`, payload),
    AddMobile: (payload) => Repository.post(`/api/ReceiveShipRequests/AddMobile`, payload),
    Edit: (payload) => Repository.post(`/api/ReceiveShipRequests/Edit`, payload),
    EditMobile: (payload) => Repository.post(`/api/ReceiveShipRequests/EditMobile`, payload),
    Cancel: (payload) => Repository.post(`/api/ReceiveShipRequests/Cancel`, payload),
    CoordinatorProcess: (payload) => Repository.post(`/api/ReceiveShipRequests/CoordinatorProcess`, payload),
    SaleProcess: (payload) => Repository.post(`/api/ReceiveShipRequests/SaleProcess`, payload),
    Delete: (payload) => Repository.post(`/api/ReceiveShipRequests/Delete`, payload),
    ChangeStatus: (payload) => Repository.post(`/api/ReceiveShipRequests/ChangeStatus`, payload),
}