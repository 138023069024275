import Repository from "../../repository";

export default {
    Get: (payload) => Repository.post(`/api/WaterwayStations/Get`, payload),
    GetActive: (payload) => Repository.post(`/api/WaterwayStations/GetActive`, payload),
    GetExport: (payload) => Repository.post(`/api/WaterwayStations/GetExport`, payload),
    GetById: (payload) => Repository.post(`/api/WaterwayStations/GetById`, payload),
    Add: (payload) => Repository.post(`/api/WaterwayStations/Add`, payload),
    Export: (payload) => Repository.post(`/api/WaterwayStations/Export`, payload),
    Edit: (payload) => Repository.post(`/api/WaterwayStations/Edit`, payload),
    Deploy: (payload) => Repository.post(`/api/WaterwayStations/Deploy`, payload),
    Delete: (payload) => Repository.post(`/api/WaterwayStations/Delete`, payload),
    ChangeStatus: (payload) => Repository.post(`/api/WaterwayStations/ChangeStatus`, payload),
}
