import Repository from "../../repository";

export default {
    Get: (payload) => Repository.post(`/api/Customers/Get`, payload),
    GetActive: (payload) => Repository.post(`/api/Customers/GetActive`, payload),
    GetById: (payload) => Repository.post(`/api/Customers/GetById`, payload),
    Add: (payload) => Repository.post(`/api/Customers/Add`, payload),
    Edit: (payload) => Repository.post(`/api/Customers/Edit`, payload),
    Delete: (payload) => Repository.post(`/api/Customers/Delete`, payload),
    ChangeStatus: (payload) => Repository.post(`/api/Customers/ChangeStatus`, payload),
    GetByGroupId: (payload) => Repository.post(`/api/Customers/GetByGroupId`, payload),
    ResetPassword: (payload) => Repository.post(`/api/Customers/ResetPassword`, payload),
}



