import Repository from "../../repository";

export default {
    Get: (payload) => Repository.post(`/api/ShippingAndCreditnPL/Get`, payload),
    GetActive: (payload) => Repository.post(`/api/ShippingAndCreditnPL/GetActive`, payload),
    GetMobile: (payload) => Repository.post(`/api/ShippingAndCreditnPL/GetMobile`, payload),
    GetById: (payload) => Repository.post(`/api/ShippingAndCreditnPL/GetById`, payload),
    Copy: (payload) => Repository.post(`/api/ShippingAndCreditnPL/Copy`, payload),
    Add: (payload) => Repository.post(`/api/ShippingAndCreditnPL/Add`, payload),
    Edit: (payload) => Repository.post(`/api/ShippingAndCreditnPL/Edit`, payload),
    Delete: (payload) => Repository.post(`/api/ShippingAndCreditnPL/Delete`, payload),
    ChangeStatus: (payload) => Repository.post(`/api/ShippingAndCreditnPL/ChangeStatus`, payload),
}