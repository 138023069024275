import Repository from "../../repository";

export default {
    GetListMenu: (payload) => Repository.post(`/api/menu/GetListMenu`, payload),
    GetMenuById: (payload) => Repository.post(`/api/menu/GetMenuById`, payload),
    EditMenu: (payload) => Repository.post(`/api/menu/EditMenu`, payload),
    EditStatus: (payload) => Repository.post(`/api/menu/EditStatus`, payload),
    GetListMenuByGroupID: (payload) => Repository.post(`/api/menu/GetListMenuByGroupID`, payload),
    GetMenuRightByGroupID: (payload) => Repository.post(`/api/menu/GetMenuRightByGroupID`, payload),
    EditMenuRightByGroupID: (payload) => Repository.post(`/api/menu/EditMenuRightByGroupID`, payload),
    EditMenuRight: (payload) => Repository.post(`/api/menu/EditMenuRight`, payload),
}

