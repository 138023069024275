import Repository from "../../repository";

export default {
    Get: (payload) => Repository.post(`/api/StationExtras/Get`, payload),
    GetActive: (payload) => Repository.post(`/api/StationExtras/GetActive`, payload),
    GetById: (payload) => Repository.post(`/api/StationExtras/GetById`, payload),
    Add: (payload) => Repository.post(`/api/StationExtras/Add`, payload),
    Edit: (payload) => Repository.post(`/api/StationExtras/Edit`, payload),
    Control: (payload) => Repository.post(`/api/StationExtras/Control`, payload),
    Delete: (payload) => Repository.post(`/api/StationExtras/Delete`, payload),
    ChangeStatus: (payload) => Repository.post(`/api/StationExtras/ChangeStatus`, payload),
}
