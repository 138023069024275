import Repository from "../../repository";

export default {
    Get: (payload) => Repository.post(`/api/CustomActions/Get`, payload),
    GetById: (payload) => Repository.post(`/api/CustomActions/GetById`, payload),
    Add: (payload) => Repository.post(`/api/CustomActions/Add`, payload),
    Edit: (payload) => Repository.post(`/api/CustomActions/Edit`, payload),
    ChangeStatus: (payload) => Repository.post(`/api/CustomActions/ChangeStatus`, payload),
    Delete: (payload) => Repository.post(`/api/CustomActions/Delete`, payload),
}
