import Repository from "../../repository";

export default {
    Get: (payload) => Repository.post(`/api/MaintenanceAllowances/Get`, payload),
    GetById: (payload) => Repository.post(`/api/MaintenanceAllowances/GetById`, payload),
    Add: (payload) => Repository.post(`/api/MaintenanceAllowances/Add`, payload),
    Copy: (payload) => Repository.post(`/api/MaintenanceAllowances/Copy`, payload),
    Edit: (payload) => Repository.post(`/api/MaintenanceAllowances/Edit`, payload),
    Delete: (payload) => Repository.post(`/api/MaintenanceAllowances/Delete`, payload),
    ChangeStatus: (payload) => Repository.post(`/api/MaintenanceAllowances/ChangeStatus`, payload),
}