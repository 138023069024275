import Repository from "../../repository";

export default {
    Get: (payload) => Repository.post(`/api/ProposedShippingPrices/Get`, payload),
    GetActive: (payload) => Repository.post(`/api/ProposedShippingPrices/GetActive`, payload),
    GetById: (payload) => Repository.post(`/api/ProposedShippingPrices/GetById`, payload),
    Add: (payload) => Repository.post(`/api/ProposedShippingPrices/Add`, payload),
    Edit: (payload) => Repository.post(`/api/ProposedShippingPrices/Edit`, payload),
    Delete: (payload) => Repository.post(`/api/ProposedShippingPrices/Delete`, payload),
    ChangeStatus: (payload) => Repository.post(`/api/ProposedShippingPrices/ChangeStatus`, payload),
}