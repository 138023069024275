import Repository from "../../repository";

export default {
    GetLaneList: (payload) => Repository.post(`/api/WimDatas/GetLaneList`, payload),
    GetVehicleTypeList: (payload) => Repository.post(`/api/WimDatas/GetVehicleTypeList`, payload),
    Get: (payload) => Repository.post(`/api/WimDatas/Get`, payload),
    GetMore: (payload) => Repository.post(`/api/WimDatas/GetMore`, payload),
    GetById: (payload) => Repository.post(`/api/WimDatas/GetById`, payload),
    GetForReport: (payload) => Repository.post(`/api/WimDatas/GetForReport`, payload),
    GetOverload: (payload) => Repository.post(`/api/WimDatas/GetOverload`, payload),
    GetReport: (payload) => Repository.post(`/api/WimDatas/GetReport`, payload),
    GetHistories: (payload) => Repository.post(`/api/WimDatas/GetHistories`, payload),
    AddWimReport: (payload) => Repository.post(`/api/WimDatas/AddWimReport`, payload),
    EditWimReport: (payload) => Repository.post(`/api/WimDatas/EditWimReport`, payload),
}
