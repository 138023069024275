import Repository from "../../repository";

export default {
    Get: (payload) => Repository.post(`/api/BalanceDebits/Get`, payload),
    GetById: (payload) => Repository.post(`/api/BalanceDebits/GetById`, payload),
    Add: (payload) => Repository.post(`/api/BalanceDebits/Add`, payload),
    AddWithExcel: (payload) => Repository.post(`/api/BalanceDebits/AddWithExcel`, payload),
    Edit: (payload) => Repository.post(`/api/BalanceDebits/Edit`, payload),
    Delete: (payload) => Repository.post(`/api/BalanceDebits/Delete`, payload),
}
