import Repository from "../../repository";

export default {
    Get: (payload) => Repository.post(`/api/RenewalDocs/Get`, payload),
    GetTotal: (payload) => Repository.post(`/api/RenewalDocs/GetTotal`, payload),
    GetActive: (payload) => Repository.post(`/api/RenewalDocs/GetActive`, payload),
    GetById: (payload) => Repository.post(`/api/RenewalDocs/GetById`, payload),
    Copy: (payload) => Repository.post(`/api/RenewalDocs/Copy`, payload),
    Add: (payload) => Repository.post(`/api/RenewalDocs/Add`, payload),
    Edit: (payload) => Repository.post(`/api/RenewalDocs/Edit`, payload),
    Delete: (payload) => Repository.post(`/api/RenewalDocs/Delete`, payload),
    ChangeStatus: (payload) => Repository.post(`/api/RenewalDocs/ChangeStatus`, payload),
}
