import Repository from "../../repository";

export default {
    GetListColor: (payload) => Repository.post(`/api/Colors/GetListColor`, payload),
    GetListColorActive: (payload) => Repository.post(`/api/Colors/GetListColorActive`, payload),
    GetColorById: (payload) => Repository.post(`/api/Colors/GetColorById`, payload),
    AddColor: (payload) => Repository.post(`/api/Colors/AddColor`, payload),
    EditColor: (payload) => Repository.post(`/api/Colors/EditColor`, payload),
    DeleteColor: (payload) => Repository.post(`/api/Colors/DeleteColor`, payload),
    ChangeStatusColor: (payload) => Repository.post(`/api/Colors/ChangeStatusColor`, payload),
}