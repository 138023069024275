<template>
  <div v-if="items" class="list">
    <div class="d-flex align-items-center mb-3">
      <div class="dot mr-3"></div>
      <strong class="name-info">Tên cây: </strong>
      <p class="value-info">{{ items.StationName }}</p>
    </div>
    <div class="d-flex align-items-center mb-3">
      <div class="dot mr-3"></div>
      <strong class="name-info">Số cây: </strong>
      <p class="value-info">{{ items.TreeNumber }}</p>
    </div>
    <div class="d-flex align-items-center mb-3">
      <div class="dot mr-3"></div>
      <strong class="name-info">Chiều cao(cm): </strong>
      <p class="value-info">{{ items.TreeHeight }}</p>
    </div>
    <div class="d-flex align-items-center mb-3">
      <div class="dot mr-3"></div>
      <strong class="name-info">ĐK thân(cm): </strong>
      <p class="value-info">{{ items.TreeWidth }}</p>
    </div>
    <div class="d-flex align-items-center mb-3">
      <div class="dot mr-3"></div>
      <strong class="name-info">ĐK tán lá(cm): </strong>
      <p class="value-info">{{ items.DiameterFoliagesRefer }}</p>
    </div>
    <div class="d-flex align-items-center mb-3">
      <div class="dot mr-3"></div>
      <strong class="name-info">Loại cây bóng mát: </strong>
      <p class="value-info">{{ items.NormalTimeWaiting }} - {{ items.PeakTimeWaiting }}</p>
    </div>
    <div class="d-flex align-items-center mb-3">
      <div class="dot mr-3"></div>
      <strong class="name-info">Tuổi thọ: </strong>
      <p class="value-info">{{ items.Lifespan }}</p>
    </div>
    <div class="d-flex align-items-center mb-3" style="flex-wrap: wrap;">
      <div class="dot mr-3"></div>
      <strong class="name-info">Tình trạng cây: </strong>
      <p class="value-info text-wrap">{{ items.StatusName }} </p>
    </div>
    <div class="d-flex align-items-center mb-3" style="flex-wrap: wrap;">
      <div class="dot mr-3"></div>
      <strong class="name-info">Địa chỉ: </strong>
      <p class="value-info">{{ items.FullAddress }} </p>
    </div>
    <div class="d-flex align-items-center mb-3" style="flex-wrap: wrap;">
      <div class="dot mr-3"></div>
      <strong class="name-info">Tuyến/Khu vực: </strong>
      <p class="value-info">{{ items.TreeRouteName }} </p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    items: {
      type: Object,
    },
    listBusStop: {
        type: Array,
    }
  },
};
</script>

<style scoped>
.name-info {
  color: var(--label-color-primary, #000);
  font-size: 15px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: -0.4px;
  margin-right: 3px;
}
.value-info {
  color: var(--label-color-primary, #000);
  font-size: 15px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.4px;
  margin: 0;
}
.dot {
  border-radius: 50%;
  background: var(--website-brand-color, #029834);
  width: 8px;
  height: 8px;
  z-index: 999;
}
.list {
  padding: 8px 16px;
}
</style>
