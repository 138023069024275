import Repository from "../../repository";

export default {
    Get: (payload) => Repository.post(`/api/GroupUserCustomers/Get`, payload),
    GetActiveAll: (payload) => Repository.post(`/api/GroupUserCustomers/GetActiveAll`, payload),
    GetActive: (payload) => Repository.post(`/api/GroupUserCustomers/GetActive`, payload),
    GetById: (payload) => Repository.post(`/api/GroupUserCustomers/GetById`, payload),
    Add: (payload) => Repository.post(`/api/GroupUserCustomers/Add`, payload),
    Edit: (payload) => Repository.post(`/api/GroupUserCustomers/Edit`, payload),
    Delete: (payload) => Repository.post(`/api/GroupUserCustomers/Delete`, payload),
    ChangeStatus: (payload) => Repository.post(`/api/GroupUserCustomers/ChangeStatus`, payload),
}



