import Repository from "../../repository";

export default {
    GetDeviceLink: (payload) => Repository.post(`/api/StationLinks/GetDeviceLink`, payload),
    GetStationDevice: (payload) => Repository.post(`/api/StationLinks/GetStationDevice`, payload),
    EditDevice: (payload) => Repository.post(`/api/StationLinks/EditDevice`, payload),
    GetSpeakerLink: (payload) => Repository.post(`/api/StationLinks/GetSpeakerLink`, payload),
    GetStationSpeaker: (payload) => Repository.post(`/api/StationLinks/GetStationSpeaker`, payload),
    EditSpeaker: (payload) => Repository.post(`/api/StationLinks/EditSpeaker`, payload),
    GetStationLink: (payload) => Repository.post(`/api/StationLinks/GetStationLink`, payload),
    GetStations: (payload) => Repository.post(`/api/StationLinks/GetStations`, payload),
    EditStations: (payload) => Repository.post(`/api/StationLinks/EditStations`, payload),
}
