<template>
  <div
    style="
      background: var(--pattern, #f0f8f4);
      padding-top: 120px;
      padding-bottom: 120px;
    "
  >
    <div class="custom-container">
      <div class="grid-container">
        <div class="content-grid resp reveal">
          <h5 class="text-green ma-28-semi text-uppercase">{{ $t('home-title-2') }}</h5>
          <p class="in-16-re">
            {{ $t('home-decrtiption-2') }}
          </p>
          <div>
            <button class="custom-button text-green in-14-semi ml-auto text-uppercase">
            {{ $t('learn-more') }}
          </button>
          </div>
          
        </div>
        <div class="img-content d-flex justify-content-end">
          <div class="curtain reveal-child"></div>
          <img src="@/assets/image/bg/bg_14.png" class="digi-img" height="100%" />
        </div>
        <div class="img-content2 d-flex justify-content-end">
          <div class="curtain reveal-child"></div>
          <img src="@/assets/image/bg/bg_15.png" class="digi-img" width="100%" />
        </div>
        <div class="img-content3 d-flex align-items-center">
          <div class="curtain reveal-child"></div>
          <img src="@/assets/image/bg/bg_16.png" class="digi-img" width="100%" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      windowHeight: window.innerHeight,
    };
  },
  watch: {
    windowHeight() {},
  },
  created() {
    localStorage.setItem("currentUrl", "HOME");
    window.addEventListener("scroll", this.handleScroll);
  },
  unmounted() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    handleScroll(value) {
      let reveals = document.querySelectorAll(".reveal-child");
      let img = document.querySelectorAll(".digi-img");
      let height = window.innerHeight;
      for (let i = 0; i < reveals.length; i++) {
        let windowHeight = window.innerHeight;
        let elementTop = reveals[0].getBoundingClientRect().top;
        let elementVisible = 300;
        const rect = reveals[i].getBoundingClientRect();
				const visiblePercentage = (Math.min(rect.bottom, windowHeight) - Math.max(rect.top, 0)) / rect.height * 100;
        if (elementTop < windowHeight - elementVisible) {
          // reveals[i].classList.add("active");
          // reveals[i].style.opacity = `${this.map_range(visiblePercentage,0,100,0,1)}`
          reveals[i].style.width = `${this.map_range(visiblePercentage,0,100,100,0) > 100 ? 100 : this.map_range(visiblePercentage,0,100,100,0)}%`
          img[i].style.opacity = `${this.map_range(visiblePercentage,0,100,0,1)}`
          // reveals[i].style.visibility = 'hidden';
        } else {
          // reveals[i].classList.remove("active");
          // reveals[i].style.opacity = '0'
          img[i].style.opacity = '0'
          reveals[i].style.width  = '100%'
          reveals[i].style.visibility = 'visible';
        }
      }
    },
    map_range(value, low1, high1, low2, high2) {
			return low2 + (high2 - low2) * (value - low1) / (high1 - low1);
		}
  },
};
</script>

<style scoped>
.grid-container {
  display: grid;
  grid-gap: 24px;
  grid-template-areas:
    "content content content content"
    "image-1 image-1 image-3 image-3"
    "image-2 image-2 image-2 image-2";
}
.resp {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* align-items: end; */
  text-align: center ;
}
@media screen and (min-width: 1024px) {
  .grid-container {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-template-areas:
      "content content content content image-1 image-1 image-1 image-3 image-3 image-3"
      "image-2 image-2 image-2 image-2 image-2 image-2 image-2 image-3 image-3 image-3";
  }
  .resp {
    text-align: left;
  }
}
@media screen and (min-width: 1440px) {
  .grid-container {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-template-areas:
      ". content content content content image-1 image-1 image-1 image-3 image-3 image-3 ."
      ". image-2 image-2 image-2 image-2 image-2 image-2 image-2 image-3 image-3 image-3 .";
  }
  .resp {
    text-align: right;
  }
  
}
.digi-img{
  transition: 1s all ease;
}
.reveal-child {
  position: relative;
  transform: translateX(0);
  opacity: 1;
  transition: 1s all ease;
}
.reveal-child.active {
  /* transform: translateX(100%); */
  opacity: 1;
  visibility: hidden;
  width: 0%;
}
.curtain {
  position: absolute;
  top: 0;
  height: 100%;
  /* width: 100%; */
  background-color: #f0f8f4;
  /* box-shadow: 0px 0px 8px 2px #fbfbfb; */
  z-index: 1;
}
.content-grid {
  grid-area: content;
}
.img-content {
  grid-area: image-1;
  position: relative;
}
.img-content2 {
  grid-area: image-2;
  position: relative;
}
.img-content3 {
  grid-area: image-3;
  position: relative;
}
.custom-button {
  border-radius: 40px;
  border: 1.5px solid var(--hover, #063);
  padding: 8px 16px;
  background: none;
  transition: 0.2s;
}
.custom-button:hover{
  background: #063;
  color: #ffff !important;
}
.reveal {
  position: relative;
  transform: translateY(150px);
  opacity: 0;
  transition: 1s all ease;
}
.reveal.active {
  transform: translateY(0);
  opacity: 1;
}
</style>
