import Repository from "../../repository";

export default {
    Get: (payload) => Repository.post(`/api/FoodCourts/Get`, payload),
    GetFoodCourtRight: (payload) => Repository.post(`/api/FoodCourts/GetFoodCourtRight`, payload),
    GetActive: (payload) => Repository.post(`/api/FoodCourts/GetActive`, payload),
    GetById: (payload) => Repository.post(`/api/FoodCourts/GetById`, payload),
    Add: (payload) => Repository.post(`/api/FoodCourts/Add`, payload),
    Edit: (payload) => Repository.post(`/api/FoodCourts/Edit`, payload),
    Delete: (payload) => Repository.post(`/api/FoodCourts/Delete`, payload),
    ChangeStatus: (payload) => Repository.post(`/api/FoodCourts/ChangeStatus`, payload),
}
