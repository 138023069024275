<template>
  <div
    class=""
    style="
      background-image: url('/assets/image/bg/bg_26.png');
      background-size: cover;
      background-position: top center;
      padding: 120px 0;
    "
  >
    <div class="custom-container">
      <div class="row">
        <div class="col-lg-7"></div>
        <div class="col-lg-5 p-0">
          <div class="form-card">
            <h2 class="ma-28-semi text-green">{{ $t("comment") }}</h2>
            <b-form @submit="onSubmit" @reset="onReset">
              <b-form-group
                id="input-group-1"
                :label="$t('name')"
                label-for="input-1"
                :invalid-feedback="invalidFeedback"
                :state="state"
              >
                <b-form-input
                  id="input-1"
                  :state="state"
                  v-model="name"
                ></b-form-input>
              </b-form-group>

              <b-form-group
                id="input-group-2"
                :label="$t('mail')"
                label-for="input-2"
              >
                <b-form-input id="input-2" v-model="email"></b-form-input>
              </b-form-group>
              <b-form-group
                id="input-group-2"
                :label="$t('sdt')"
                label-for="input-3"
                :invalid-feedback="invalidFeedback"
                :state="state"
              >
                <b-form-input
                  id="input-3"
                  :state="state"
                  v-model="phoneNumber"
                ></b-form-input>
              </b-form-group>
              <b-form-group
                id="input-group-2"
                :label="$t('title-feedback')"
                label-for="input-3"
                :invalid-feedback="invalidFeedback"
                :state="state"
              >
                <b-form-input
                  id="input-3"
                  :state="state"
                  v-model="subject"
                ></b-form-input>
              </b-form-group>
              <b-form-group
                id="input-group-2"
                :label="$t('comment-content')"
                label-for="input-3"
                :invalid-feedback="invalidFeedback"
                :state="state"
              >
                <b-form-textarea
                  rows="5"
                  :state="state"
                  id="input-3"
                  v-model="note"
                ></b-form-textarea>
              </b-form-group>
              <button
                type="submit"
                class="w-100 submit-btn text-uppercase mt-2"
                squared
              >
                {{ $t("send") }}
              </button>
            </b-form>
          </div>
        </div>
        <b-modal
          id="succes-modal"
          size="md"
          hide-footer
          hide-header
          centered
          scrollable
          v-model="isShow"
        >
          <div class="text-center">
            <svg
              width="64"
              height="64"
              viewBox="0 0 64 64"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g id="c-check 1">
                <g id="Group">
                  <g id="Group_2">
                    <path
                      id="Vector"
                      d="M31.9994 62.6661C15.0893 62.6661 1.33276 48.909 1.33276 31.9995C1.33276 15.09 15.0893 1.33281 31.9994 1.33281C48.9096 1.33281 62.6661 15.09 62.6661 31.9995C62.6661 48.909 48.9096 62.6661 31.9994 62.6661Z"
                      fill="#029834"
                    />
                    <path
                      id="Vector_2"
                      d="M26.6655 46.4372L12.228 31.9991L15.9989 28.2276L26.6655 38.8943L47.9989 17.5609L51.7697 21.3324L26.6655 46.4372Z"
                      fill="#FCFCFC"
                    />
                  </g>
                </g>
              </g>
            </svg>
            <p class="succes-title mt-2 mb-4">Cám ơn</p>
            <p class="mb-4" style="font-size: 16px">
              Chúng tôi đã nhận được tin nhắn của bạn. Nếu yêu cầu là khẩn cấp,
              vui lòng gọi đến số
              <strong class="font-weight-bold">(+84) 0389 150 134</strong> để
              nói chuyện với một trong những nhân viên của chúng tôi. Nếu không,
              chúng tôi sẽ liên hệ với bạn qua email trong 2 ngày làm việc.
            </p>
            <b-button
              pill
              style="background-color: #063"
              class="px-5"
              @click="$bvModal.hide('succes-modal')"
              >ĐÃ HIỂU</b-button
            >
          </div>
        </b-modal>
      </div>
    </div>
  </div>
</template>
<script>
import { Card, FormGroupInput, Button } from "@/components";
import RepositoryFactory from "@/repository/factory";
const guestContacts = RepositoryFactory.get("guestContacts");

export default {
  components: {
    [Button.name]: Button,
    [FormGroupInput.name]: FormGroupInput,
  },
  computed: {
    invalidFeedback() {
      return "Vui lòng nhập đủ thông tin được yêu cầu ";
    },
  },
  watch: {
    isShow(to) {
      if (to === false) {
        this.name = null;
        this.email = null;
        this.phoneNumber = null;
        this.note = null;
        this.state = null;
      }
    },
  },
  data() {
    return {
      isShow: false,
      state: null,
      name: null,
      email: null,
      phoneNumber: null,
      note: null,
      subject: null,
    };
  },
  methods: {
    async onSubmit(event) {
      event.preventDefault();
      if (this.name && this.email && this.phoneNumber && this.note) {
        this.state = true;
      } else {
        this.state = false;
      }
      if (!this.state) return;
      let body = {
        ServerName: "SXDCT",
        GeoCode: "TREE",
        Name: this.name,
        Phone: this.phoneNumber,
        Email: this.email,
        Subject: this.subject,
        Comment: this.note,
        Images: "",
      };
      let res = await guestContacts.AddNoLogin(body);
      if (res.status) {
        this.$bvModal.show("succes-modal");
      }
    },
    onReset() {},
  },
};
</script>

<style scoped>
.form-card {
  background-color: white;
  padding: 24px;
}
.submit-btn {
  border-radius: 12px;
  border: 1.5px solid var(--hover, #063);
  color: #063;
  background-color: white;
  font-size: 14px;
  font-weight: 600;
  padding: 8px 16px;
}
.submit-btn:hover {
  background-color: #063;
  color: white;
}
</style>
