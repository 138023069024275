import Repository from "../../repository";

export default {
    Get: (payload) => Repository.post(`/api/nPLVehicles/Get`, payload),
    GetTruck: (payload) => Repository.post(`/api/nPLVehicles/GetTruck`, payload),
    GetTractor: (payload) => Repository.post(`/api/nPLVehicles/GetTractor`, payload),
    GetByTeamVehicleID: (payload) => Repository.post(`/api/nPLVehicles/GetByTeamVehicleID`, payload),
    GetByNotTeamVehicleID: (payload) => Repository.post(`/api/nPLVehicles/GetByNotTeamVehicleID`, payload),
    GetByStationVehicleID: (payload) => Repository.post(`/api/nPLVehicles/GetByStationVehicleID`, payload),
    GetByNotStationVehicleID: (payload) => Repository.post(`/api/nPLVehicles/GetByNotStationVehicleID`, payload),
    GetActive: (payload) => Repository.post(`/api/nPLVehicles/GetActive`, payload),
    GetById: (payload) => Repository.post(`/api/nPLVehicles/GetById`, payload),
    Add: (payload) => Repository.post(`/api/nPLVehicles/Add`, payload),
    Edit: (payload) => Repository.post(`/api/nPLVehicles/Edit`, payload),
    Delete: (payload) => Repository.post(`/api/nPLVehicles/Delete`, payload),
    ChangeStatus: (payload) => Repository.post(`/api/nPLVehicles/ChangeStatus`, payload),
}
