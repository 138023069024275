import Repository from "../../repository";

export default {
    Get: (payload) => Repository.post(`/api/NPLDriveHelpers/Get`, payload),
    GetActive: (payload) => Repository.post(`/api/NPLDriveHelpers/GetActive`, payload),
    GetById: (payload) => Repository.post(`/api/NPLDriveHelpers/GetById`, payload),
    Add: (payload) => Repository.post(`/api/NPLDriveHelpers/Add`, payload),
    Edit: (payload) => Repository.post(`/api/NPLDriveHelpers/Edit`, payload),
    Delete: (payload) => Repository.post(`/api/NPLDriveHelpers/Delete`, payload),
    ChangeStatus: (payload) => Repository.post(`/api/NPLDriveHelpers/ChangeStatus`, payload),
}