import Repository from "../../repository";

export default {
    GetListLand: (payload) => Repository.post(`/api/Lands/GetListLand`, payload),
    GetListLandActive: (payload) => Repository.post(`/api/Lands/GetListLandActive`, payload),
    GetLandById: (payload) => Repository.post(`/api/Lands/GetLandById`, payload),
    AddLand: (payload) => Repository.post(`/api/Lands/AddLand`, payload),
    EditLand: (payload) => Repository.post(`/api/Lands/EditLand`, payload),
    DeleteLand: (payload) => Repository.post(`/api/Lands/DeleteLand`, payload),
    ChangeStatusLand: (payload) => Repository.post(`/api/Lands/ChangeStatusLand`, payload),
}