<template>
  <div class="space-between">
    <div class="custom-container">
      <div class="row justify-content-center">
        <div class="col-lg-6 text-center" style="margin-bottom: 80px">
          <h5 class="ma-28-semi text-green">{{ $t("home-title-4") }}</h5>
          <p class="in-16-re">
            {{ $t("home-decrtiption-4") }}
          </p>
        </div>
      </div>
      <div class="row justify-content-center">
        <div class="col-lg-4">
          <div
            v-for="(e, index) in slides"
            :key="index"
            :class="`${index === slide ? '' : 'd-none'}`"
            style="position: relative"
            class="h-100"
          >
            <img
              :src="e.img1"
              :class="`${index === slide ? 'img-opacity' : ''}`"
              height="100%"
              width="100%"
            />
            <div
              class="curtain"
              :class="`${
                index === slide
                  ? isRight
                    ? 'fade-in-right'
                    : 'fade-in-left'
                  : 'd-none'
              }`"
            ></div>
          </div>
        </div>
        <div
          class="col-lg-6 d-flex flex-column justify-content-end"
          style="position: relative"
        >
          <div class="d-flex next-btn">
            <div class="mr-2 btn-slide" @click="changeSlide(-1)">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M20 12L4 12M4 12L10 18M4 12L10 6"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
            <div class="btn-slide" @click="changeSlide(1)">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M4 12H20M20 12L14 6M20 12L14 18"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
          </div>
          <div style="height: 50px"></div>
          <div
            v-for="(e, index) in slides"
            :key="index"
            :class="`${index === slide ? '' : 'd-none'}`"
            style="position: relative"
          >
            <h5 class="ma-24-semi text-green">
              {{ e.title }}
            </h5>
            <p class="in-16-re mb-30">
              {{ e.content }}
            </p>
            <div style="max-height: 380px; overflow: hidden">
              <img
                :src="e.img2"
                :class="`${index === slide ? 'img-opacity' : ''}`"
                width="100%"
                class="second-image"
              />
            </div>
            <div
                class="curtain"
                :class="`${
                  index === slide
                    ? isRight
                      ? 'fade-in-right'
                      : 'fade-in-left'
                    : 'd-none'
                }`"
              ></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
	data() {
		return {
			slides: [
				{
					title: this.$t("home-feature.feature-1"),
					content: this.$t("home-feature.feature-decription-1"),
					img1: "./assets/image/bg/bg_17.png",
					img2: "./assets/image/bg/bg_18.png",
				},
				{
					title: this.$t("home-feature.feature-2"),
					content: this.$t("home-feature.feature-decription-2"),
					img1: "./assets/image/bg/img_4.png",
					img2: "./assets/image/bg/img_5.png",
				},
				{
					title: this.$t("home-feature.feature-3"),
					content: this.$t("home-feature.feature-decription-3"),
					img1: "./assets/image/bg/img_6.png",
					img2: "./assets/image/bg/img_7.png",
				},
			],
			slide: 0,
			isRight: null,
			isAuto: true,
		};
	},
	created() {
		this.autoSlide(8000);
	},
	methods: {
		changeSlide(value) {
			this.isAuto = false
			if (value > 0 && this.slide < this.slides.length - 1) {
				this.slide = this.slide + value;
				this.isRight = true;
			} else if (value < 0 && this.slide !== 0) {
				this.slide = this.slide + value;
				this.isRight = false;
			} else if (value > 0 && this.slide >= this.slides.length - 1) {
				this.slide = 0;
				this.isRight = true;
			} else if (value < 0 && this.slide === 0) {
				this.slide = this.slides.length - 1;
				this.isRight = false;
			}
			setTimeout(()=>{
				this.isAuto = true
				this.autoSlide(8000);
			},8000)
		},
		autoSlide(delay) {
			// this.changeSlide(1);
			if (!this.isAuto) return;
			let value = 1
			if (value > 0 && this.slide < this.slides.length - 1) {
				this.slide = this.slide + value;
				this.isRight = true;
			} else if (value > 0 && this.slide >= this.slides.length - 1) {
				this.slide = 0;
				this.isRight = true;
			}
			setTimeout(() => {
				this.autoSlide(delay);
			}, delay);
		},
	},
};
</script>

<style scoped>
.height-content {
	height: 512px;
}
.test {
	visibility: inherit;
	transition: 1s;
}
.hide-test {
	visibility: hidden;
	animation: hide 900ms linear forwards;
}
.btn-slide {
	height: 40px;
	width: 40px;
	border-radius: 50%;
	border: 1px solid var(--hover, #063);
	cursor: pointer;
	display: flex;
	justify-content: center;
	align-items: center;
	transition: 500ms;
	stroke: #063;
}
.btn-slide:hover {
	background: var(--hover, #063);
	stroke: #ffff;
}
@media screen and (max-width: 1024px) {
	.second-image {
		display: none;
	}
	.height-content {
		height: 270px;
		/* transform: translateY(200%); */
	}
	.next-btn {
		display: none !important;
	}
}
.curtain {
	position: absolute;
	top: 0;
	height: 100%;
	width: 100%;
	background-color: #fafafa;
	/* box-shadow: 0px 0px 8px 2px #fbfbfb; */
}
.is-show {
	animation: fadeRight 900ms linear forwards;
}
.next-btn {
	position: absolute;
	top: 0;
	right: 0;
}
.img-opacity {
	animation: showOpacity 1.5s linear forwards;
}
.fade-in-right {
	animation: fadeRight 900ms linear forwards;
}
.fade-in-left {
	animation: fadeLeft 900ms linear forwards;
}
@keyframes hide {
	0% {
		opacity: 1;
	}
	100% {
		opacity: 0;
	}
}
@keyframes showOpacity {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}
@keyframes fadeRight {
  0% {
    /* transform: translateX(0); */
    width: 100%;
  }
  100% {
    /* transform: translateX(150%); */
    width: 0;
    visibility: hidden;
  }
}
@keyframes fadeLeft {
	0% {
		/* transform: translateX(0); */
		width: 100%;
	}
	100% {
		/* transform: translateX(-150%); */
		width: 0;
		visibility: hidden;
	}
}
.reveal {
	position: relative;
	transform: translateY(150px);
	opacity: 0;
	transition: 1s all ease;
}
.reveal.active {
	transform: translateY(0);
	opacity: 1;
}
.mb-30 {
	margin-bottom: 30px;
}
</style>
