import Repository from "../../repository";

export default {
    Get: (payload) => Repository.post(`/api/LightingMaintenances/Get`, payload),
    Dashboard: (payload) => Repository.post(`/api/LightingMaintenances/Dashboard`, payload),
    GetEditDetails: (payload) => Repository.post(`/api/LightingMaintenances/GetEditDetails`, payload),
    GetReference: (payload) => Repository.post(`/api/LightingMaintenances/GetReference`, payload),
    GetById: (payload) => Repository.post(`/api/LightingMaintenances/GetById`, payload),
    GetDetails: (payload) => Repository.post(`/api/LightingMaintenances/GetDetails`, payload),
    Add: (payload) => Repository.post(`/api/LightingMaintenances/Add`, payload),
    Edit: (payload) => Repository.post(`/api/LightingMaintenances/Edit`, payload),
    Delete: (payload) => Repository.post(`/api/LightingMaintenances/Delete`, payload),
    EditClose: (payload) => Repository.post(`/api/LightingMaintenances/EditClose`, payload),
    Approval: (payload) => Repository.post(`/api/LightingMaintenances/Approval`, payload),
    Charts: (payload) => Repository.post(`/api/LightingMaintenances/Charts`, payload),
    ReportTotals: (payload) => Repository.post(`/api/LightingMaintenances/ReportTotals`, payload),
    ReportDetails: (payload) => Repository.post(`/api/LightingMaintenances/ReportDetails`, payload),
}
