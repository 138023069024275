import Repository from "../../repository";

export default {
    Get: (payload) => Repository.post(`/api/OilAllowances/Get`, payload),
    GetById: (payload) => Repository.post(`/api/OilAllowances/GetById`, payload),
    Add: (payload) => Repository.post(`/api/OilAllowances/Add`, payload),
    Copy: (payload) => Repository.post(`/api/OilAllowances/Copy`, payload),
    Edit: (payload) => Repository.post(`/api/OilAllowances/Edit`, payload),
    Delete: (payload) => Repository.post(`/api/OilAllowances/Delete`, payload),
    ChangeStatus: (payload) => Repository.post(`/api/OilAllowances/ChangeStatus`, payload),
    GoodTypes: (payload) => Repository.post(`/api/OilAllowances/GoodTypes`, payload),
    Routes: (payload) => Repository.post(`/api/OilAllowances/Routes`, payload),
    Vehicles: (payload) => Repository.post(`/api/OilAllowances/Vehicles`, payload),
}