import Vue from "vue";
import Vuex from "vuex";

import app from "../main";
import menu from "./modules/menu";
// import user from "./modules/user";
import chat from "./modules/chat";
import todo from "./modules/todo";
import survey from "./modules/survey";

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        windowHeight: 0,
        windowWidth: 0,
        stateNotification: false
    },
    getters: {
        getWindowHeight: state => state.windowHeight,
        getWindowWidth: state => state.windowWidth,
        notification: state => state.stateNotification,
    },
    mutations: {
        changeLang(state, payload) {
            app.$i18n.locale = payload;
            localStorage.setItem("currentLanguage", payload);
        },
        changeHeightWindow(state, height) {
            state.windowHeight = height;
        },
        changeWidthWindow(state, width) {
            state.windowWidth = width;
        },
        changeStateNotification(state, payload) {
            state.stateNotification = payload
        }
    },
    actions: {
        setLang({ commit }, payload) {
            commit("changeLang", payload);
        },
        setHeightWindow({ commit }, payload) {
            commit("changeHeightWindow", payload);
        },
        setWidthWindow({ commit }, payload) {
            commit("changeWidthWindow", payload);
        },
        setNotification({ commit }, payload) {
            commit('changeStateNotification', payload)
        }
    },
    modules: {
        menu,
        // user,
        chat,
        todo,
        survey,
    },
});
