import Repository from "../../repository";

export default {
    Get: (payload) => Repository.post(`/api/nPLCustomers/Get`, payload),
    Search: (payload) => Repository.post(`/api/nPLCustomers/Search`, payload),
    GetActive: (payload) => Repository.post(`/api/nPLCustomers/GetActive`, payload),
    GetListCustomerByUserID: (payload) => Repository.post(`/api/nPLCustomers/GetListCustomerByUserID`, payload),
    GetListContactByCusID: (payload) => Repository.post(`/api/nPLCustomers/GetListContactByCusID`, payload),
    GetById: (payload) => Repository.post(`/api/nPLCustomers/GetById`, payload),
    ResetPassword: (payload) => Repository.post(`/api/nPLCustomers/ResetPassword`, payload),
    Add: (payload) => Repository.post(`/api/nPLCustomers/Add`, payload),
    Edit: (payload) => Repository.post(`/api/nPLCustomers/Edit`, payload),
    Delete: (payload) => Repository.post(`/api/nPLCustomers/Delete`, payload),
    ChangeStatus: (payload) => Repository.post(`/api/nPLCustomers/ChangeStatus`, payload),
}
