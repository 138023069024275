import Repository from "../../repository";

export default {
    GetListTreeStatus: (payload) => Repository.post(`/api/TreeStatus/GetListTreeStatus`, payload),
    GetListTreeStatusActive: (payload) => Repository.post(`/api/TreeStatus/GetListTreeStatusActive`, payload),
    GetTreeStatusById: (payload) => Repository.post(`/api/TreeStatus/GetTreeStatusById`, payload),
    Add: (payload) => Repository.post(`/api/TreeStatus/Add`, payload),
    Edit: (payload) => Repository.post(`/api/TreeStatus/Edit`, payload),
    Delete: (payload) => Repository.post(`/api/TreeStatus/Delete`, payload),
    ChangeStatus: (payload) => Repository.post(`/api/TreeStatus/ChangeStatus`, payload),
}
