import Repository from "../../repository";

export default {
    Get: (payload) => Repository.post(`/api/InternalNotifications/Get`, payload),
    GetByID: (payload) => Repository.post(`/api/InternalNotifications/GetById`, payload),
    Add: (payload) => Repository.post(`/api/InternalNotifications/Add`, payload),
    Edit: (payload) => Repository.post(`/api/InternalNotifications/Edit`, payload),
    Delete: (payload) => Repository.post(`/api/InternalNotifications/Delete`, payload),
    ChangeStatus: (payload) => Repository.post(`/api/InternalNotifications/ChangeStatus`, payload),
    ResetSend: (payload) => Repository.post(`/api/InternalNotifications/ResetSend`, payload),
}
