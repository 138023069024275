import Repository from "../../repository";

export default {
    Get: (payload) => Repository.post(`/api/AdjustPrices/Get`, payload),
    GetById: (payload) => Repository.post(`/api/AdjustPrices/GetById`, payload),
    Add: (payload) => Repository.post(`/api/AdjustPrices/Add`, payload),
    Edit: (payload) => Repository.post(`/api/AdjustPrices/Edit`, payload),
    Delete: (payload) => Repository.post(`/api/AdjustPrices/Delete`, payload),
    GetFoodCourts: (payload) => Repository.post(`/api/AdjustPricesFoodCourts/Get`, payload),
    GetByIdFoodCourts: (payload) => Repository.post(`/api/AdjustPricesFoodCourts/GetById`, payload),
    AddFoodCourts: (payload) => Repository.post(`/api/AdjustPricesFoodCourts/Add`, payload),
    EditFoodCourts: (payload) => Repository.post(`/api/AdjustPricesFoodCourts/Edit`, payload),
    DeleteFoodCourts: (payload) => Repository.post(`/api/AdjustPricesFoodCourts/Delete`, payload),
    GetLastAdjustPrice_ByAllCus: (payload) => Repository.post(`/api/AdjustPriceTotals/GetLastAdjustPrice_ByAllCus`, payload),
    GetTotals: (payload) => Repository.post(`/api/AdjustPriceTotals/Get`, payload),
    GetByIdTotals: (payload) => Repository.post(`/api/AdjustPriceTotals/GetById`, payload),
    AddTotals: (payload) => Repository.post(`/api/AdjustPriceTotals/Add`, payload),
    EditTotals: (payload) => Repository.post(`/api/AdjustPriceTotals/Edit`, payload),
    EditSendNotify: (payload) => Repository.post(`/api/AdjustPriceTotals/EditSendNotify`, payload),
    DeleteTotals: (payload) => Repository.post(`/api/AdjustPriceTotals/Delete`, payload),
}
