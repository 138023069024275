<template>
  <div>
    <div class="page-header clear-filter" style="min-height: 640px">
      <parallax class="page-header-image" style="background-image: url('/assets/image/bg/bg_1.png')">
      </parallax>
      <div class="container px-0 d-flex justify-content-center align-items-center h-100 mt-4">
        <div class="title-content">
          <h3 class="ma-56-semi m-0">{{ $t('gallery') }}</h3>
          <p class="in-20-re">
            {{ $t('gallery-description') }}
          </p>
        </div>
      </div>
    </div>
    <div class="custom-container" style="margin-top: 120px; margin-bottom: 120px">
      <div class="d-flex justify-content-center mb-4">
        <div @click="selectedTab = 'all'" :class="{ 'active-tab': selectedTab === 'all' }" class="tab-item">Tất cả</div>
        <div @click="selectedTab = 'images'" :class="{ 'active-tab': selectedTab === 'images' }" class="tab-item mx-3">
          Hình ảnh</div>
        <div @click="selectedTab = 'video'" :class="{ 'active-tab': selectedTab === 'video' }" class="tab-item">Video
        </div>
      </div>

      <!-- Tất cả -->
      <div v-if="selectedTab === 'all'">
        <div class="row">
          <div v-for="(column, columnIndex) in combinedColumns" :key="columnIndex" class="col-lg-4 mb-4">
            <div v-for="(item, itemIndex) in column" :key="itemIndex" class="media-frame mb-4">
              <img v-if="item.type === 'image'" width="100%" :src="item.link" @click="showWallpaper(column, itemIndex)"
                alt="Gallery image" loading="lazy">
              <div v-else class="video-wrapper">
                <video width="100%" :src="item.link" muted preload="metadata" loading="lazy"></video>
                <div class="video-overlay" @click="enlargeVideo(item)">
                  <span class="play-icon"><i class="fa-solid fa-play"></i></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Ảnh -->
      <div v-if="selectedTab === 'images'">
        <div class="row">
          <div v-for="(e, index) in columnImage" :key="index" class="col-lg-4 mb-4">
            <div v-for="(item, i) in e" :key="i" class="image-frame">
              <img width="100%" :src="item.link" @click="showWallpaper(e, i)" alt="Gallery image" loading="lazy">
            </div>
          </div>
        </div>
      </div>

      <!-- Video -->
      <div v-if="selectedTab === 'video'">
        <div class="row">
          <div v-for="(column, columnIndex) in columnVideo" :key="columnIndex" class="col-lg-4 mb-4">
            <div v-for="(item, itemIndex) in column" :key="itemIndex" class="video-frame">
              <div class="video-wrapper">
                <video width="100%" :src="item.link" muted preload="metadata" loading="lazy"></video>
                <div class="video-overlay" @click="enlargeVideo(item)">
                  <span class="play-icon"><i class="fa-solid fa-play "></i></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>

    <!-- Fullscreen video modal -->
    <div v-if="enlargedVideo" class="fullscreen-video-modal" @click="closeEnlargedVideo">
      <div class="modal-content" @click.stop>
        <video class="responsive-video" ref="fullscreenVideo" :src="enlargedVideo.link" controls autoplay></video>
        <button class="close-button" @click="closeEnlargedVideo">&times;</button>
      </div>
    </div>
    <div id="fullscreen-wallpaper-light">
      <div class="button-close-wallpaper m-3">
        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none" class="btn-close"
          @click="hideWallpaper">
          <path d="M0 40L40 0" stroke="white" stroke-linecap="round" />
          <path d="M0 0L40 40" stroke="white" stroke-linecap="round" />
        </svg>
      </div>
      <div class="image-wallpaper" @keydown="closeSlide($event)">
        <BCarousel :list-image="listImage" :slideindex="slide" />
      </div>
    </div>
  </div>
</template>

<script>
import BCarousel from "@/components/BCarouselComponent";
export default {
  components: {
    BCarousel,
  },
  data() {
    return {
      Images: [
        { link: "https://viewfile.nlt-group.com:9004/webcayxanh/12.JPG", type: "image" },
        { link: "https://viewfile.nlt-group.com:9004/webcayxanh/13.JPG", type: "image" },
        { link: "https://viewfile.nlt-group.com:9004/webcayxanh/14.JPG", type: "image" },
        { link: "https://viewfile.nlt-group.com:9004/webcayxanh/15.JPG", type: "image" },
        { link: "https://viewfile.nlt-group.com:9004/webcayxanh/17.JPG", type: "image" },
        { link: "https://viewfile.nlt-group.com:9004/webcayxanh/18.JPG", type: "image" },
        { link: "https://viewfile.nlt-group.com:9004/webcayxanh/19.JPG", type: "image" },
        { link: "https://viewfile.nlt-group.com:9004/webcayxanh/20.JPG", type: "image" },
        { link: "https://viewfile.nlt-group.com:9004/webcayxanh/21.JPG", type: "image" },
        { link: "https://viewfile.nlt-group.com:9004/webcayxanh/22.JPG", type: "image" },
        { link: "https://viewfile.nlt-group.com:9004/webcayxanh/24.JPG", type: "image" },
        { link: "https://viewfile.nlt-group.com:9004/webcayxanh/17.JPG", type: "image" },
        { link: "https://viewfile.nlt-group.com:9004/webcayxanh/25.JPG", type: "image" },
        { link: "https://viewfile.nlt-group.com:9004/webcayxanh/3.JPG", type: "image" },
      ],
      Videos: [
        { link: "https://viewfile.nlt-group.com:9004/webcayxanh/1.mp4", type: "video" },
        { link: "https://viewfile.nlt-group.com:9004/webcayxanh/2.mp4", type: "video" },
        { link: "https://viewfile.nlt-group.com:9004/webcayxanh/23.MP4", type: "video" },
        { link: "https://viewfile.nlt-group.com:9004/webcayxanh/4.MP4", type: "video" },
        { link: "https://viewfile.nlt-group.com:9004/webcayxanh/5.MP4", type: "video" },
        { link: "https://viewfile.nlt-group.com:9004/webcayxanh/6.MP4", type: "video" },
        { link: "https://viewfile.nlt-group.com:9004/webcayxanh/7.MP4", type: "video" },
        { link: "https://viewfile.nlt-group.com:9004/webcayxanh/8.MP4", type: "video" },
        { link: "https://viewfile.nlt-group.com:9004/webcayxanh/9.MP4", type: "video" },
        { link: "https://viewfile.nlt-group.com:9004/webcayxanh/10.MP4", type: "video" },
        { link: "https://viewfile.nlt-group.com:9004/webcayxanh/11.MP4", type: "video" },
        { link: "https://viewfile.nlt-group.com:9004/webcayxanh/16.MP4", type: "video" },
      ],
      listImage: [],
      slide: 0,
      selectedTab: "all",
      enlargedVideo: null,
    };
  },
  computed: {
    combinedMedia() {
      const combined = [...this.Images, ...this.Videos];
      return this.shuffleArray(combined);
    },
    combinedColumns() {
      const numberOfColumns = 3;
      const columns = Array.from({ length: numberOfColumns }, () => []);

      this.combinedMedia.forEach((item, index) => {
        columns[index % numberOfColumns].push(item);
      });

      return columns;
    },
    columnImage() {
      const originalArray = this.Images;
      const numberOfArrays = 3;
      const chunkSize = Math.ceil(originalArray.length / numberOfArrays);
      const resultArray = [];
      for (let i = 0; i < originalArray.length; i += chunkSize) {
        const chunk = originalArray.slice(i, i + chunkSize);
        resultArray.push(chunk);
      }
      return resultArray;
    },
    columnVideo() {
      const originalArray = this.Videos;
      const numberOfArrays = 3;
      const chunkSize = Math.ceil(originalArray.length / numberOfArrays);
      const resultArray = [];
      for (let i = 0; i < originalArray.length; i += chunkSize) {
        const chunk = originalArray.slice(i, i + chunkSize);
        resultArray.push(chunk);
      }
      return resultArray;
    }
  },
  methods: {
    shuffleArray(array) {
      for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
      }
      return array;
    },
    hideWallpaper() {
      this.listImage = null;
      let targetDiv = document.getElementById("fullscreen-wallpaper-light");
      targetDiv.style.display = "none";
    },
    closeSlide(event) {
      let x = event.keyCode;
      if (x == 27) {
        alert("You pressed the Escape key!");
      }
    },
    showWallpaper(list, key) {
      let result = [];
      this.listImage = null;
      list.forEach((item, index) => {
        if (item.type === 'image') {
          let obj = {
            ...item,
            ID: index,
            Image: item.link,
          };
          result.push(obj);
        }
      });

      if (result.length > 0) {
        this.slide = result.findIndex(item => item.ID === key);
        if (this.slide === -1) this.slide = 0;
        this.listImage = result;
        let targetDiv = document.getElementById("fullscreen-wallpaper-light");
        if (targetDiv.style.display === "none" || targetDiv.style.display === "") {
          targetDiv.style.display = "block";
        }
      }
    },
    enlargeVideo(video) {
      this.enlargedVideo = video;
      this.$nextTick(() => {
        if (this.$refs.fullscreenVideo) {
          this.$refs.fullscreenVideo.play();
        }
      });
    },
    closeEnlargedVideo() {
      this.enlargedVideo = null;
    },
    playVideo(event) {
      event.target.play();
    },
    pauseVideo(event) {
      event.target.pause();
      event.target.currentTime = 0;
    },
  },
};
</script>

<style scoped>
.image-frame {
  margin-bottom: 30px;
  width: 100%;
  overflow: hidden !important;
  background-repeat: no-repeat;
  background-size: cover;
  cursor: pointer;
}

.title-content {
  margin-top: 120px;
}

.btn-close:hover path {
  stroke: #029834;
}

.tab-item {
  cursor: pointer;
  padding: 10px;
  color: #029834;
  font-weight: 500;
}

.active-tab {
  color: green;
  border-bottom: 3px solid #029834;
}

.video-frame {
  overflow: hidden;
  margin-bottom: 15px;
}

video {
  object-fit: cover;
  transition: transform 0.3s ease;
}

.fullscreen-video-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  position: relative;
  width: 80%;
  height: auto;
  /* max-width: 500px; */
}

.modal-content video {
  width: 50%;
  height: auto;
}

#fullscreen-wallpaper-light {
  display: none;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100vh;
  z-index: 9999;
  background-color: rgba(0, 0, 0, 0.95);
  cursor: pointer;
}

#fullscreen-wallpaper-light .image-wallpaper {
  width: 100%;
  height: 100%;
}

#fullscreen-wallpaper-light .button-close-wallpaper {
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 9999;
}

#fullscreen-wallpaper-light .button-close-wallpaper .btn {
  border-radius: 50%;
  width: 40px;
  height: 40px;
  padding: 0.6rem;
}

#fullscreen-wallpaper-light .button-close-wallpaper .btn i {
  font-size: 20px;
}

.fullscreen-video-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1111;
}

video {
  will-change: transform;
}

.modal-content video {
  width: 100%;
  height: 90vh;
}

.close-button {
  position: absolute;
  top: -40px;
  right: -60px;
  background: none;
  border: none;
  color: white;
  font-size: 60px;
  cursor: pointer;
}

.responsive-video {
  width: 100%;
  height: auto;
}

.video-wrapper {
  position: relative;
}

/* play */
.video-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 96%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.2);
  opacity: 0;
  transition: opacity 0.3s ease;
  object-fit: cover;
  cursor: pointer;
}

.video-wrapper:hover .video-overlay {
  opacity: 1;
}

.play-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #029834;
  width: 50px;
  height: 50px;
  border-radius: 50px;
  font-size: 48px;
  color: white;
}

.play-icon i {
  font-size: 24px;
  width: 24px;
  height: 24px;
  padding-left: 4px;
  align-items: center;
}

.media-frame {
  cursor: pointer;
}
</style>
