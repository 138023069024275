import Repository from "../../repository";

export default {
    getLightingCharts: (payload) => Repository.post(`/api/LightingCharts/Get`, payload),
    dayLightingCharts: (payload) => Repository.post(`/api/LightingCharts/Day`, payload),
    weekLightingCharts: (payload) => Repository.post(`/api/LightingCharts/Week`, payload),
    monthLightingCharts: (payload) => Repository.post(`/api/LightingCharts/Month`, payload),
    yearLightingCharts: (payload) => Repository.post(`/api/LightingCharts/Year`, payload),
    totalLightingCharts: (payload) => Repository.post(`/api/LightingCharts/Total`, payload),
    regionLightingCharts: (payload) => Repository.post(`/api/LightingCharts/Region`, payload),
    getChartTrees: (payload) => Repository.post(`/api/ChartTrees/GetChartData`, payload),
    getChartGasFamily: (payload) => Repository.post(`/api/ChartGasFamily/Get`, payload),
    getChartGasFoodCourt: (payload) => Repository.post(`/api/ChartGasFoodCourt/Get`, payload),
    getByStationChartGasFoodCourt: (payload) => Repository.post(`/api/ChartGasFoodCourt/GetByStation`, payload),
    getByFoodCourtsChartGasFoodCourt: (payload) => Repository.post(`/api/ChartGasFoodCourt/GetByFoodCourts`, payload),
    getChartGasRestaurent: (payload) => Repository.post(`/api/ChartGasRestaurent/Get`, payload),
    weekChartFires: (payload) => Repository.post(`/api/ChartFires/GetChartWeek`, payload),
    monthChartFires: (payload) => Repository.post(`/api/ChartFires/GetChartMonth`, payload),
    yearChartFires: (payload) => Repository.post(`/api/ChartFires/GetChartYear`, payload),
    getChartMonitors: (payload) => Repository.post(`/api/ChartMonitors/GetChart_Monitors`, payload),
    getForSaleGasCharts: (payload) => Repository.post(`/api/GasCharts/Getchart_ForSale`, payload),
    getChartTreeMaintenance: (payload) => Repository.post(`/api/ChartTreeMaintenance/GetChartMaintenance`, payload),
    getChartCameraThermals: (payload) => Repository.post(`/api/ChartCameraThermals/Get`, payload),
    getChartKiosks: (payload) => Repository.post(`/api/ChartKiosks/Get`, payload),
    getWaterwayCharts: (payload) => Repository.post(`/api/WaterwayCharts/Waterway`, payload),
    clearanceHeightWaterwayCharts: (payload) => Repository.post(`/api/WaterwayCharts/ClearanceHeight`, payload),
}
