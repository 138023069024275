import Repository from "../../repository";

export default {
    Get: (payload) => Repository.post(`/api/DriverShippings/Get`, payload),
    DriverApproval: (payload) => Repository.post(`/api/DriverShippings/DriverApproval`, payload),
    DriverShipping: (payload) => Repository.post(`/api/DriverShippings/DriverShipping`, payload),
    GetDriverCancelReasonsApproval: (payload) => Repository.post(`/api/DriverShippings/GetDriverCancelReasonsApproval`, payload),
    GetListFee: (payload) => Repository.post(`/api/DriverShippings/GetListFee`, payload),
    AddFee: (payload) => Repository.post(`/api/DriverShippings/AddFee`, payload),
    DriverCancelFee: (payload) => Repository.post(`/api/DriverShippings/DriverCancelFee`, payload),
    DriverUpdateStatus: (payload) => Repository.post(`/api/DriverShippings/DriverUpdateStatus`, payload),
    GetDetailByOIDAndReferenceID: (payload) => Repository.post(`/api/DriverShippings/GetDetailByOIDAndReferenceID`, payload),
    GetChargeStationsByOID: (payload) => Repository.post(`/api/DriverShippings/GetChargeStationsByOID`, payload),
}
