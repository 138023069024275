import Repository from "../../repository";

export default {
    GetCabinetScheduleList: (payload) => Repository.post(`/api/CabinetSchedules/GetCabinetScheduleList`, payload),
    GetCabinetScheduleListActive: (payload) => Repository.post(`/api/CabinetSchedules/GetCabinetScheduleListActive`, payload),
    GetSCabinetScheduleById: (payload) => Repository.post(`/api/CabinetSchedules/GetSCabinetScheduleById`, payload),
    Add: (payload) => Repository.post(`/api/CabinetSchedules/Add`, payload),
    Edit: (payload) => Repository.post(`/api/CabinetSchedules/Edit`, payload),
    ChangeStatus: (payload) => Repository.post(`/api/CabinetSchedules/ChangeStatus`, payload),
    Delete: (payload) => Repository.post(`/api/CabinetSchedules/Delete`, payload),
    GetSelectTable: (payload) => Repository.post(`/api/CabinetSchedules/GetSelectTable`, payload),
}
