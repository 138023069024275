var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"space-between"},[_c('div',{staticClass:"custom-container"},[_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-lg-6 text-center",staticStyle:{"margin-bottom":"80px"}},[_c('h5',{staticClass:"ma-28-semi text-green"},[_vm._v(_vm._s(_vm.$t("home-title-4")))]),_c('p',{staticClass:"in-16-re"},[_vm._v(" "+_vm._s(_vm.$t("home-decrtiption-4"))+" ")])])]),_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-lg-4"},_vm._l((_vm.slides),function(e,index){return _c('div',{key:index,staticClass:"h-100",class:`${index === _vm.slide ? '' : 'd-none'}`,staticStyle:{"position":"relative"}},[_c('img',{class:`${index === _vm.slide ? 'img-opacity' : ''}`,attrs:{"src":e.img1,"height":"100%","width":"100%"}}),_c('div',{staticClass:"curtain",class:`${
              index === _vm.slide
                ? _vm.isRight
                  ? 'fade-in-right'
                  : 'fade-in-left'
                : 'd-none'
            }`})])}),0),_c('div',{staticClass:"col-lg-6 d-flex flex-column justify-content-end",staticStyle:{"position":"relative"}},[_c('div',{staticClass:"d-flex next-btn"},[_c('div',{staticClass:"mr-2 btn-slide",on:{"click":function($event){return _vm.changeSlide(-1)}}},[_c('svg',{attrs:{"xmlns":"http://www.w3.org/2000/svg","width":"24","height":"24","viewBox":"0 0 24 24","fill":"none"}},[_c('path',{attrs:{"d":"M20 12L4 12M4 12L10 18M4 12L10 6","stroke-width":"2","stroke-linecap":"round","stroke-linejoin":"round"}})])]),_c('div',{staticClass:"btn-slide",on:{"click":function($event){return _vm.changeSlide(1)}}},[_c('svg',{attrs:{"width":"24","height":"24","viewBox":"0 0 24 24","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"d":"M4 12H20M20 12L14 6M20 12L14 18","stroke-width":"2","stroke-linecap":"round","stroke-linejoin":"round"}})])])]),_c('div',{staticStyle:{"height":"50px"}}),_vm._l((_vm.slides),function(e,index){return _c('div',{key:index,class:`${index === _vm.slide ? '' : 'd-none'}`,staticStyle:{"position":"relative"}},[_c('h5',{staticClass:"ma-24-semi text-green"},[_vm._v(" "+_vm._s(e.title)+" ")]),_c('p',{staticClass:"in-16-re mb-30"},[_vm._v(" "+_vm._s(e.content)+" ")]),_c('div',{staticStyle:{"max-height":"380px","overflow":"hidden"}},[_c('img',{staticClass:"second-image",class:`${index === _vm.slide ? 'img-opacity' : ''}`,attrs:{"src":e.img2,"width":"100%"}})]),_c('div',{staticClass:"curtain",class:`${
                index === _vm.slide
                  ? _vm.isRight
                    ? 'fade-in-right'
                    : 'fade-in-left'
                  : 'd-none'
              }`})])})],2)])])])
}
var staticRenderFns = []

export { render, staticRenderFns }