import Repository from "../../repository";

export default {
    Get: (payload) => Repository.post(`/api/LightingMaintenanceErrors/Get`, payload),
    GetActive: (payload) => Repository.post(`/api/LightingMaintenanceErrors/GetActive`, payload),
    GetById: (payload) => Repository.post(`/api/LightingMaintenanceErrors/GetById`, payload),
    Add: (payload) => Repository.post(`/api/LightingMaintenanceErrors/Add`, payload),
    Edit: (payload) => Repository.post(`/api/LightingMaintenanceErrors/Edit`, payload),
    Delete: (payload) => Repository.post(`/api/LightingMaintenanceErrors/Delete`, payload),
    ChangeStatus: (payload) => Repository.post(`/api/LightingMaintenanceErrors/ChangeStatus`, payload),
}
