import Repository from "../../repository";

export default {
    ReportDetected: (payload) => Repository.post(`/api/StationDataGasRestaurent/ReportDetected`, payload),
    ReportEndGas: (payload) => Repository.post(`/api/StationDataGasRestaurent/ReportEndGas`, payload),
    LogDetected: (payload) => Repository.post(`/api/StationDataGasRestaurent/LogDetected`, payload),
    LogEndGas: (payload) => Repository.post(`/api/StationDataGasRestaurent/LogEndGas`, payload),
    HistoryGasRestaurent: (payload) => Repository.post(`/api/StationDataGasRestaurent/HistoryGasRestaurent`, payload),
    GasOrderHistory: (payload) => Repository.post(`/api/StationDataGasRestaurent/GasOrderHistory`, payload),
}
