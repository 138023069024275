import Repository from "../../repository";

export default {
    Get: (payload) => Repository.post(`/api/WaterwayBeaconFlashs/Get`, payload),
    GetColor: (payload) => Repository.post(`/api/WaterwayBeaconFlashs/GetColor`, payload),
    GetActive: (payload) => Repository.post(`/api/WaterwayBeaconFlashs/GetActive`, payload),
    GetById: (payload) => Repository.post(`/api/WaterwayBeaconFlashs/GetById`, payload),
    Add: (payload) => Repository.post(`/api/WaterwayBeaconFlashs/Add`, payload),
    Edit: (payload) => Repository.post(`/api/WaterwayBeaconFlashs/Edit`, payload),
    Delete: (payload) => Repository.post(`/api/WaterwayBeaconFlashs/Delete`, payload),
    ChangeStatus: (payload) => Repository.post(`/api/WaterwayBeaconFlashs/ChangeStatus`, payload),
}
