import Repository from "../../repository";

export default {
    Get: (payload) => Repository.post(`/api/nPLExperts/Get`, payload),
    GetActive: (payload) => Repository.post(`/api/nPLExperts/GetActive`, payload),
    GetById: (payload) => Repository.post(`/api/nPLExperts/GetById`, payload),
    GetRescue: (payload) => Repository.post(`/api/nPLExperts/GetRescue`, payload),
    Add: (payload) => Repository.post(`/api/nPLExperts/Add`, payload),
    Edit: (payload) => Repository.post(`/api/nPLExperts/Edit`, payload),
    Delete: (payload) => Repository.post(`/api/nPLExperts/Delete`, payload),
    ChangeStatus: (payload) => Repository.post(`/api/nPLExperts/ChangeStatus`, payload),
}