import Repository from "../../repository";

export default {
    GetSLLampListByCabinetId: (payload) => Repository.post(`/api/SLLamps/GetSLLampListByCabinetId`, payload),
    GetSLLampExportLists: (payload) => Repository.post(`/api/SLLamps/GetSLLampExportList`, payload),
    GetDeploy: (payload) => Repository.post(`/api/SLLamps/GetDeploy`, payload),
    EditCabinet: (payload) => Repository.post(`/api/SLLamps/EditCabinet`, payload),
    DelCabinet: (payload) => Repository.post(`/api/SLLamps/DelCabinet`, payload),
    GetSLLampListActive: (payload) => Repository.post(`/api/SLLamps/GetSLLampListActive`, payload),
    GetSLLampById: (payload) => Repository.post(`/api/SLLamps/GetSLLampById`, payload),
    Export: (payload) => Repository.post(`/api/SLLamps/Export`, payload),
    EditDeploy: (payload) => Repository.post(`/api/SLLamps/EditDeploy`, payload),
    Delete: (payload) => Repository.post(`/api/SLLamps/Delete`, payload),
    ChangeStatus: (payload) => Repository.post(`/api/SLLamps/ChangeStatus`, payload),
}
