import Repository from "../../repository";

export default {
    GetListTreeRoute: (payload) => Repository.post(`/api/TreeRoutes/GetListTreeRoute`, payload),
    GetListTreeRouteActive: (payload) => Repository.post(`/api/TreeRoutes/GetListTreeRouteActive`, payload),
    GetTreeRouteByID: (payload) => Repository.post(`/api/TreeRoutes/GetTreeRouteByID`, payload),
    AddTreeRoute: (payload) => Repository.post(`/api/TreeRoutes/AddTreeRoute`, payload),
    EditTreeRoute: (payload) => Repository.post(`/api/TreeRoutes/EditTreeRoute`, payload),
    DeleteTreeRoute: (payload) => Repository.post(`/api/TreeRoutes/DeleteTreeRoute`, payload),
    ChangeStatusTreeRoute: (payload) => Repository.post(`/api/TreeRoutes/ChangeStatusTreeRoute`, payload),
}
