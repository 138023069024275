import Repository from "../../repository";

export default {
    Get: (payload) => Repository.post(`/api/BusTicketTypes/Get`, payload),
    GetActive: (payload) => Repository.post(`/api/BusTicketTypes/GetActive`, payload),
    GetById: (payload) => Repository.post(`/api/BusTicketTypes/GetById`, payload),
    Add: (payload) => Repository.post(`/api/BusTicketTypes/Add`, payload),
    Edit: (payload) => Repository.post(`/api/BusTicketTypes/Edit`, payload),
    Delete: (payload) => Repository.post(`/api/BusTicketTypes/Delete`, payload),
    ChangeStatus: (payload) => Repository.post(`/api/BusTicketTypes/ChangeStatus`, payload),
}