import Repository from "../../repository";

export default {
    Get: (payload) => Repository.post(`/api/GasTypes/Get`, payload),
    GetActive: (payload) => Repository.post(`/api/GasTypes/GetActive`, payload),
    GetById: (payload) => Repository.post(`/api/GasTypes/GetById`, payload),
    Add: (payload) => Repository.post(`/api/GasTypes/Add`, payload),
    Edit: (payload) => Repository.post(`/api/GasTypes/Edit`, payload),
    Delete: (payload) => Repository.post(`/api/GasTypes/Delete`, payload),
    ChangeStatus: (payload) => Repository.post(`/api/GasTypes/ChangeStatus`, payload),
}