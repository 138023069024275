import Repository from "../../repository";

export default {
    Get: (payload) => Repository.post(`/api/DebitPeriodOils/Get`, payload),
    GetById: (payload) => Repository.post(`/api/DebitPeriodOils/GetById`, payload),
    GetByVehicleGroupID: (payload) => Repository.post(`/api/DebitPeriodOils/GetByVehicelGroupID`, payload),
    GetDetailByDriver: (payload) => Repository.post(`/api/DebitPeriodOils/GetDetailByDriver`, payload),
    Add: (payload) => Repository.post(`/api/DebitPeriodOils/Add`, payload),
    Edit: (payload) => Repository.post(`/api/DebitPeriodOils/Edit`, payload),
    Delete: (payload) => Repository.post(`/api/DebitPeriodOils/Delete`, payload),
    ChangeStatus: (payload) => Repository.post(`/api/DebitPeriodOils/ChangeStatus`, payload),
    DriverUpdateDetails: (payload) => Repository.post(`/api/DebitPeriodOils/DriverUpdateDetails`, payload),
    ApprovalFinal: (payload) => Repository.post(`/api/DebitPeriodOils/ApprovalFinal`, payload),
}
