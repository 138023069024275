import Repository from "../../repository";

export default {
    Get: (payload) => Repository.post(`/api/RouteAllowances/Get`, payload),
    GetById: (payload) => Repository.post(`/api/RouteAllowances/GetById`, payload),
    Copy: (payload) => Repository.post(`/api/RouteAllowances/Copy`, payload),
    Add: (payload) => Repository.post(`/api/RouteAllowances/Add`, payload),
    Edit: (payload) => Repository.post(`/api/RouteAllowances/Edit`, payload),
    Delete: (payload) => Repository.post(`/api/RouteAllowances/Delete`, payload),
    ChangeStatus: (payload) => Repository.post(`/api/RouteAllowances/ChangeStatus`, payload),
}