import Repository from "../../repository";

export default {
    GetListImportExcelTemplate: (payload) => Repository.post(`/api/ExcelTemplates/GetListImportExcelTemplate`, payload),
    GetListExportExcelTemplate: (payload) => Repository.post(`/api/ExcelTemplates/GetListExportExcelTemplate`, payload),
    GetListExcelTemplateActive: (payload) => Repository.post(`/api/ExcelTemplates/GetListExcelTemplateActive`, payload),
    GetExcelTemplateById: (payload) => Repository.post(`/api/ExcelTemplates/GetExcelTemplateById`, payload),
    AddImportExcelTemplates: (payload) => Repository.post(`/api/ExcelTemplates/AddImportExcelTemplates`, payload),
    AddExportExcelTemplates: (payload) => Repository.post(`/api/ExcelTemplates/AddExportExcelTemplates`, payload),
    EditExcelTemplate: (payload) => Repository.post(`/api/ExcelTemplates/EditExcelTemplate`, payload),
    DeleteExcelTemplate: (payload) => Repository.post(`/api/ExcelTemplates/DeleteExcelTemplate`, payload),
    ChangeStatusExcelTemplate: (payload) => Repository.post(`/api/ExcelTemplates/ChangeStatusExcelTemplate`, payload),
}
