import Repository from "../../repository";

export default {
    Get: (payload) => Repository.post(`/api/FaceDatas/Get`, payload),
    GetActive: (payload) => Repository.post(`/api/FaceDatas/GetActive`, payload),
    GetById: (payload) => Repository.post(`/api/FaceDatas/GetById`, payload),
    Add: (payload) => Repository.post(`/api/FaceDatas/Add`, payload),
    Edit: (payload) => Repository.post(`/api/FaceDatas/Edit`, payload),
    Delete: (payload) => Repository.post(`/api/FaceDatas/Delete`, payload),
    ChangeStatus: (payload) => Repository.post(`/api/FaceDatas/ChangeStatus`, payload),
}
