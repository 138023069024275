import Repository from "../../repository";

export default {
    GetContract: (payload) => Repository.post(`/api/GasContracts/GetContract`, payload),
    GetSale: (payload) => Repository.post(`/api/GasContracts/GetSale`, payload),
    GetDelivery: (payload) => Repository.post(`/api/GasContracts/GetDelivery`, payload),
    GetItemPrice: (payload) => Repository.post(`/api/GasContracts/GetItemPrice`, payload),
    GetById: (payload) => Repository.post(`/api/GasContracts/GetById`, payload),
    Add: (payload) => Repository.post(`/api/GasContracts/Add`, payload),
    Edit: (payload) => Repository.post(`/api/GasContracts/Edit`, payload),
    Edit_Approval: (payload) => Repository.post(`/api/GasContracts/Edit_Approval`, payload),
    Edit_Cancel: (payload) => Repository.post(`/api/GasContracts/Edit_Cancel`, payload),
    Delete: (payload) => Repository.post(`/api/GasContracts/Delete`, payload),
    GetItemPrices: (payload) => Repository.post(`/api/GasContracts/GetItemPrices`, payload),
    GetLastSale: (payload) => Repository.post(`/api/GasContracts/GetLastSale`, payload),
}
