import Repository from "../../repository";

export default {
    GetListCameraKiosk: (payload) => Repository.post(`/api/ReportKiosks/GetList_CameraKiosk`, payload),
    GetTop: (payload) => Repository.post(`/api/ReportKiosks/GetTop`, payload),
    GetDataByDate: (payload) => Repository.post(`/api/ReportKiosks/GetDataByDate`, payload),
    GetDataByDateByFaceID: (payload) => Repository.post(`/api/ReportKiosks/GetDataByDateByFaceID`, payload),
    GetDetailByFaceID: (payload) => Repository.post(`/api/ReportKiosks/GetDetailByFaceID`, payload),
    GetNoFace: (payload) => Repository.post(`/api/ReportKiosks/GetNoFace`, payload),
}
