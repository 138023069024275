import Repository from "../../repository";

export default {
    GetListConnectType: (payload) => Repository.post(`/api/ConnectTypes/GetListConnectType`, payload),
    GetListConnectTypeActive: (payload) => Repository.post(`/api/ConnectTypes/GetListConnectTypeActive`, payload),
    GetConnectTypeTypeById: (payload) => Repository.post(`/api/ConnectTypes/GetConnectTypeTypeById`, payload),
    AddConnectType: (payload) => Repository.post(`/api/ConnectTypes/AddConnectType`, payload),
    EditConnectType: (payload) => Repository.post(`/api/ConnectTypes/EditConnectType`, payload),
    DeleteConnectType: (payload) => Repository.post(`/api/ConnectTypes/DeleteConnectType`, payload),
    ChangeStatusConnectType: (payload) => Repository.post(`/api/ConnectTypes/ChangeStatusConnectType`, payload),
}