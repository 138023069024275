var render = function render(){var _vm=this,_c=_vm._self._c;return _c('nav',{staticClass:"navbar",class:_vm.classes},[(_vm.$slots['after-menu'])?_c('ul',{staticClass:"navbar-nav",class:{ 'd-none': _vm.windowWidth && _vm.windowWidth <= 990 },staticStyle:{"visibility":"hidden"}},[_vm._t("after-menu")],2):_vm._e(),_c('div',{staticClass:"container-fluid",class:_vm.menuClasses,style:(`margin: -12px ${_vm.marginNav}px;`)},[(_vm.$slots['left-menu'])?_c('ul',{staticClass:"navbar-nav is-show"},[_vm._t("left-menu")],2):_vm._e(),_c('div',{staticClass:"navbar-translate"},[_vm._t("default",null,null,_vm.slotData),_c('navbar-toggle-button',{attrs:{"toggled":_vm.showMenu},nativeOn:{"click":function($event){$event.stopPropagation();return _vm.toggle.apply(null, arguments)}}})],2),(
        _vm.$slots['navbar-menu'] ||
        _vm.$scopedSlots['navbar-menu'] ||
        _vm.$slots['after-menu']
      )?_c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.close),expression:"close"}],staticClass:"navbar-collapse collapse",class:[
        { show: _vm.showMenu },
        { 'has-image': _vm.menuImage },
        _vm.navMenuClasses,
      ],staticStyle:{"flex-grow":"0","background-color":"#f0f8f4"},style:(_vm.menuImage ? `background: url(${_vm.menuImage}) 0% 0% / cover;` : ''),attrs:{"data-color":"orange","id":"navigation"}},[_vm._t("before-menu"),_c('ul',{staticClass:"navbar-nav"},[(_vm.windowWidth && _vm.windowWidth <= 990)?_vm._t("left-menu"):_vm._e(),_vm._t("navbar-menu",null,null,_vm.slotData)],2)],2):_vm._e()]),_c('ul',{staticClass:"navbar-nav",class:{ 'd-none': _vm.windowWidth <= 990 }},[_vm._t("after-menu")],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }