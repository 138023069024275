import Repository from "../../repository";

export default {
    ChoseLanguage: (payload) => Repository.post(`/api/config/ChoseLanguage`, payload),
    LanguageTable: (payload) => Repository.post(`/api/config/LanguageTable`, payload),
    MobileLanguage: (payload) => Repository.post(`/api/config/MobileLanguage`, payload),
    GetListCustomize: (payload) => Repository.post(`/api/customize/GetListCustomize`, payload),
    AddCustomize: (payload) => Repository.post(`/api/customize/AddCustomize`, payload),
    EditCustomize: (payload) => Repository.post(`/api/customize/EditCustomize`, payload),
    EditHideCustomize: (payload) => Repository.post(`/api/customize/EditHideCustomize`, payload),
    DelCustomize: (payload) => Repository.post(`/api/customize/DelCustomize`, payload),
    GetColumnShow: (payload) => Repository.post(`/api/customize/GetColumnShow`, payload),
    GetByClumnName: (payload) => Repository.post(`/api/customize/GetByClumnName`, payload),
    GetColumnShowByObjectName: (payload) => Repository.post(`/api/customize/GetColumnShowByObjectName`, payload),
    GetMethod: (payload) => Repository.post(`/api/customize/GetMethod`, payload),
    submitData: (url, payload) => Repository.post(url, payload),
}
