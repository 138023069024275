import Repository from "../../repository";

export default {
    Get: (payload) => Repository.post(`/api/GasDetectorByStations/Get`, payload),
    GetLevelDetect: (payload) => Repository.post(`/api/GasDetectorByStations/GetLevelDetect`, payload),
    GetById: (payload) => Repository.post(`/api/GasDetectorByStations/GetById`, payload),
    Add: (payload) => Repository.post(`/api/GasDetectorByStations/Add`, payload),
    Edit: (payload) => Repository.post(`/api/GasDetectorByStations/Edit`, payload),
    Delete: (payload) => Repository.post(`/api/GasDetectorByStations/Delete`, payload),
    ChangeStatus: (payload) => Repository.post(`/api/GasDetectorByStations/ChangeStatus`, payload),
}