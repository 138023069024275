import Repository from "../../repository";

export default {
    Get: (payload) => Repository.post(`/api/ProcessDriverMaintenances/Get`, payload),
    GetById: (payload) => Repository.post(`/api/ProcessDriverMaintenances/GetById`, payload),
    EditReceive: (payload) => Repository.post(`/api/ProcessDriverMaintenances/EditReceive`, payload),
    ProcessReceive: (payload) => Repository.post(`/api/ProcessDriverMaintenances/ProcessReceive`, payload),
    EditComplete: (payload) => Repository.post(`/api/ProcessDriverMaintenances/EditComplete`, payload),
    ProcessComplete: (payload) => Repository.post(`/api/ProcessDriverMaintenances/ProcessComplete`, payload),
}
