import Repository from "../../repository";

export default {
    GetListDeviceInfo: (payload) => Repository.post(`/api/DeviceInfos/GetListDeviceInfo`, payload),
    GetListDeviceInfoActive: (payload) => Repository.post(`/api/DeviceInfos/GetListDeviceInfoActive`, payload),
    GetListDeviceInfosById: (payload) => Repository.post(`/api/DeviceInfos/GetListDeviceInfosById`, payload),
    AddDeviceInfo: (payload) => Repository.post(`/api/DeviceInfos/AddDeviceInfo`, payload),
    EditIndicators: (payload) => Repository.post(`/api/DeviceInfos/EditIndicators`, payload),
    DeleteDeviceInfo: (payload) => Repository.post(`/api/DeviceInfos/DeleteDeviceInfo`, payload),
    ChangeStatusDeviceInfo: (payload) => Repository.post(`/api/DeviceInfos/ChangeStatusDeviceInfo`, payload),
}
