import Repository from "../../repository";

export default {
    Get: (payload) => Repository.post(`/api/StationDevices/Get`, payload),
    GetImage: (ID, Channel) => Repository.get(`/api/StationDevices/GetImage/${ID}/${Channel}`),
    GetDeviceType: (payload) => Repository.post(`/api/StationDevices/GetDeviceType`, payload),
    GetActive: (payload) => Repository.post(`/api/StationDevices/GetActive`, payload),
    GetById: (payload) => Repository.post(`/api/StationDevices/GetById`, payload),
    Add: (payload) => Repository.post(`/api/StationDevices/Add`, payload),
    Edit: (payload) => Repository.post(`/api/StationDevices/Edit`, payload),
    Delete: (payload) => Repository.post(`/api/StationDevices/Delete`, payload),
    ChangeStatus: (payload) => Repository.post(`/api/StationDevices/ChangeStatus`, payload),
}
