import Repository from "../../repository";

export default {
    GetListGroupUser: (payload) => Repository.post(`/api/groupuser/GetListGroupUser`, payload),
    GetGroupUserByID: (payload) => Repository.post(`/api/groupuser/GetGroupUserByID`, payload),
    GetGroupUserActive: (payload) => Repository.post(`/api/groupuser/GetGroupUserActive`, payload),
    GetActiveAll: (payload) => Repository.post(`/api/groupuser/GetActiveAll`, payload),
    AddGroupUser: (payload) => Repository.post(`/api/groupuser/AddGroupUser`, payload),
    EditGroupUser: (payload) => Repository.post(`/api/groupuser/EditGroupUser`, payload),
    EditGroupUserVer2: (payload) => Repository.post(`/api/groupuser/EditGroupUserVer2`, payload),
    EditStatusGroupUser: (payload) => Repository.post(`/api/groupuser/EditStatusGroupUser`, payload),
    DelGroupUser: (payload) => Repository.post(`/api/groupuser/DelGroupUser`, payload),
    GetListUser: (payload) => Repository.post(`/api/user/GetListUser`, payload),
    GetUserByUserID: (payload) => Repository.post(`/api/user/GetUserByUserID`, payload),
    AddUser: (payload) => Repository.post(`/api/user/AddUser`, payload),
    EditUser: (payload) => Repository.post(`/api/user/EditUser`, payload),
    EditStatusUser: (payload) => Repository.post(`/api/user/EditStatusUser`, payload),
    DelUser: (payload) => Repository.post(`/api/user/DelUser`, payload),
    ResetPasswordUser: (payload) => Repository.post(`/api/user/ResetPasswordUser`, payload),
    GetSaler: (payload) => Repository.post(`/api/user/GetSaler`, payload),
    GetSalerByStore: (payload) => Repository.post(`/api/user/GetSalerByStore`, payload),
}