import Repository from "../../repository";

export default {
    Get: (payload) => Repository.post(`/api/StationRights_ByGroup/Get`, payload),
    GetIsSelected: (payload) => Repository.post(`/api/StationRights_ByGroup/GetIsSelected`, payload),
    GetNotSelected: (payload) => Repository.post(`/api/StationRights_ByGroup/GetNotSelected`, payload),
    GetGeoCode: (payload) => Repository.post(`/api/StationRights_ByGroup/GetGeoCode`, payload),
    Edit: (payload) => Repository.post(`/api/StationRights_ByGroup/Edit`, payload),
    EditByList: (payload) => Repository.post(`/api/StationRights_ByGroup/EditByList`, payload),
}
