import Repository from "../../repository";

export default {
    GetSLLightLevelList: (payload) => Repository.post(`/api/SLLightLevels/GetSLLightLevelList`, payload),
    GetSLLightLevelListActive: (payload) => Repository.post(`/api/SLLightLevels/GetSLLightLevelListActive`, payload),
    GetSLightLevelById: (payload) => Repository.post(`/api/SLLightLevels/GetSLightLevelById`, payload),
    Add: (payload) => Repository.post(`/api/SLLightLevels/Add`, payload),
    Edit: (payload) => Repository.post(`/api/SLLightLevels/Edit`, payload),
    ChangeStatus: (payload) => Repository.post(`/api/SLLightLevels/ChangeStatus`, payload),
    Delete: (payload) => Repository.post(`/api/SLLightLevels/Delete`, payload),
}
