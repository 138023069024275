/** Load region default **/
export const listRegion = {
    VietNam: {
        id: 0,
        zip: null,
        lat: 15.4,
        lng: 110.4,
        url: "viet-nam",
        name: "Việt Nam",
    }, 
    HoChiMinh: {
        id: 1,
        zip: null,
        lat: 10.7941878,
        lng: 106.6724396,
        url: "ho-chi-minh",
        name: "Hồ Chí Minh",
    },
    CanTho: {
        id: 2,
        zip: null,
        lat: 10.0407019,
        lng: 105.7492869,
        url: "can-tho",
        name: "Cần Thơ",
    },
    LongAn: {
        id: 3,
        zip: null,
        lat: 10.7645085,
        lng: 106.1512756,
        url: "long-an",
        name: "Long An",
    },
    HauGiang: {
        id: 4,
        zip: null,
        lat: 9.7909411,
        lng: 105.6335449,
        url: "hau-giang",
        name: "Hậu Giang",
    },
    NhaTrang: {
        id: 5,
        zip: null,
        lat: 12.421451,
        lng: 109.037072,
        url: "nha-trang",
        name: "Nha Trang",
    },
    PhuYen: {
        id: 6,
        zip: null,
        lat: 13.2013,
        lng: 109.1696,
        url: "phu-yen",
        name: "Phú Yên",
    },
    PhuTho: {
        id: 7,
        zip: null,
        lat: 21.3188,
        lng: 105.135,
        url: "phu-tho",
        name: "Phú Thọ",
    },
    ThanhHoa: {
        id: 8,
        zip: null,
        lat: 19.8136326,
        lng: 105.7653916,
        url: "thanh-hoa",
        name: "Thanh Hóa",
    },
    QuangNinh: {
        id: 9,
        zip: null,
        lat: 21.0800153,
        lng: 107.3007202,
        url: "quang-ninh",
        name: "Quảng Ninh",
    },
    NinhBinh: {
        id: 10,
        zip: null,
        lat: 20.1813,
        lng: 105.9686,
        url: "ninh-binh",
        name: "Ninh Bình",
    },
    DakNong: {
        id: 11,
        zip: null,
        lat: 12.1313061,
        lng: 107.7326202,
        url: "dak-nong",
        name: "Đắk Nông",
    },
    HaNoi: {
        id: 12,
        zip: null,
        lat: 20.9755,
        lng: 105.6528,
        url: "dak-nong",
        name: "Đắk Nông",
    },
    NinhThuan: {
        id: 13,
        zip: null,
        lat: 11.4647,
        lng: 109.0445,
        url: "ninh-thuan",
        name: "Ninh Thuận",
    },
    Sapporo: {
        id: 14,
        zip: null,
        lat: 42.9857,
        lng: 141.248,
        url: "sapporo",
        name: "Sapporo",
    },
    Vinh: {
        id: 15,
        zip: null,
        lat: 18.787203,
        lng: 105.605202,
        url: "vinh",
        name: "Vinh",
    },
    CaMau: {
        id: 16,
        zip: null,
        lat: 9.176205,
        lng: 105.150862,
        url: "ca-mau",
        name: "Cà Mau",
    },
    PhanRang: {
        id: 17,
        zip: null,
        lat: 11.583233331527016, 
        lng: 108.99115203493366,
        url: "phan-rang",
        name: "Phan Rang",
    },
}
