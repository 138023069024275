import Repository from "../../repository";

export default {
    Get: (payload) => Repository.post(`/api/ManagementUnit/Get`, payload),
    GetActive: (payload) => Repository.post(`/api/ManagementUnit/GetActive`, payload),
    GetByID: (payload) => Repository.post(`/api/ManagementUnit/GetByID`, payload),
    Add: (payload) => Repository.post(`/api/ManagementUnit/Add`, payload),
    Edit: (payload) => Repository.post(`/api/ManagementUnit/Edit`, payload),
    Delete: (payload) => Repository.post(`/api/ManagementUnit/Delete`, payload),
    ChangeStatus: (payload) => Repository.post(`/api/ManagementUnit/ChangeStatus`, payload),
}
