const routes = [
  {
    path: "/guideline/overview",
    component: () => import("./components/OverView.vue"),
  },
  {
    path: "/guideline/main",
    component: () => import("./components/Main.vue"),
  },
  {
    path: "/guideline/featureplanning",
    component: () => import("./components/Feature.vue"),
  },
  {
    path: "/guideline/manual",
    component: () => import("./components/Manual.vue"),
  },
  {
    path: "/guideline/app",
    component: () => import("./components/App.vue"),
  },
  {
    path: "/guideline/featuretreedetail",
    component: () => import("./components/FeatureTreeInfo.vue"),
  },
  {
    path: "/guideline/featuretreeplanning",
    component: () => import("./components/FeatureTreePlanning.vue"),
  },
  {
    path: "/guideline/blog",
    component: () => import("./components/FeatureInfo.vue"),
  },
  {
    path: "/guideline/feature-2",
    component: () => import("./components/Feature2.vue"),
  },
  {
    path: "/guideline/blog2",
    component: () => import("./components/blog2.vue"),
  },
  {
    path: "/guideline/rating",
    component: () => import("./components/Rating.vue"),
  },
];
export default routes;
