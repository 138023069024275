import Repository from "../../repository";

export default {
    Get: (payload) => Repository.post(`/api/IndicatorWarningByStations/Get`, payload),
    GetById: (payload) => Repository.post(`/api/IndicatorWarningByStations/GetById`, payload),
    Add: (payload) => Repository.post(`/api/IndicatorWarningByStations/Add`, payload),
    Edit: (payload) => Repository.post(`/api/IndicatorWarningByStations/Edit`, payload),
    Delete: (payload) => Repository.post(`/api/IndicatorWarningByStations/Delete`, payload),
    ChangeStatus: (payload) => Repository.post(`/api/IndicatorWarningByStations/ChangeStatus`, payload),
}
