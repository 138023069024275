import Repository from "../../repository";

export default {
    GetListCollect: (payload) => Repository.post(`/api/collect/GetListCollect`, payload),
    GetListCollectNotComplete: (payload) => Repository.post(`/api/collect/GetListCollectNotComplete`, payload),
    GetCollectByOID: (payload) => Repository.post(`/api/collect/GetCollectByOID`, payload),
    AddCollect: (payload) => Repository.post(`/api/collect/AddCollect`, payload),
    EditCollect: (payload) => Repository.post(`/api/collect/EditCollect`, payload),
    DelCollect: (payload) => Repository.post(`/api/collect/DelCollect`, payload),
    EditIsCloseCollection: (payload) => Repository.post(`/api/collect/EditIsCloseCollection`, payload),
    AddCollectTreeData: (payload) => Repository.post(`/api/collecttree/AddCollectTreeData`, payload),
    EditCollectTreeData: (payload) => Repository.post(`/api/collecttree/EditCollectTreeData`, payload),
    DelCollectTreeData: (payload) => Repository.post(`/api/collecttree/DelCollectTreeData`, payload),
    UpdateIsViewDataCollect: (payload) => Repository.post(`/api/collecttree/UpdateIsViewDataCollect`, payload),
    UpdateIsApprovalDataCollect: (payload) => Repository.post(`/api/collecttree/UpdateIsApprovalDataCollect`, payload),
    UpdateIsReturnDataCollect: (payload) => Repository.post(`/api/collecttree/UpdateIsReturnDataCollect`, payload),
    GetListDataCollectReturnDetailByOID: (payload) => Repository.post(`/api/collecttree/GetListDataCollectReturnDetailByOID`, payload),
    GetListDataCollectDetailByOID: (payload) => Repository.post(`/api/collecttree/GetListDataCollectDetailByOID`, payload),
    GetDataCollectDetailByID: (payload) => Repository.post(`/api/collecttree/GetDataCollectDetailByID`, payload),
}
