import Repository from "../../repository";

export default {
    LoginCustomer: (payload) => Repository.post(`/api/AuthenticationCustomer/LoginCustomer`, payload),
    LoginDriver: (payload) => Repository.post(`/api/AuthenticationCustomer/LoginDriver`, payload),
    RefreshToken: (payload) => Repository.post(`/api/AuthenticationCustomer/RefreshToken`, payload),
    CheckToken: (payload) => Repository.post(`/api/AuthenticationCustomer/CheckToken`, payload),
    ChangePasswordCustomer: (payload) => Repository.post(`/api/AuthenticationCustomer/ChangePasswordCustomer`, payload),
    ChangePasswordDriver: (payload) => Repository.post(`/api/AuthenticationCustomer/ChangePasswordDriver`, payload),
    SendCodeForgetPassword: (payload) => Repository.post(`/api/AuthenticationCustomer/SendCodeForgetPassword`, payload),
    ChangePasswordBySecurityCode: (payload) => Repository.post(`/api/AuthenticationCustomer/ChangePasswordBySecurityCode`, payload),
}