<template>
	<navbar position="fixed" menu-classes="ml-auto" navMenuClasses="" class="bg-color">
		<template>
			<router-link class="nav-link" to="/">
				<div class="d-flex align-items-center justify-content-center">
					<img src="@/assets/image/icon/logoApp.png" height="51px" class="mr-2" />
				</div>
			</router-link>
		</template>
		<template slot="navbar-menu">
			<li class="">
				<div v-if="!user" @click="isShowLogin = true" class="nav-link" to="">
					<p class="text-uppercase in-14-semi nav-hover text-nowrap text-white">
						{{ $t("login") }}
					</p>
				</div>
				<b-nav-item-dropdown v-else style="min-width: 75px" class="nav-size" no-caret>
					<template slot="button-content">
						<p class="nav-size">
							{{ user.FullName }}
						</p>
					</template>
					<b-dropdown-item @click="handleLogout"> {{ $t("logout") }}</b-dropdown-item>
				</b-nav-item-dropdown>
			</li>
			<li class="nav-item">
				<b-nav-item-dropdown style="min-width: 75px" class="nav-size" no-caret>
					<template slot="button-content">
						<p class="nav-size text-uppercase text-white"><img src="@/assets/image/icon/globe-01.svg" height="20px" />&nbsp;&nbsp;{{ currentLanguage }}</p>
					</template>
					<b-dropdown-item @click="handleChangeLanguage('en')" href="#">EN</b-dropdown-item>
					<b-dropdown-item @click="handleChangeLanguage('vi')" href="#">VI</b-dropdown-item>
				</b-nav-item-dropdown>
			</li>
		</template>
		<modalLogin :isShow="isShowLogin" @close="isShowLogin = false" @create="openCreateCloseLogin" @forget="openForgetModal"></modalLogin>
		<modalCreate :isShow="isShowCreate" @close="isShowCreate = false" @openLogin="openLoginCloseCreate"></modalCreate>
		<modalForget :isShow="isShowForget" @close="isShowForget = false" @openLogin="isShowLogin = true"></modalForget>
	</navbar>
</template>

<script>
import modalLogin from "@/components/Modal/LoginModal.vue";
import modalCreate from "@/components/Modal/CreateAccoutModal.vue";
import modalForget from "@/components/Modal/ForgetModal.vue";
import {Navbar} from "@/components";
import {Popover} from "element-ui";
export default {
	name: "main-navbar",
	props: {
		transparent: Boolean,
		colorOnScroll: Number,
	},
	components: {
		Navbar,
		[Popover.name]: Popover,
		modalLogin,
		modalCreate,
		modalForget,
	},
	watch: {
		menuActive() {},
	},
	created() {
		this.getCurrentLanguage();
		this.$watch(
			() => this.$route.params,
			() => {
				this.fetchData();
			},
			// fetch the data when the view is created and the data is
			// already being observed
			{immediate: true}
		);
	},
	data() {
		return {
			menuActive: localStorage.getItem("currentUrl"),
			path: this.$root._route.fullPath,
			currentLanguage: "",
			isShowLogin: false,
			isShowCreate: false,
			isShowForget: false,
			user: localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")) : null,
		};
	},
	methods: {
		pushUrl(url) {
			localStorage.setItem("currentUrl", url);
		},
		fetchData() {
			this.path = this.$root._route.fullPath;
		},
		getCurrentLanguage() {
			if(localStorage.getItem("currentLanguage")){
				let currentLanguage = localStorage.getItem("currentLanguage")
				this.currentLanguage = currentLanguage ==='vi' || currentLanguage ==='en' ? localStorage.getItem("currentLanguage") : "vi";
				this.$i18n.locale = this.currentLanguage;
			}else{
				this.currentLanguage = "vi"
				this.$i18n.locale = this.currentLanguage;
			}
		},
		handleChangeLanguage(value) {
			this.currentLanguage = value;
			this.$i18n.locale = value;
			localStorage.setItem("currentLanguage", value);
			location.reload();
		},
		openLoginCloseCreate() {
			this.isShowLogin = true;
			this.isShowCreate = false;
		},
		openCreateCloseLogin() {
			this.isShowLogin = false;
			this.isShowCreate = true;
		},
		openForgetModal() {
			this.isShowForget = true;
			this.isShowLogin = false;
		},
		handleLogout() {
			localStorage.removeItem("user");
			localStorage.removeItem("token");
			location.reload();
		},
	},
};
</script>
<style>
.dropdown .dropdown-toggle::after {
	color: #fafafa;
	/* display: none; */
}
</style>
<style scoped>
.btn-login {
	border: 2px solid white;
	background-color: #c71313;
	font-size: 15px;
	display: flex;
	padding: 10px 24px;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: 10px;
}
.btn-login:hover {
	background-color: #fff;
	color: #c71313 !important;
	border-color: #c71313;
}
.btn-login:focus {
	background-color: #fff;
	color: #c71313 !important;
	border-color: #c71313;
}
.bg-color {
	background-color: #063 !important;
	height: 64px !important;
	z-index: 1;
}
.nav-color {
	color: #c71313 !important ;
	border-bottom: 2px solid #c71313;
}
.nav-size {
	font-size: 14px !important;
	color: #fff !important;
}
.nav-hover:hover {
	/* transition: width 0.3s ease; */
	color: #fafafa !important ;
	/* border-bottom: 2px solid #c71313; */
}
@keyframes underline {
	0% {
		transform: scaleX(0);
	}
	100% {
		transform: scaleX(1);
	}
}

.nav-hover {
	position: relative;
	display: inline-block;
	/* padding: 10px 20px; */
	font-size: 16px;
	background-color: transparent;
	border: none;
	cursor: pointer;
	color: #fafafa !important ; /* Màu chữ */
}

.nav-hover::before {
	content: "";
	position: absolute;
	width: 0;
	height: 2px;
	background-color: #fafafa;
	bottom: -2px;
	left: 50%;
	transform: translateX(-50%);
	transition: width 0.5s ease, opacity 0.5s ease;
	opacity: 0;
}

.nav-hover:hover::before {
	width: 100%;
	opacity: 1;
}

/* .nav-hover:not(:hover)::after {
  width: 0%;
} */
.download-btn {
	background-color: #c71313;
}
.download-btn:hover {
	background-color: #a11616;
}
/* .nav-item:hover {
  background: white;
} */
</style>
