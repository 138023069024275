import Repository from "../../repository";

export default {
    Get: (payload) => Repository.post(`/api/ScheduleSlideShows/Get`, payload),
    GetByID: (payload) => Repository.post(`/api/ScheduleSlideShows/GetByID`, payload),
    GetStationSelect: (payload) => Repository.post(`/api/ScheduleSlideShows/GetStationSelect`, payload),
    EditStatus: (payload) => Repository.post(`/api/ScheduleSlideShows/EditStatus`, payload),
    Add: (payload) => Repository.postWithFile(`/api/ScheduleSlideShows/Add`, payload),
    Edit: (payload) => Repository.post(`/api/ScheduleSlideShows/Edit`, payload),
    Del: (payload) => Repository.post(`/api/ScheduleSlideShows/Del`, payload),
}
