import Repository from "../../repository";

export default {
    GetListCollectType: (payload) => Repository.post(`/api/CollectTypes/GetListCollectType`, payload),
    GetListCollectTypeActive: (payload) => Repository.post(`/api/CollectTypes/GetListCollectTypeActive`, payload),
    GetCollectTypeById: (payload) => Repository.post(`/api/CollectTypes/GetCollectTypeById`, payload),
    AddCollectType: (payload) => Repository.post(`/api/CollectTypes/AddCollectType`, payload),
    EditCollectType: (payload) => Repository.post(`/api/CollectTypes/EditCollectType`, payload),
    DeDeleteCollectTypelete: (payload) => Repository.post(`/api/CollectTypes/DeleteCollectType`, payload),
    ChangeStatusCollectType: (payload) => Repository.post(`/api/CollectTypes/ChangeStatusCollectType`, payload),
}