import Repository from "../../repository";

export default {
    Get: (payload) => Repository.post(`/api/CustomerNotifications/Get`, payload),
    GetByID: (payload) => Repository.post(`/api/CustomerNotifications/GetById`, payload),
    Add: (payload) => Repository.post(`/api/CustomerNotifications/Add`, payload),
    Edit: (payload) => Repository.post(`/api/CustomerNotifications/Edit`, payload),
    Delete: (payload) => Repository.post(`/api/CustomerNotifications/Delete`, payload),
    ChangeStatus: (payload) => Repository.post(`/api/CustomerNotifications/ChangeStatus`, payload),
    ResetSend: (payload) => Repository.post(`/api/CustomerNotifications/ResetSend`, payload),
}