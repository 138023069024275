import Repository from "../../repository";

export default {
    Get: (payload) => Repository.post(`/api/CompanyConfig/Get`, payload),
    GetByCmpnID: (payload) => Repository.post(`/api/CompanyConfig/GetByCmpnID`, payload),
    GetByID: (payload) => Repository.post(`/api/CompanyConfig/GetByID`, payload),
    Add: (payload) => Repository.post(`/api/CompanyConfig/Add`, payload),
    Edit: (payload) => Repository.post(`/api/CompanyConfig/Edit`, payload),
    ChangeStatus: (payload) => Repository.post(`/api/CompanyConfig/ChangeStatus`, payload),
}

