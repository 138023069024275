import Repository from "../../repository";

export default {
    GetEmployerList: (payload) => Repository.post(`/api/Employers/GetEmployerList`, payload),
    GetEmployerById: (payload) => Repository.post(`/api/Employers/GetEmployerById`, payload),
    Add: (payload) => Repository.post(`/api/Employers/Add`, payload),
    Edit: (payload) => Repository.post(`/api/Employers/Edit`, payload),
    Delete: (payload) => Repository.post(`/api/Employers/Delete`, payload),
    ChangeStatus: (payload) => Repository.post(`/api/Employers/ChangeStatus`, payload),
    ResetPassword: (payload) => Repository.post(`/api/Employers/ResetPassword`, payload),
}



