import Repository from "../../repository";

export default {
    GetListDataType: (payload) => Repository.post(`/api/DataTypes/GetListDataType`, payload),
    GetListDataTypeActive: (payload) => Repository.post(`/api/DataTypes/GetListDataTypeActive`, payload),
    GetDataTypeById: (payload) => Repository.post(`/api/DataTypes/GetDataTypeById`, payload),
    AddDataType: (payload) => Repository.post(`/api/DataTypes/AddDataType`, payload),
    EditDataType: (payload) => Repository.post(`/api/DataTypes/EditDataType`, payload),
    DeleteDataType: (payload) => Repository.post(`/api/DataTypes/DeleteDataType`, payload),
    ChangeStatusDatatype: (payload) => Repository.post(`/api/DataTypes/ChangeStatusDatatype`, payload),
    GetDataTypeByScreen: (payload) => Repository.post(`/api/DataTypes/GetDataTypeByScreen`, payload),
}