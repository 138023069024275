import Repository from "../../repository";

export default {
    Get: (payload) => Repository.post(`/api/BusOwners/Get`, payload),
    GetActive: (payload) => Repository.post(`/api/BusOwners/GetActive`, payload),
    GetById: (payload) => Repository.post(`/api/BusOwners/GetById`, payload),
    Add: (payload) => Repository.post(`/api/BusOwners/Add`, payload),
    Edit: (payload) => Repository.post(`/api/BusOwners/Edit`, payload),
    Delete: (payload) => Repository.post(`/api/BusOwners/Delete`, payload),
    ChangeStatus: (payload) => Repository.post(`/api/BusOwners/ChangeStatus`, payload),
}