import Repository from "../../repository";

export default {
    GetListLevelWarning: (payload) => Repository.post(`/api/LevelWarnings/GetListLevelWarning`, payload),
    GetListLevelWarningActive: (payload) => Repository.post(`/api/LevelWarnings/GetListLevelWarningActive`, payload),
    GetLevelWarningById: (payload) => Repository.post(`/api/LevelWarnings/GetLevelWarningById`, payload),
    AddLevelWarning: (payload) => Repository.post(`/api/LevelWarnings/AddLevelWarning`, payload),
    EditLevelWarning: (payload) => Repository.post(`/api/LevelWarnings/EditLevelWarning`, payload),
    DeleteLevelWarning: (payload) => Repository.post(`/api/LevelWarnings/DeleteLevelWarning`, payload),
    ChangeStatusLevelWarning: (payload) => Repository.post(`/api/LevelWarnings/ChangeStatusLevelWarning`, payload),
}
