import Repository from "../../repository";

export default {
    GetCrownList: (payload) => Repository.post(`/api/Crowns/GetCrownList`, payload),
    GetCrownListActive: (payload) => Repository.post(`/api/Crowns/GetCrownListActive`, payload),
    GetCrownById: (payload) => Repository.post(`/api/Crowns/GetCrownById`, payload),
    Add: (payload) => Repository.post(`/api/Crowns/Add`, payload),
    Edit: (payload) => Repository.post(`/api/Crowns/Edit`, payload),
    Delete: (payload) => Repository.post(`/api/Crowns/Delete`, payload),
    ChangeStatus: (payload) => Repository.post(`/api/Crowns/ChangeStatus`, payload),
}