import Repository from "../../repository";

export default {
    Get: (payload) => Repository.post(`/api/LightLevelWarnings/Get`, payload),
    GetActive: (payload) => Repository.post(`/api/LightLevelWarnings/GetActive`, payload),
    GetById: (payload) => Repository.post(`/api/LightLevelWarnings/GetById`, payload),
    Add: (payload) => Repository.post(`/api/LightLevelWarnings/Add`, payload),
    Edit: (payload) => Repository.post(`/api/LightLevelWarnings/Edit`, payload),
    Delete: (payload) => Repository.post(`/api/LightLevelWarnings/Delete`, payload),
    ChangeStatus: (payload) => Repository.post(`/api/LightLevelWarnings/ChangeStatus`, payload),
}
