import Repository from "../../repository";

export default {
    Current: (payload) => Repository.post(`/api/MonitorReports/Current`, payload),
    Total: (payload) => Repository.post(`/api/MonitorReports/Total`, payload),
    Detail: (payload) => Repository.post(`/api/MonitorReports/Detail`, payload),
    GetListForDetail: (payload) => Repository.post(`/api/MonitorReports/GetListForDetail`, payload),
    Error: (payload) => Repository.post(`/api/MonitorReports/Error`, payload),
    Warning: (payload) => Repository.post(`/api/MonitorReports/Warning`, payload),
    ReportMaster: (payload) => Repository.post(`/api/MonitorReports/ReportMaster`, payload),
}
