import Repository from "../../repository";

export default {
    Get: (payload) => Repository.post(`/api/Shippers/Get`, payload),
    GetByStoreId: (payload) => Repository.post(`/api/Shippers/GetByStoreId`, payload),
    GetActive: (payload) => Repository.post(`/api/Shippers/GetActive`, payload),
    GetById: (payload) => Repository.post(`/api/Shippers/GetById`, payload),
    Add: (payload) => Repository.post(`/api/Shippers/Add`, payload),
    Edit: (payload) => Repository.post(`/api/Shippers/Edit`, payload),
    Delete: (payload) => Repository.post(`/api/Shippers/Delete`, payload),
    ChangeStatus: (payload) => Repository.post(`/api/Shippers/ChangeStatus`, payload),
    ResetPassword: (payload) => Repository.post(`/api/Shippers/ResetPassword`, payload),
}
