import Repository from "../../repository";

export default {
    Get: (payload) => Repository.post(`/api/SaleContracts/Get`, payload),
    AutoComplete: (payload) => Repository.post(`/api/SaleContracts/AutoComplete`, payload),
    Place: (payload) => Repository.post(`/api/SaleContracts/Place`, payload),
    GetByID: (payload) => Repository.post(`/api/SaleContracts/GetByID`, payload),
    GetByIDCus: (payload) => Repository.post(`/api/SaleContracts/GetByIDCus`, payload),
    GetBySelectObject: (payload) => Repository.post(`/api/SaleContracts/GetBySelectObject`, payload),
    GetBySelectCusID: (payload) => Repository.post(`/api/SaleContracts/GetBySelectCusID`, payload),
    GetByReference: (payload) => Repository.post(`/api/SaleContracts/GetByReference`, payload),
    Add: (payload) => Repository.post(`/api/SaleContracts/Add`, payload),
    Edit: (payload) => Repository.post(`/api/SaleContracts/Edit`, payload),
    Delete: (payload) => Repository.post(`/api/SaleContracts/Delete`, payload),
    ChangeStatus: (payload) => Repository.post(`/api/SaleContracts/ChangeStatus`, payload),
    GetForWaitingApproval: (payload) => Repository.post(`/api/SaleContracts/GetForWaitingApproval`, payload),
    Approval: (payload) => Repository.post(`/api/SaleContracts/Approval`, payload),
    GetCancelReasonsApproval: (payload) => Repository.post(`/api/SaleContracts/GetCancelReasonsApproval`, payload),
    GetByCustomer: (payload) => Repository.post(`/api/SaleContracts/GetByCustomer`, payload),
    AddDepotByCustomer: (payload) => Repository.post(`/api/SaleContracts/AddDepotByCustomer`, payload),
    AddInvoiceByCustomer: (payload) => Repository.post(`/api/SaleContracts/AddInvoiceByCustomer`, payload),
    GetFileShipping: (payload) => Repository.post(`/api/SaleContracts/GetFileShipping`, payload),
}
