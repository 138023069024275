import Repository from "../../repository";

export default {
    GetTreeGroupList: (payload) => Repository.post(`/api/TreeGroups/GetTreeGroupList`, payload),
    GetTreeGroupActive: (payload) => Repository.post(`/api/TreeGroups/GetTreeGroupActive`, payload),
    GetTreeGroupById: (payload) => Repository.post(`/api/TreeGroups/GetTreeGroupById`, payload),
    Add: (payload) => Repository.post(`/api/TreeGroups/Add`, payload),
    Edit: (payload) => Repository.post(`/api/TreeGroups/Edit`, payload),
    Delete: (payload) => Repository.post(`/api/TreeGroups/Delete`, payload),
    ChangeStatus: (payload) => Repository.post(`/api/TreeGroups/ChangeStatus`, payload),
}
