import Repository from "../../repository";

export default {
    Get: (payload) => Repository.post(`/api/TreeComments/Get`, payload),
    GetById: (payload) => Repository.post(`/api/TreeComments/GetById`, payload),
    Add: (payload) => Repository.post(`/api/TreeComments/Add`, payload),
    Edit: (payload) => Repository.post(`/api/TreeComments/Edit`, payload),
    Close: (payload) => Repository.post(`/api/TreeComments/Close`, payload),
    Delete: (payload) => Repository.post(`/api/TreeComments/Delete`, payload),
}
