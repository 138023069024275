import Repository from "../../repository";

export default {
    Get: (payload) => Repository.post(`/api/NPLCategories/Get`, payload),
    GetActive: (payload) => Repository.post(`/api/NPLCategories/GetActive`, payload),
    GetOrderTypesForDriver: (payload) => Repository.post(`/api/NPLCategories/GetOrderTypesForDriver`, payload),
    GetGoodsTypesForDriver: (payload) => Repository.post(`/api/NPLCategories/GetGoodsTypesForDriver`, payload),
    GetByTransportTypeID: (payload) => Repository.post(`/api/NPLCategories/GetByTransportTypeID`, payload),
    GetById: (payload) => Repository.post(`/api/NPLCategories/GetById`, payload),
    Add: (payload) => Repository.post(`/api/NPLCategories/Add`, payload),
    Edit: (payload) => Repository.post(`/api/NPLCategories/Edit`, payload),
    Delete: (payload) => Repository.post(`/api/NPLCategories/Delete`, payload),
    ChangeStatus: (payload) => Repository.post(`/api/NPLCategories/ChangeStatus`, payload),
}
