import Repository from "../../repository";

export default {
    GetListExport: (payload) => Repository.post(`/api/StationByGasFamilys/GetListExport`, payload),
    ExportProduct: (payload) => Repository.post(`/api/StationByGasFamilys/ExportProduct`, payload),
    EditDeploy: (payload) => Repository.post(`/api/StationByGasFamilys/EditDeploy`, payload),
    DeleteDeploy: (payload) => Repository.post(`/api/StationByGasFamilys/DeleteDeploy`, payload),
    GetById: (payload) => Repository.post(`/api/StationByGasFamilys/GetById`, payload),
    Get: (payload) => Repository.post(`/api/StationByGasFamilys/Get`, payload),
    Delete: (payload) => Repository.post(`/api/StationByGasFamilys/Delete`, payload),
    ChangeStatus: (payload) => Repository.post(`/api/StationByGasFamilys/ChangeStatus`, payload),
}
