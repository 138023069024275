import Repository from "../../repository";

export default {
    Get: (payload) => Repository.post(`/api/GroupCustomers/Get`, payload),
    GetActiveAll: (payload) => Repository.post(`/api/GroupCustomers/GetActiveAll`, payload),
    GetActive: (payload) => Repository.post(`/api/GroupCustomers/GetActive`, payload),
    GetById: (payload) => Repository.post(`/api/GroupCustomers/GetById`, payload),
    Add: (payload) => Repository.post(`/api/GroupCustomers/Add`, payload),
    Edit: (payload) => Repository.post(`/api/GroupCustomers/Edit`, payload),
    Delete: (payload) => Repository.post(`/api/GroupCustomers/Delete`, payload),
    ChangeStatus: (payload) => Repository.post(`/api/GroupCustomers/ChangeStatus`, payload),
}



