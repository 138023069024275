import Repository from "../../repository";

export default {
    GetRegionList: (payload) => Repository.post(`/api/Regions/GetRegionList`, payload),
    GETCITY: (payload) => Repository.post(`/api/Regions/GETCITY`, payload),
    GETCITYDetail: (payload) => Repository.post(`/api/Regions/GETCITYDetail`, payload),
    GetRegionListActive: (payload) => Repository.post(`/api/Regions/GetRegionListActive`, payload),
    GetSRegionById: (payload) => Repository.post(`/api/Regions/GetSRegionById`, payload),
    Add: (payload) => Repository.post(`/api/Regions/Add`, payload),
    Edit: (payload) => Repository.post(`/api/Regions/Edit`, payload),
    ChangeStatus: (payload) => Repository.post(`/api/Regions/ChangeStatus`, payload),
    Delete: (payload) => Repository.post(`/api/Regions/Delete`, payload),
}
