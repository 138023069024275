import Repository from "../../repository";

export default {
    GetSLCabinetList: (payload) => Repository.post(`/api/SLCabinets/GetSLCabinetList`, payload),
    GetSLCabinetExportList: (payload) => Repository.post(`/api/SLCabinets/GetSLCabinetExportList`, payload),
    GetDeploy: (payload) => Repository.post(`/api/SLCabinets/GetDeploy`, payload),
    GetSLCabinetListActive: (payload) => Repository.post(`/api/SLCabinets/GetSLCabinetListActive`, payload),
    GetSLCabinetById: (payload) => Repository.post(`/api/SLCabinets/GetSLCabinetById`, payload),
    Export: (payload) => Repository.post(`/api/SLCabinets/Export`, payload),
    EditDeploy: (payload) => Repository.post(`/api/SLCabinets/EditDeploy`, payload),
    Delete: (payload) => Repository.post(`/api/SLCabinets/Delete`, payload),
    ChangeStatus: (payload) => Repository.post(`/api/SLCabinets/ChangeStatus`, payload),
}
