import Repository from "../../repository";

export default {
    GetTreeTypeList: (payload) => Repository.post(`/api/TreeTypes/GetTreeTypeList`, payload),
    GetTreeTypeActive: (payload) => Repository.post(`/api/TreeTypes/GetTreeTypeActive`, payload),
    GetTreeStatusById: (payload) => Repository.post(`/api/TreeStatus/GetTreeStatusById`, payload),
    GetTreeTypeById: (payload) => Repository.post(`/api/TreeTypes/GetTreeTypeById`, payload),
    Add: (payload) => Repository.post(`/api/TreeTypes/Add`, payload),
    Edit: (payload) => Repository.post(`/api/TreeTypes/Edit`, payload),
    Delete: (payload) => Repository.post(`/api/TreeTypes/Delete`, payload),
    ChangeStatus: (payload) => Repository.post(`/api/TreeTypes/ChangeStatus`, payload),
}
