// export const rootURL = "https://tomcat.namlongtekgroup.com/geoserver/"
export const rootURL = "https://geo.nlt-group.com:15100/geoserver/"
const geoserverURL = `${rootURL}rest`
const wfsURL = `${rootURL}wfs`
const defaultLoad = 1000000;

// Tạo yêu cầu để lấy danh sách các lớp WMS trong workspace
export const checkWMSLayerExists = async (username, password, workspace, layerName) => {
    const path = `${geoserverURL}/workspaces/${workspace}/datastores/${layerName}.json`;
    try {
        const auth = 'Basic ' + btoa(`${username}:${password}`);
        const response = await fetch(path, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                Authorization: auth,
            },
        });
        let success = false
        if (response.status === 200) {
            success = true
            console.log(`WMS layer "${layerName}" exists in workspace "${workspace}".`);
        } else if (response.status === 404) {
            success = false
            console.log(`WMS layer "${layerName}" does not exist in workspace "${workspace}".`);
        } else {
            success = false
            console.error(`Error checking WMS layer: ${response.statusText}`);
        }
        return success
    } catch (error) {
        console.error(`Error checking WMS layer: ${error.message}`);
    }
};
//Các hàm để thao tác cùng dữ liệu trao WMS
export const getLayerWFS = (workspace, layer, BBOX = null) => {
    const path = `${rootURL}${workspace}/ows`;
    const defaultParameters = {
        service: "WFS",
        version: "1.0.0",
        request: "GetFeature",
        typeName: workspace + ":" + layer,
        outputFormat: "application/json",
        SrsName: "EPSG:4326",
        maxFeatures: defaultLoad,
    };
    if (BBOX) {
        defaultParameters.bbox = BBOX
    }
    const parameters = new URLSearchParams(defaultParameters).toString();
    return path + '?' + parameters;
}
export const insertPoint = async (workspace, urlWorkspace, layer, data) => {
    const keyID = "ID";
    const keyLat = "Lat";
    const keyLong = "Long";
    const keyTreeRoutes = "TreeRoutes";
    const keyTreeGroups = "TreeGroups";
    const keyTreeTypes = "TreeTypes";
    const keyTrees = "Trees";
    const keyTreeStatus = "TreeStatus";
    const keyTreeNumber = "TreeNumber";
    const keyTreeColor = "TreeColor";
    const geometry = `<${workspace}:the_geom>
                        <gml:Point srsName="EPSG:4326">
                            <gml:coordinates>${parseFloat(data[keyLong]).toFixed(8)},${parseFloat(data[keyLat]).toFixed(8)}</gml:coordinates>
                        </gml:Point>
                    </${workspace}:the_geom>`;
    let additionalProperties = "";
    if (layer === "TREE") {
        additionalProperties = `<${workspace}:${keyTreeRoutes}>${data[keyTreeRoutes]}</${workspace}:${keyTreeRoutes}>
                                <${workspace}:${keyTreeGroups}>${data[keyTreeGroups]}</${workspace}:${keyTreeGroups}>
                                <${workspace}:${keyTreeTypes}>${data[keyTreeTypes]}</${workspace}:${keyTreeTypes}>
                                <${workspace}:${keyTrees}>${data[keyTrees]}</${workspace}:${keyTrees}>
                                <${workspace}:${keyTreeStatus}>${data[keyTreeStatus]}</${workspace}:${keyTreeStatus}>
                                <${workspace}:${keyTreeNumber}>${data[keyTreeNumber]}</${workspace}:${keyTreeNumber}>
                                <${workspace}:${keyTreeColor}>${data[keyTreeColor]}</${workspace}:${keyTreeColor}>`;
    }
    const body = `<wfs:Transaction version="2.0.0" service="WFS" xmlns:wfs="http://www.opengis.net/wfs/2.0" xmlns:fes="http://www.opengis.net/fes/2.0" xmlns:gml="http://www.opengis.net/gml/3.2" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns:${workspace}="${urlWorkspace}" xsi:schemaLocation="http://www.someserver.example.com/myns ./SampleSchema.xsd http://www.opengis.net/wfs/2.0 http://schemas.opengis.net/wfs/2.0/wfs.xsd http://www.opengis.net/gml/3.2 http://schemas.opengis.net/gml/3.2.1/gml.xsd">
                    <wfs:Insert>
                        <${workspace}:${layer}>
                            <${workspace}:${keyID}>${data[keyID]}</${workspace}:${keyID}>
                            ${additionalProperties}
                            ${geometry}
                        </${workspace}:${layer}>
                    </wfs:Insert>
                </wfs:Transaction>`;
    let success = false;
    try {
        const response = await fetch(wfsURL, {
            method: "POST",
            credentials: "same-origin",
            dataType: "xml",
            headers: {
                "Content-Type": "text/xml",
            },
            body: body,
        });
        if (response.status === 200) {
            success = true;
            console.log(`Insert point from layer "${layer}" success.`);
        }
    } catch (error) {
        console.error("Error insert point", error);
    }
    return success;
}
export const updatePoint = async (workspace, layer, data) => {
    const keyID = "ID";
    const keyLat = "Lat";
    const keyLong = "Long";
    const keyTreeNumber = "TreeNumber";
    const keyTreeRoutes = "TreeRoutes";
    const keyTreeGroups = "TreeGroups";
    const keyTreeTypes = "TreeTypes";
    const keyTrees = "Trees";
    const keyTreeStatus = "TreeStatus";
    const keyTreeColor = "TreeColor";
    const properties = `<wfs:Property>
                            <wfs:ValueReference>the_geom</wfs:ValueReference>
                            <wfs:Value>
                                <gml:MultiPoint srsName="http://www.opengis.net/gml/srs/epsg.xml#4326">
                                    <gml:pointMember>
                                        <gml:Point>
                                            <gml:coordinates xmlns:gml="http://www.opengis.net/gml" decimal="." cs="," ts=" ">${parseFloat(data[keyLong]).toFixed(8)},${parseFloat(data[keyLat]).toFixed(8)}</gml:coordinates>
                                        </gml:Point>
                                    </gml:pointMember>        
                                </gml:MultiPoint>
                            </wfs:Value>
                        </wfs:Property>`;
    let additionalProperties = "";
    if (layer == "TREE") {
        additionalProperties = `<wfs:Property>
                                    <wfs:ValueReference>${keyTreeNumber}</wfs:ValueReference>
                                    <wfs:Value>${data[keyTreeNumber]}</wfs:Value>
                                </wfs:Property>
                                <wfs:Property>
                                    <wfs:ValueReference>${keyTreeRoutes}</wfs:ValueReference>
                                    <wfs:Value>${data[keyTreeRoutes]}</wfs:Value>
                                </wfs:Property>
                                <wfs:Property>
                                    <wfs:ValueReference>${keyTreeGroups}</wfs:ValueReference>
                                    <wfs:Value>${data[keyTreeGroups]}</wfs:Value>
                                </wfs:Property>
                                <wfs:Property>
                                    <wfs:ValueReference>${keyTreeTypes}</wfs:ValueReference>
                                    <wfs:Value>${data[keyTreeTypes]}</wfs:Value>
                                </wfs:Property>
                                <wfs:Property>
                                    <wfs:ValueReference>${keyTrees}</wfs:ValueReference>
                                    <wfs:Value>${data[keyTrees]}</wfs:Value>
                                </wfs:Property>
                                <wfs:Property>
                                    <wfs:ValueReference>${keyTreeStatus}</wfs:ValueReference>
                                    <wfs:Value>${data[keyTreeStatus]}</wfs:Value>
                                </wfs:Property>
                                <wfs:Property>
                                    <wfs:ValueReference>${keyTreeColor}</wfs:ValueReference>
                                    <wfs:Value>${data[keyTreeColor]}</wfs:Value>
                                </wfs:Property>`;
    }
    const body = `<?xml version="1.0" ?>
                <wfs:Transaction version="2.0.0" service="WFS" xmlns:fes="http://www.opengis.net/fes/2.0" xmlns:wfs="http://www.opengis.net/wfs/2.0" xmlns:gml="http://www.opengis.net/gml/3.2" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xsi:schemaLocation="http://www.opengis.net/wfs/2.0 http://schemas.opengis.net/wfs/2.0/wfs.xsd">
                    <wfs:Update typeName="${workspace}:${layer}">
                        ${properties}
                        ${additionalProperties}
                        <fes:Filter>
                            <fes:PropertyIsEqualTo>
                                <fes:ValueReference>${keyID}</fes:ValueReference>
                                <fes:Literal>${data[keyID]}</fes:Literal>
                            </fes:PropertyIsEqualTo>
                        </fes:Filter>
                    </wfs:Update>
                </wfs:Transaction>`;
    let success = false;
    try {
        const response = await fetch(wfsURL, {
            method: "POST",
            credentials: "same-origin",
            dataType: "xml",
            headers: {
                "Content-Type": "text/xml",
            },
            body: body,
        });
        if (response.status === 200) {
            success = true;
            console.log(`Updated coordinates for point with ID ${data[keyID]}`);
        }
    } catch (error) {
        console.error(`Error updating coordinates for point with ID ${data[keyID]}: ${error.message}`);
    }
    return success;
}
export const deletePoint = async (workspace, layer, data) => {
    const keyID = "ID"
    const body = `<?xml version="1.0" ?>
                <wfs:Transaction version="2.0.0" service="WFS" xmlns:fes="http://www.opengis.net/fes/2.0" xmlns:wfs="http://www.opengis.net/wfs/2.0" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xsi:schemaLocation="http://www.opengis.net/wfs/2.0 http://schemas.opengis.net/wfs/2.0/wfs.xsd">
                    <wfs:Delete typeName="${workspace}:${layer}">
                        <fes:Filter>
                            <fes:PropertyIsEqualTo>
                                <fes:ValueReference>${keyID}</fes:ValueReference>
                                <fes:Literal>${data[keyID]}</fes:Literal>
                            </fes:PropertyIsEqualTo>
                        </fes:Filter>
                    </wfs:Delete>
                </wfs:Transaction>`;
    try {
        const response = await fetch(wfsURL, {
            method: "POST",
            credentials: "same-origin",
            dataType: "xml",
            headers: {
                "Content-Type": "text/xml",
            },
            body: body,
        });
        let success = false
        if (response.status === 200) {
            success = true
            console.log(`Deleted all features from layer "${layer}".`);
        } else {
            success = false
            console.error(`Error deleting features: ${response.statusText}`);
        }
        return success
    } catch (error) {
        console.error("Error deleting features", error);
    }
}

