import Repository from "../../repository";

export default {
    Get: (payload) => Repository.post(`/api/BalanceDebitTanks/Get`, payload),
    GetById: (payload) => Repository.post(`/api/BalanceDebitTanks/GetById`, payload),
    Add: (payload) => Repository.post(`/api/BalanceDebitTanks/Add`, payload),
    AddWithExcel: (payload) => Repository.post(`/api/BalanceDebitTanks/AddWithExcel`, payload),
    Edit: (payload) => Repository.post(`/api/BalanceDebitTanks/Edit`, payload),
    Delete: (payload) => Repository.post(`/api/BalanceDebitTanks/Delete`, payload),
}
