/**
 * Import theo thứ tự a đến z, không nhập lung tung
 */
import AuthenticationCustomer from "./entity/swagger/Authentication.Customer.Microservice";
import AuthenticationDriver from "./entity/swagger/Authentication.Driver.Microservice";
import AuthenticationSystem from "./entity/swagger/Authentication.System.Microservice";
import AquacultureCharts from "./entity/swagger/Microservice.Aquaculture.Charts";
import AquacultureReport from "./entity/swagger/Microservice.Aquaculture.Report";
import AAuthenticationGuest from "./entity/swagger/A.AuthenticationGuest";
import BusGetMapNow from "./entity/swagger/Microservice.Bus.GetMapNow";
import CameraThermalReports from "./entity/swagger/Microservice.CameraThermal.Reports";
import CategoryAquaStations from "./entity/swagger/Microservice.Category.AquaStations";
import CategoryBusCompanies from "./entity/swagger/Microservice.Category.BusCompanies";
import CategoryBusOwners from "./entity/swagger/Microservice.Category.BusOwners";
import CategoryBusRoutes from "./entity/swagger/Microservice.Category.BusRoutes";
import CategoryBusStops from "./entity/swagger/Microservice.Category.BusStops";
import CategoryBusTicketTypes from "./entity/swagger/Microservice.Category.BusTicketTypes";
import CategoryBusTicketPrices from "./entity/swagger/Microservice.Category.BusTicketPrices";
import CategoryBusVehicles from "./entity/swagger/Microservice.Category.BusVehicles";
import CategoryCameras from "./entity/swagger/Microservice.Category.Cameras";
import CategoryColectType from "./entity/swagger/Microservice.Category.CollectType";
import CategoryColor from "./entity/swagger/Microservice.Category.Colors";
import CategoryConnectTypes from "./entity/swagger/Microservice.Category.ConnectTypes";
import CategoryCrowns from "./entity/swagger/Microservice.Category.Crowns";
import CategoryDataTypes from "./entity/swagger/Microservice.Category.DataTypes";
import CategoryDeviceInfos from "./entity/swagger/Microservice.Category.DeviceInfos";
import CategoryExcel from "./entity/swagger/Microservice.Category.Excel";
import CategoryFireLocationDetails from "./entity/swagger/Microservice.Category.FireLocationDetails";
import CategoryFireLocations from "./entity/swagger/Microservice.Category.FireLocations";
import CategoryFireNodes from "./entity/swagger/Microservice.Category.FireNodes";
import CategoryFireStations from "./entity/swagger/Microservice.Category.FireStations";
import CategoryFoodCourts from "./entity/swagger/Microservice.Category.FoodCourts";
import CategoryGasDetectorByStations from "./entity/swagger/Microservice.Category.GasDetectorByStations";
import CategoryGasItemGroups from "./entity/swagger/Microservice.Category.GasItemGroups";
import CategoryGasItems from "./entity/swagger/Microservice.Category.GasItems";
import CategoryGasTypes from "./entity/swagger/Microservice.Category.GasTypes";
import CategoryGasUnits from "./entity/swagger/Microservice.Category.GasUnits";
import CategoryIndicators from "./entity/swagger/Microservice.Category.Indicators";
import CategoryLands from "./entity/swagger/Microservice.Category.Lands";
import CategoryLeafs from "./entity/swagger/Microservice.Category.Leafs";
import CategoryLedStations from "./entity/swagger/Microservice.Category.LedStations";
import CategoryMaintenanceArea from "./entity/swagger/Microservice.Category.MaintenanceArea";
import CategoryMaintenanceRequestType from "./entity/swagger/Microservice.Category.MaintenanceRequestType";
import CategoryManagementUnit from "./entity/swagger/Microservice.Category.ManagementUnit";
import CategoryMonitorLocations from "./entity/swagger/Microservice.Category.MonitorLocations";
import CategorynPL from "./entity/swagger/Microservice.Category.nPL";
import CategorynPLChargingStationDetails from "./entity/swagger/Microservice.Category.nPLChargingStationDetails";
import CategorynPLCustomer from "./entity/swagger/Microservice.Category.nPLCustomer";
import CategorynPLCustomersDetails from "./entity/swagger/Microservice.Category.nPLCustomersDetails";
import CategorynPLDriveHelper from "./entity/swagger/Microservice.Category.nPLDriveHelper";
import CategorynPLDriver from "./entity/swagger/Microservice.Category.nPLDriver";
import CategorynPLExperts from "./entity/swagger/Microservice.Category.nPLExperts";
import CategorynPLRegions from "./entity/swagger/Microservice.Category.nPLRegions";
import CategorynPLRoutes from "./entity/swagger/Microservice.Category.nPLRoutes";
import CategorynPLVehicles from "./entity/swagger/Microservice.Category.nPLVehicles";
import CategoryPositions from "./entity/swagger/Microservice.Category.Positions";
import CategoryPowerTypes from "./entity/swagger/Microservice.Category.PowerTypes"
import CategoryRadioFMTransceiver from "./entity/swagger/Microservice.Category.RadioFMTransceiver";
import CategoryRadioRegions from "./entity/swagger/Microservice.Category.RadioRegions";
import CategoryRadioRelay from "./entity/swagger/Microservice.Category.RadioRelay";
import CategoryRadioStations from "./entity/swagger/Microservice.Category.RadioStations";
import CategoryRegions from "./entity/swagger/Microservice.Category.Regions";
import CategorySLCabinets from "./entity/swagger/Microservice.Category.SLCabinets";
import CategorySLLamps from "./entity/swagger/Microservice.Category.SLLamps"
import CategorySLLightLevels from "./entity/swagger/Microservice.Category.SLLightLevels";
import CategoryStationByGasFamilys from "./entity/swagger/Microservice.Category.StationByGasFamilys";
import CategoryStationByGasFoodCourts from "./entity/swagger/Microservice.Category.StationByGasFoodCourts";
import CategoryStationByGasRestaurents from "./entity/swagger/Microservice.Category.StationByGasRestaurents";
import CategoryStationByKioskCameras from "./entity/swagger/Microservice.Category.StationByKioskCameras";
import CategoryStationByThermalCameras from "./entity/swagger/Microservice.Category.StationByThermalCameras";
import CategoryStationDevices from "./entity/swagger/Microservice.Category.StationDevices";
import CategoryStationExtras from "./entity/swagger/Microservice.Category.StationExtras";
import CategoryStationImages from "./entity/swagger/Microservice.Category.StationImages";
import CategoryStationLinks from "./entity/swagger/Microservice.Category.StationLinks";
import CategoryStationNodes from "./entity/swagger/Microservice.Category.StationNodes";
import CategoryStations from "./entity/swagger/Microservice.Category.Stations";
import CategoryStationSpeakers from "./entity/swagger/Microservice.Category.StationSpeakers";
import CategoryStatus from "./entity/swagger/Microservice.Category.Status";
import CategoryStores from "./entity/swagger/Microservice.Category.Stores";
import CategoryTreeCategories from "./entity/swagger/Microservice.Category.TreeCategories";
import CategoryTreeGroups from "./entity/swagger/Microservice.Category.TreeGroups";
import CategoryTreeRoots from "./entity/swagger/Microservice.Category.TreeRoots";
import CategoryTreeRoutes from "./entity/swagger/Microservice.Category.TreeRoutes";
import CategoryTrees from "./entity/swagger/Microservice.Category.Trees";
import CategoryTreeStatus from "./entity/swagger/Microservice.Category.TreeStatus";
import CategoryTreeTypes from "./entity/swagger/Microservice.Category.TreeTypes";
import CategoryUploads from "./entity/swagger/Microservice.Category.Uploads";
import CategoryWaterwayBeacons from "./entity/swagger/Microservice.Category.WaterwayBeacons";
import CategoryWaterwayCabinets from "./entity/swagger/Microservice.Category.WaterwayCabinets";
import CategoryWaterwayClearanceHeights from "./entity/swagger/Microservice.Category.WaterwayClearanceHeights";
import CategoryWaterwayRoutes from "./entity/swagger/Microservice.Category.WaterwayRoutes";
import CategoryWaterwaySigns from "./entity/swagger/Microservice.Category.WaterwaySigns";
import CategoryWaterwaySignTypes from "./entity/swagger/Microservice.Category.WaterwaySignTypes";
import CategoryWaterwayStations from "./entity/swagger/Microservice.Category.WaterwayStations";
import CategoryWaterwayTypes from "./entity/swagger/Microservice.Category.WaterwayTypes";
import CollectChart from "./entity/swagger/Microservice.Collect.Chart";
import CollectCollectData from "./entity/swagger/Microservice.Collect.CollectData";
import CollectExcelProcessing from "./entity/swagger/Microservice.Collect.ExcelProcessing";
import ConfigAttachFiles from "./entity/swagger/Microservice.Config.AttachFiles";
import ConfigBackups from "./entity/swagger/Microservice.Config.Backups";
import ConfigCameraRight from "./entity/swagger/Microservice.Config.CameraRight";
import ConfigCompany from "./entity/swagger/Microservice.Config.Company";
import ConfigCustomerGroups from "./entity/swagger/Microservice.Config.CustomerGroups";
import ConfigCustomers from "./entity/swagger/Microservice.Config.Customers";
import ConfigCustomerUserGroups from "./entity/swagger/Microservice.Config.CustomerUserGroups";
import ConfigEmployers from "./entity/swagger/Microservice.Config.Employers";
import ConfigEntrys from "./entity/swagger/Microservice.Config.Entrys";
import ConfigFactors from "./entity/swagger/Microservice.Config.Factors";
import ConfigFormPrints from "./entity/swagger/Microservice.Config.FormPrints";
import ConfigGroupEmployers from "./entity/swagger/Microservice.Config.GroupEmployers";
import ConfigImportDatas from "./entity/swagger/Microservice.Config.ImportDatas";
import ConfigMenu from "./entity/swagger/Microservice.Config.Menu";
import ConfigMobileMenu from "./entity/swagger/Microservice.Config.MobileMenu";
import ConfigSearch from "./entity/swagger/Microservice.Config.Search";
import ConfigShippers from "./entity/swagger/Microservice.Config.Shippers";
import ConfigStationRight_ByGroups from "./entity/swagger/Microservice.Config.StationRight_ByGroups";
import ConfigSystem from "./entity/swagger/Microservice.Config.System";
import ConfigUsers from "./entity/swagger/Microservice.Config.Users";
import DebitGasValuesFoodCourt from "./entity/swagger/Microservice.DebitGasValues.FoodCourt";
import FaceRecognitionBlackLists from "./entity/swagger/Microservice.FaceRecognition.BlackLists";
import FaceRecognitionFaceDatas from "./entity/swagger/Microservice.FaceRecognition.FaceDatas";
import FireWarningReports from "./entity/swagger/Microservice.FireWarning.Reports";
import GasFamilysReports from "./entity/swagger/Microservice.GasFamilys.Reports";
import GasFoodCourtsReports from "./entity/swagger/Microservice.GasFoodCourts.Reports";
import GasRestaurantsReports from "./entity/swagger/Microservice.GasRestaurants.Reports";
import GreeneriesMaintenanceRequests from "./entity/swagger/Microservice.Greeneries.MaintenanceRequests";
import GreeneryTreeDataCurrents from "./entity/swagger/Microservice.Greenery.TreeDataCurrents";
import GuestContacts from "./entity/swagger/System.GuestContacts";
import KioskReports from "./entity/swagger/Microservice.Kiosk.Reports";
import LightingIndicatorWarnings from "./entity/swagger/Microservice.Lighting.IndicatorWarnings";
import LightingLevelWarnings from "./entity/swagger/Microservice.Lighting.LevelWarnings";
import LightingsCabinetActions from "./entity/swagger/Microservice.Lightings.CabinetActions";
import LightingsCabinetSchedules from "./entity/swagger/Microservice.Lightings.CabinetSchedules";
import LightingsCustomActions from "./entity/swagger/Microservice.Lightings.CustomActions";
import LightingsHistories from "./entity/swagger/Microservice.Lightings.Histories";
import LightingsMaintenanceErrors from "./entity/swagger/Microservice.Lightings.MaintenanceErrors";
import LightingsMaintenanceOthers from "./entity/swagger/Microservice.Lightings.MaintenanceOthers";
import LightingsMaintenances from "./entity/swagger/Microservice.Lightings.Maintenances";
import LightingsReports from "./entity/swagger/Microservice.Lightings.Reports";
import LightingsSmartLedReport from "./entity/swagger/Microservice.Lightings.SmartLedReport";
import LightingsSmartLedSchedule from "./entity/swagger/Microservice.Lightings.SmartLedSchedule";
import MasterCharts from "./entity/swagger/Microservice.Master.Charts";
import MasterDashboard from "./entity/swagger/Microservice.Master.Dashboard";
import MasterMaps from "./entity/swagger/Microservice.Master.Maps";
import MasterMonitors from "./entity/swagger/Microservice.Master.Monitors";
import MasterNotification from "./entity/swagger/Microservice.Master.Notification";
import MasterNotifyConfigs from "./entity/swagger/Microservice.Master.NotifyConfigs";
import MasterNotifyRight from "./entity/swagger/Microservice.Master.NotifyRight";
import MasterNotifyTypes from "./entity/swagger/Microservice.Master.NotifyTypes";
import MasterTokenApps from "./entity/swagger/Microservice.Master.TokenApps";
import MediasCommentDetails from "./entity/swagger/Microservice.Medias.CommentDetails";
import MediasComments from "./entity/swagger/Microservice.Medias.Comments";
import MediasTreeComments from "./entity/swagger/Microservice.Medias.TreeComments";
import MonitorIndicatorWarningByStations from "./entity/swagger/Microservice.Monitor.IndicatorWarningByStations";
import MonitorIndicatorWarnings from "./entity/swagger/Microservice.Monitor.IndicatorWarnings";
import MonitorLevelWarnings from "./entity/swagger/Microservice.Monitor.LevelWarnings";
import MonitorReport from "./entity/swagger/Microservice.Monitor.Report";
import MonitorStationLocationMaps from "./entity/swagger/Microservice.Monitor.StationLocationMaps";
import MonitorStationRights from "./entity/swagger/Microservice.Monitor.StationRights";
import NotificationContacts from "./entity/swagger/Microservice.Notification.Contacts";
import NotificationCustomers from "./entity/swagger/Microservice.Notification.Customers";
import NotificationInternals from "./entity/swagger/Microservice.Notification.Internals";
import nPLAdvanceDrivers from "./entity/swagger/Microservice.nPL.AdvanceDrivers";
import nPLAllowances from "./entity/swagger/Microservice.nPL.Allowances";
import nPLCustomerShippings from "./entity/swagger/Microservice.nPL.CustomerShippings";
import nPLDataBeginStartsVehicles from "./entity/swagger/Microservice.nPL.DataBeginStartsVehicles";
import nPLDebitPeriodOils from "./entity/swagger/Microservice.nPL.DebitPeriodOils";
import nPLDriverRequests from "./entity/swagger/Microservice.nPL.DriverRequests";
import nPLDriverSalarys from "./entity/swagger/Microservice.nPL.DriverSalarys";
import nPLDriverShippings from "./entity/swagger/Microservice.nPL.DriverShippings";
import nPLETCCosts from "./entity/swagger/Microservice.nPL.ETCCosts";
import nPLGetMapNow from "./entity/swagger/Microservice.nPL.GetMapNow";
import nPLMaintenanceAllowances from "./entity/swagger/Microservice.nPL.MaintenanceAllowances";
import nPLMonthlyTickets from "./entity/swagger/Microservice.nPL.MonthlyTickets";
import nPLOilAllowances from "./entity/swagger/Microservice.nPL.OilAllowances";
import nPLProcessChangeTires from "./entity/swagger/Microservice.nPL.ProcessChangeTires";
import nPLProcessDriverMaintenances from "./entity/swagger/Microservice.nPL.ProcessDriverMaintenances";
import nPLProcessDriverRepairs from "./entity/swagger/Microservice.nPL.ProcessDriverRepairs";
import nPLProcessRequestVehicles from "./entity/swagger/Microservice.nPL.ProcessRequestVehicles";
import nPLProposedCreditLimits from "./entity/swagger/Microservice.nPL.ProposedCreditLimits";
import nPLProposedRouteCosts from "./entity/swagger/Microservice.nPL.ProposedRouteCosts";
import nPLProposedShippingPrices from "./entity/swagger/Microservice.nPL.ProposedShippingPrices";
import nPLReceiveShipRequests from "./entity/swagger/Microservice.nPL.ReceiveShipRequests";
import nPLRenewallDocs from "./entity/swagger/Microservice.nPL.RenewallDocs";
import nPLRequestVehicles from "./entity/swagger/Microservice.nPL.RequestVehicles";
import nPLRouteAllowances from "./entity/swagger/Microservice.nPL.RouteAllowances";
import nPLSaleContracts from "./entity/swagger/Microservice.nPL.SaleContracts";
import nPLShippingAndCreditnPL from "./entity/swagger/Microservice.nPL.ShippingAndCreditnPL";
import nPLShippingIncurreds from "./entity/swagger/Microservice.nPL.ShippingIncurreds";
import nPLShippings from "./entity/swagger/Microservice.nPL.Shippings";
import SalesAdjustDebits from "./entity/swagger/Microservice.Sales.AdjustDebits";
import SalesAdjustDebits_FoodCourts from "./entity/swagger/Microservice.Sales.AdjustDebits_FoodCourts";
import SalesAdjustPrices from "./entity/swagger/Microservice.Sales.AdjustPrices";
import SalesBalanceDebits from "./entity/swagger/Microservice.Sales.BalanceDebits";
import SalesBalanceDebitTanks from "./entity/swagger/Microservice.Sales.BalanceDebitTanks";
import SalesGasDeliverys from "./entity/swagger/Microservice.Sales.GasDeliverys";
import SalesGasReports from "./entity/swagger/Microservice.Sales.GasReports";
import SalesReturnTanks from "./entity/swagger/Microservice.Sales.ReturnTanks";
import SalesSaleContracts from "./entity/swagger/Microservice.Sales.SaleContracts";
import SmartRadioLiveDatas from "./entity/swagger/Microservice.SmartRadio.LiveDatas";
import SmartRadioSchedules from "./entity/swagger/Microservice.SmartRadio.RadioSchedules";
import SystemGuestComments from "./entity/swagger/System.GuestComments";
import TreeMaintenanceReport from "./entity/swagger/Microservice.TreeMaintenance.Report";
import WaterwayCabinetActions from "./entity/swagger/Microservice.Waterway.CabinetActions";
import WaterwayReports from "./entity/swagger/Microservice.Waterway.Reports";
import WimDatas from "./entity/swagger/Microservice.Wim.WimDatas";
import WimImages from "./entity/swagger/Microservice.Wim.WimImages";
import WindElectricityReports from "./entity/swagger/Microservice.WindElectricity.Reports";

const repositories = {
    "authCustomer": AuthenticationCustomer,
    "authDriver": AuthenticationDriver,
    "authSystem": AuthenticationSystem,
    "aquaChart": AquacultureCharts,
    "aquaReport": AquacultureReport,
    "authenticationGuest":AAuthenticationGuest,
    "busGetMapNow":BusGetMapNow,
    "cameraThermalReport": CameraThermalReports,
    "cateAquaStation": CategoryAquaStations,
    "cateBusCompanies": CategoryBusCompanies,
    "cateBusOwners": CategoryBusOwners,
    "cateBusRoutes": CategoryBusRoutes,
    "cateBusStops": CategoryBusStops,
    "cateBusTicketTypes": CategoryBusTicketTypes,
    "cateBusTicketPrices": CategoryBusTicketPrices,
    "cateBusVehicles": CategoryBusVehicles,
    "cateCameras": CategoryCameras,
    "cateCollectType": CategoryColectType,
    "cateColor": CategoryColor,
    "cateConnectType": CategoryConnectTypes,
    "cateCrowns": CategoryCrowns,
    "cateDataTypes": CategoryDataTypes,
    "cateDeviceInfos": CategoryDeviceInfos,
    "cateExcel": CategoryExcel,
    "cateFireLocationDetails": CategoryFireLocationDetails,
    "cateFireLocations": CategoryFireLocations,
    "cateFireNodes": CategoryFireNodes,
    "cateFireStations": CategoryFireStations,
    "cateFoodCourts": CategoryFoodCourts,
    "cateGasDetectorByStations": CategoryGasDetectorByStations,
    "cateGasItemGroups": CategoryGasItemGroups,
    "cateGasItems": CategoryGasItems,
    "cateGasTypes": CategoryGasTypes,
    "cateGasUnits": CategoryGasUnits,
    "cateIndicators": CategoryIndicators,
    "cateLands": CategoryLands,
    "cateLeafs": CategoryLeafs,
    "cateLedStations": CategoryLedStations,
    "cateMaintenanceArea": CategoryMaintenanceArea,
    "cateMaintenanceRequestType": CategoryMaintenanceRequestType,
    "cateManagementUnit": CategoryManagementUnit,
    "cateMonitorLocations": CategoryMonitorLocations,
    "catenPL": CategorynPL,
    "catenPLChargingStationDetails": CategorynPLChargingStationDetails,
    "catenPLCustomer": CategorynPLCustomer,
    "catenPLCustomerDetails": CategorynPLCustomersDetails,
    "catenPLDriveHelper": CategorynPLDriveHelper,
    "catenPLDriver": CategorynPLDriver,
    "catenPLExperts": CategorynPLExperts,
    "catenPLRegions": CategorynPLRegions,
    "catenPLRoutes": CategorynPLRoutes,
    "catenPLVehicles": CategorynPLVehicles,
    "catePositions": CategoryPositions,
    "catePowerTypes": CategoryPowerTypes,
    "cateRadioFMTransceiver": CategoryRadioFMTransceiver,
    "cateRadioRegions": CategoryRadioRegions,
    "cateRadioRelay": CategoryRadioRelay,
    "cateRadioStations": CategoryRadioStations,
    "cateRegions": CategoryRegions,
    "cateSLCabinets": CategorySLCabinets,
    "cateSLLamps": CategorySLLamps,
    "cateSLLightLevels": CategorySLLightLevels,
    "cateStationByGasFamilys": CategoryStationByGasFamilys,
    "cateStationByGasFoodCourts": CategoryStationByGasFoodCourts,
    "cateStationByGasRestaurents": CategoryStationByGasRestaurents,
    "cateStationByKioskCameras": CategoryStationByKioskCameras,
    "cateStationByThermalCameras": CategoryStationByThermalCameras,
    "cateStationDevices": CategoryStationDevices,
    "cateStationExtras": CategoryStationExtras,
    "cateStationImages": CategoryStationImages,
    "cateStationLinks": CategoryStationLinks,
    "cateStationNodes": CategoryStationNodes,
    "cateStations": CategoryStations,
    "cateStationSpeakers": CategoryStationSpeakers,
    "cateStatus": CategoryStatus,
    "cateStores": CategoryStores,
    "cateTreeCategories": CategoryTreeCategories,
    "cateTreeGroups": CategoryTreeGroups,
    "cateTreeRoots": CategoryTreeRoots,
    "cateTreeRoutes": CategoryTreeRoutes,
    "cateTrees": CategoryTrees,
    "cateTreeStatus": CategoryTreeStatus,
    "cateTreeTypes": CategoryTreeTypes,
    "cateUploads": CategoryUploads,
    "cateWaterwayBeacons": CategoryWaterwayBeacons,
    "cateWaterwayCabinets": CategoryWaterwayCabinets,
    "cateWaterwayClearanceHeights": CategoryWaterwayClearanceHeights,
    "cateWaterwayRoutes": CategoryWaterwayRoutes,
    "cateWaterwaySigns": CategoryWaterwaySigns,
    "cateWaterwaySignTypes": CategoryWaterwaySignTypes,
    "cateWaterwayStations": CategoryWaterwayStations,
    "cateWaterwayTypes": CategoryWaterwayTypes,
    "collectChart": CollectChart,
    "collectCollectData": CollectCollectData,
    "collectExcelProcessing": CollectExcelProcessing,
    "confAttachFiles": ConfigAttachFiles,
    "confBackups": ConfigBackups,
    "confCameraRight": ConfigCameraRight,
    "confCompany": ConfigCompany,
    "confCustomerGroups": ConfigCustomerGroups,
    "confCustomers": ConfigCustomers,
    "confCustomerUserGroups": ConfigCustomerUserGroups,
    "confEmployers": ConfigEmployers,
    "confEntrys": ConfigEntrys,
    "confFactors": ConfigFactors,
    "confFormPrints": ConfigFormPrints,
    "confGroupEmployers": ConfigGroupEmployers,
    "confImportDatas": ConfigImportDatas,
    "confMenu": ConfigMenu,
    "confMobileMenu": ConfigMobileMenu,
    "confSearch": ConfigSearch,
    "confShippers": ConfigShippers,
    "confStationRightByGroups": ConfigStationRight_ByGroups,
    "confSystem": ConfigSystem,
    "confUsers": ConfigUsers,
    "debitGasValuesFoodCourt": DebitGasValuesFoodCourt,
    "faceRecognitionBlackLists": FaceRecognitionBlackLists,
    "faceRecognitionFaceDatas": FaceRecognitionFaceDatas,
    "fireWarningReports": FireWarningReports,
    "gasFamilysReports": GasFamilysReports,
    "gasFoodCourtsReports": GasFoodCourtsReports,
    "gasRestaurantsReports": GasRestaurantsReports,
    "greeneriesMaintenanceRequests": GreeneriesMaintenanceRequests,
    "greeneryTreeDataCurrents": GreeneryTreeDataCurrents,
    "guestContacts": GuestContacts,
    "kioskReports": KioskReports,
    "lightingIndicatorWarnings": LightingIndicatorWarnings,
    "lightingLevelWarnings": LightingLevelWarnings,
    "lightingsCabinetActions": LightingsCabinetActions,
    "lightingsCabinetSchedules": LightingsCabinetSchedules,
    "lightingsCustomActions": LightingsCustomActions,
    "lightingsHistories": LightingsHistories,
    "lightingsMaintenanceErrors": LightingsMaintenanceErrors,
    "lightingsMaintenanceOthers": LightingsMaintenanceOthers,
    "lightingsMaintenances": LightingsMaintenances,
    "lightingsReports": LightingsReports,
    "lightingsSmartLedReport": LightingsSmartLedReport,
    "lightingsSmartLedSchedule": LightingsSmartLedSchedule,
    "mastCharts": MasterCharts,
    "mastDashboard": MasterDashboard,
    "mastMaps": MasterMaps,
    "mastMonitors": MasterMonitors,
    "mastNotification": MasterNotification,
    "mastNotifyConfigs": MasterNotifyConfigs,
    "mastNotifyRight": MasterNotifyRight,
    "mastNotifyTypes": MasterNotifyTypes,
    "mastTokenApps": MasterTokenApps,
    "mediasCommentDetails": MediasCommentDetails,
    "mediasComments": MediasComments,
    "mediasTreeComments": MediasTreeComments,
    "monitorIndicatorWarningByStations": MonitorIndicatorWarningByStations,
    "monitorIndicatorWarnings": MonitorIndicatorWarnings,
    "monitorLevelWarnings": MonitorLevelWarnings,
    "monitorReport": MonitorReport,
    "monitorStationLocationMaps": MonitorStationLocationMaps,
    "monitorStationRights": MonitorStationRights,
    "notiContracts": NotificationContacts,
    "notiCustomers": NotificationCustomers,
    "notiInternals": NotificationInternals,
    "nPLAdvanceDrivers": nPLAdvanceDrivers,
    "nPLAllowances": nPLAllowances,
    "nPLCustomerShippings": nPLCustomerShippings,
    "nPLDataBeginStartsVehicles": nPLDataBeginStartsVehicles,
    "nPLDebitPeriodOils": nPLDebitPeriodOils,
    "nPLDriverRequests": nPLDriverRequests,
    "nPLDriverSalarys": nPLDriverSalarys,
    "nPLDriverShippings": nPLDriverShippings,
    "nPLETCCosts": nPLETCCosts,
    "nPLGetMapNow": nPLGetMapNow,
    "nPLMaintenanceAllowances": nPLMaintenanceAllowances,
    "nPLMonthlyTickets": nPLMonthlyTickets,
    "nPLOilAllowances": nPLOilAllowances,
    "nPLProcessChangeTires": nPLProcessChangeTires,
    "nPLProcessDriverMaintenances": nPLProcessDriverMaintenances,
    "nPLProcessDriverRepairs": nPLProcessDriverRepairs,
    "nPLProcessRequestVehicles": nPLProcessRequestVehicles,
    "nPLProposedCreditLimits": nPLProposedCreditLimits,
    "nPLProposedRouteCosts": nPLProposedRouteCosts,
    "nPLProposedShippingPrices": nPLProposedShippingPrices,
    "nPLReceiveShipRequests": nPLReceiveShipRequests,
    "nPLRenewallDocs": nPLRenewallDocs,
    "nPLRequestVehicles": nPLRequestVehicles,
    "nPLRouteAllowances": nPLRouteAllowances,
    "nPLSaleContracts": nPLSaleContracts,
    "nPLShippingAndCreditnPL": nPLShippingAndCreditnPL,
    "nPLShippingIncurreds": nPLShippingIncurreds,
    "nPLShippings": nPLShippings,
    "SalesAdjustDebits": SalesAdjustDebits,
    "SalesAdjustDebits_FoodCourts": SalesAdjustDebits_FoodCourts,
    "SalesAdjustPrices": SalesAdjustPrices,
    "SalesBalanceDebits": SalesBalanceDebits,
    "SalesBalanceDebitTanks": SalesBalanceDebitTanks,
    "SalesGasDeliverys": SalesGasDeliverys,
    "SalesGasReports": SalesGasReports,
    "SalesReturnTanks": SalesReturnTanks,
    "SalesSaleContracts": SalesSaleContracts,
    "SmartRadioLiveDatas": SmartRadioLiveDatas,
    "SmartRadioSchedules": SmartRadioSchedules,
    "SystemGuestComments": SystemGuestComments,
    "TreeMaintenanceReport": TreeMaintenanceReport,
    "WaterwayCabinetActions": WaterwayCabinetActions,
    "WaterwayReports": WaterwayReports,
    "WimDatas": WimDatas,
    "WimImages": WimImages,
    "WindElectricityReports": WindElectricityReports,
}

const RepositoryFactory = {
    get: name => repositories[name]
}

export default RepositoryFactory
