import Repository from "../../repository";

export default {
    Get: (payload) => Repository.post(`/api/ProcessChangeTires/Get`, payload),
    GetTire: (payload) => Repository.post(`/api/ProcessChangeTires/GetTire`, payload),
    GetById: (payload) => Repository.post(`/api/ProcessChangeTires/GetById`, payload),
    Add: (payload) => Repository.post(`/api/ProcessChangeTires/Add`, payload),
    Edit: (payload) => Repository.post(`/api/ProcessChangeTires/Edit`, payload),
    Delete: (payload) => Repository.post(`/api/ProcessChangeTires/Delete`, payload),
    Copy: (payload) => Repository.post(`/api/ProcessChangeTires/Copy`, payload),
    ChangeStatus: (payload) => Repository.post(`/api/ProcessChangeTires/ChangeStatus`, payload),
}
