<template>
  <div >
    <b-carousel
      v-if="isShow"
      id="custom-carousel-component"
      v-model="slide"
      :interval="autoplay"
      controls
      @sliding-start="onSlideStart"
      @sliding-end="onSlideEnd"
    >
      <template v-for="(item, key) in listImage">
        <template v-if="item.Image">
          <b-carousel-slide :key="key">
            <!-- <b-button   @click="handleRotate(-90,item.Image)">
              <i class="fas fa-undo-alt"></i>
            </b-button>
            <b-button  @click="handleRotate(90,item.Image)">
              <i class="fas fa-undo fa-flip-horizontal"></i>
            </b-button> -->
            <!-- <p class="mb-4">Những cây xanh trong công viên</p> -->
            <template #img>
              <div :id="`zoom-img-${item.Image}`"
                  @wheel="handleScroll($event,item.Image)"
                  @mousedown="startPan"
                  @mousemove="panImage($event,item.Image)"
                  @mouseup="endPan"
                  @mouseleave="endPan"
                  @touchstart="startPan"
                  @touchmove="panImage($event,item.Image)"
                  @touchend="endPan"
                  style=" width: auto;
                        height: 100vh;
                        transform-origin: 0px 0px;
                        transform: scale(1) translate(0px, 0px);
                        cursor: grab;
                        margin-bottom: 100px;"
                  >
                <b-img
                  ref="imagezoom"
                  :key="key"
                  :id="item.ID"
                  :class="{ 'img-slide-active': key === slide }"
                  :src="item.Image"
                  :alt="'image-wallpaper-' + key"
                  center
                  fluid
                  style="height: 100%; transform:  scale(1);"
                  :style="`rotate:${currentImg===item.Image ? imgAngle : 0 }deg;`"
                />
              </div>
            </template>
          </b-carousel-slide>
        </template>
        <template v-else-if="item.Images">
          <b-carousel-slide :key="key">
            <template #img>
              <b-img
                ref="imagezoom"
                :key="key"
                :id="item.ID"
                :class="{ 'img-slide-active': key === slide }"
                :src="item.Images"
                :alt="'image-wallpaper-' + key"
                center
                fluid
                style="height: 100vh; "
                :style="`rotate:${imgAngle}deg`"
              />
            </template>
          </b-carousel-slide>
        </template>
      </template>
    </b-carousel>
  </div>
</template>

<script>
export default {
  name: 'BCarouselComponent',
  // props: ['listImage'],
  props: {
    listImage:{
      type: Array,
      default: () => []
    },
    // imgAngle: {
    //   type: Number,
    //   default: 0
    // }
    resetSlide: {
      type: Boolean,
      default: false
    },
    slideindex:{
      type: Number,
      default: 0
    }
  },
  mounted() {
    document.addEventListener('keyup', this.handleEscKey);
  },
  beforeDestroy() {
    document.removeEventListener('keyup', this.handleEscKey);
  },
  watch:{
    slideindex(to){
      this.isShow = false
        this.slide = to
        setTimeout(()=>{
          this.isShow = true
        },100)
      
    }
  },
  data() {
    return {
      slide: 0,
      sliding: false,
      autoplay: 60000,
      scale: 1,
      scaleStep: 0.1,
      posX: 0, // Vị trí X ban đầu
      posY: 0, // Vị trí Y ban đầu
      isPanning: false, // Biến xác định trạng thái kéo chuột
      startMouseX: 0, // Tọa độ X khi bắt đầu kéo
      startMouseY: 0, // Tọa độ Y khi bắt đầu kéo
      zoomOrigin: 'center center', // Vị trí gốc của zoom,
      imgAngle: 0,
      currentImg: 0,
      isShow: true
    }
  },
  methods: {
    handleEscKey(event) {
      if (event.keyCode === 27) {
        // Xử lý logic khi nhấn nút Esc ở đây
        // Ví dụ: đóng modal, hủy thao tác, vv.
        this.$emit('closeSlide')
      }
    },
    // closeSlide(event){
    //   let x = event.keyCode;
    //   if (x == 27) {
    //     alert ("You pressed the Escape key!");
    //   }
    // },
    setTransform(id){
      let zoom = document.getElementById(`zoom-img-${id}`)
      zoom.style.transform = "translate(" + this.posX + "px, " + this.posY + "px) scale(" + this.scale + ")";
    },
    onSlideStart(slide) {
      // this.sliding = true
    },
    onSlideEnd(slide) {
      // this.sliding = false
    },
    handleScroll(event,index){
      // event.preventDefault();

      // // Xác định hướng cuộn
      // const zoomIn = event.deltaY < 0;

      // // Điều chỉnh giá trị scale dựa vào hướng cuộn
      // if (zoomIn) {
      //   this.scale += this.scaleStep;
      // } else {
      //   this.scale -= this.scaleStep;
      // }

      // // Giới hạn giá trị scale để không cho nó quá nhỏ hoặc quá lớn
      // this.scale = Math.min(Math.max(0.1, this.scale), 3);

      // // Lấy vị trí chuột trên hình ảnh
      // const { clientX, clientY } = event;
      // const { left, top, width, height } = this.$refs.imagezoom.getBoundingClientRect();
      // const mouseX = clientX - left;
      // const mouseY = clientY - top;

      // // Tính toán vị trí mới của chuột trên hình ảnh sau khi zoom
      // const newMouseX = mouseX * (this.scale / (this.scale - this.scaleStep));
      // const newMouseY = mouseY * (this.scale / (this.scale - this.scaleStep));

      // // Tính toán giá trị transform-origin để giữ vị trí chuột không đổi sau khi zoom
      // this.zoomOrigin = `${(newMouseX / width) * 100}% ${(newMouseY / height) * 100}%`;
      event.preventDefault();
        let xs = (event.clientX - this.posX) / this.scale,
          ys = (event.clientY - this.posY) / this.scale,
          delta = (event.wheelDelta ? event.wheelDelta : -event.deltaY);
        (delta > 0) ? (this.scale *= 1.2) : (this.scale /= 1.2);
        this.posX = event.clientX - xs * this.scale;
        this.posY = event.clientY - ys * this.scale;
        this.setTransform(index)
    },
    startPan(event) {
      event.preventDefault();
      this.isPanning = true;

      // Xác định tọa độ chuột khi bắt đầu kéo
      // this.startMouseX = event.clientX || event.touches[0].clientX;
      // this.startMouseY = event.clientY || event.touches[0].clientY;
      this.startMouseX = event.clientX - this.posX
      this.startMouseY = event.clientY - this.posY
    },
    panImage(event,index) {
      event.preventDefault();

      if (this.isPanning) {
        // Xác định tọa độ chuột khi di chuyển
        // const currentMouseX = event.clientX || event.touches[0].clientX;
        // const currentMouseY = event.clientY || event.touches[0].clientY;

        // // Tính toán khoảng cách di chuyển
        // const deltaX = currentMouseX - this.startMouseX;
        // const deltaY = currentMouseY - this.startMouseY;

        // // Cập nhật vị trí mới của hình ảnh
        // this.posX += deltaX;
        // this.posY += deltaY;

        // // Lưu tọa độ chuột hiện tại để sử dụng cho lần di chuyển tiếp theo
        // this.startMouseX = currentMouseX;
        // this.startMouseY = currentMouseY;
        this.posX = (event.clientX - this.startMouseX);
        this.posY = (event.clientY - this.startMouseY);
        this.setTransform(index)
      }
    },
    endPan() {
      this.isPanning = false;
    },
    handleRotate(angle,id){
      if(this.currentImg!=0 && this.currentImg!==id)  
      {
        this.imgAngle = angle
      }
      else{
        this.imgAngle = this.imgAngle + angle
      }
      
     
      this.currentImg = id
    }
  },
}
</script>

<style scoped>
#zoom-img {
  width: 100%;
  height: 100%;
  transform-origin: 0px 0px;
  transform: scale(1) translate(0px, 0px);
  cursor: grab;
}
#custom-carousel-component {
  position: absolute;
  height: auto;
  width: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>
