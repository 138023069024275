import Repository from "../../repository";

export default {
    Get: (payload) => Repository.post(`/api/DriverRequests/Get`, payload),
    GetByDriver: (payload) => Repository.post(`/api/DriverRequests/GetByDriver`, payload),
    GetById: (payload) => Repository.post(`/api/DriverRequests/GetById`, payload),
    Add: (payload) => Repository.post(`/api/DriverRequests/Add`, payload),
    Edit: (payload) => Repository.post(`/api/DriverRequests/Edit`, payload),
    Approval: (payload) => Repository.post(`/api/DriverRequests/Approval`, payload),
    Cancel: (payload) => Repository.post(`/api/DriverRequests/Cancel`, payload),
    Reject: (payload) => Repository.post(`/api/DriverRequests/Reject`, payload),
    Delete: (payload) => Repository.post(`/api/DriverRequests/Delete`, payload),
}
