import Repository from "../../repository";

export default {
    GetReportTreeMaintenance: (payload) => Repository.post(`/api/ReportTreeMaintenance/GetReportTreeMaintenance`, payload),
    GetReportTreeCategories: (payload) => Repository.post(`/api/ReportTreeMaintenance/GetReportTreeCategories`, payload),
    GetReportTreeGroups: (payload) => Repository.post(`/api/ReportTreeMaintenance/GetReportTreeGroups`, payload),
    GetReportTreeStatus: (payload) => Repository.post(`/api/ReportTreeMaintenance/GetReportTreeStatus`, payload),
    GetReportTreeTypes: (payload) => Repository.post(`/api/ReportTreeMaintenance/GetReportTreeTypes`, payload),
    GetReportTreeRoute: (payload) => Repository.post(`/api/ReportTreeMaintenance/GetReportTreeRoute`, payload),
    GetReportManagementUnit: (payload) => Repository.post(`/api/ReportTreeMaintenance/GetReportManagementUnit`, payload),
}
