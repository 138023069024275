import Repository from "../../repository";

export default {
    Get: (payload) => Repository.post(`/api/MaintenanceRequestType/Get`, payload),
    GetActive: (payload) => Repository.post(`/api/MaintenanceRequestType/GetActive`, payload),
    GetByID: (payload) => Repository.post(`/api/MaintenanceRequestType/GetByID`, payload),
    Add: (payload) => Repository.post(`/api/MaintenanceRequestType/Add`, payload),
    Edit: (payload) => Repository.post(`/api/MaintenanceRequestType/Edit`, payload),
    Delete: (payload) => Repository.post(`/api/MaintenanceRequestType/Delete`, payload),
    ChangeStatus: (payload) => Repository.post(`/api/MaintenanceRequestType/ChangeStatus`, payload),
}
