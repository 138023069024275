import Repository from "../../repository";

export default {
    Get: (payload) => Repository.post(`/api/Allowances/Get`, payload),
    GetById: (payload) => Repository.post(`/api/Allowances/GetById`, payload),
    Add: (payload) => Repository.post(`/api/Allowances/Add`, payload),
    Copy: (payload) => Repository.post(`/api/Allowances/Copy`, payload),
    Edit: (payload) => Repository.post(`/api/Allowances/Edit`, payload),
    Delete: (payload) => Repository.post(`/api/Allowances/Delete`, payload),
    ChangeStatus: (payload) => Repository.post(`/api/Allowances/ChangeStatus`, payload),
}