import Repository from "../../repository";

export default {
    Get: (payload) => Repository.post(`/api/FireNodes/Get`, payload),
    GetTypeID: (payload) => Repository.post(`/api/FireNodes/GetTypeID`, payload),
    GetByLocationID: (payload) => Repository.post(`/api/FireNodes/GetByLocationID`, payload),
    GetDeploy: (payload) => Repository.post(`/api/FireNodes/GetDeploy`, payload),
    GetActive: (payload) => Repository.post(`/api/FireNodes/GetActive`, payload),
    GetById: (payload) => Repository.post(`/api/FireNodes/GetById`, payload),
    EditDeploy: (payload) => Repository.post(`/api/FireNodes/EditDeploy`, payload),
    Export: (payload) => Repository.post(`/api/FireNodes/Export`, payload),
    Edit: (payload) => Repository.post(`/api/FireNodes/Edit`, payload),
    Delete: (payload) => Repository.post(`/api/FireNodes/Delete`, payload),
    ChangeStatus: (payload) => Repository.post(`/api/FireNodes/ChangeStatus`, payload),
    EditLocation: (payload) => Repository.post(`/api/FireNodes/EditLocation`, payload),
    DelLocation: (payload) => Repository.post(`/api/FireNodes/DelLocation`, payload),
}

