<template>
	<div>
		<b-modal id="forget-modal" centered content-class="modal-radius-forget" hide-footer scrollable size="md" no-fade v-model="showModal">
			<template #modal-header="{close}">
				<div class="d-flex align-items-center w-100">
					<p class="modal-title">Quên mật khẩu</p>
					<img src="/assets/image/icon/x-close.svg" class="ml-auto" @click="close" style="cursor: pointer" />
				</div>
			</template>
			<template v-if="step === 0">
				<b-form @submit="onSubmit(1, $event)">
					<b-form-group class="mb-3" id="fieldset-1" label="Nhập email khôi phục mật khẩu" label-for="input-1" label-class="lable-class">
						<b-form-input id="input-1" placeholder="Nhập email của bạn" v-model="email"></b-form-input>
					</b-form-group>
					<b-button class="w-100 login-btn" type="submit">Tiếp tục</b-button>
				</b-form>
			</template>
			<template v-else-if="step === 1">
				<b-form @submit="onSubmit(2, $event)">
					<b-form-group class="mb-3" id="fieldset-1" label="Nhập mã xác thực" label-for="input-1" label-class="lable-class">
						<b-form-input id="input-1" placeholder="Nhập mã xác thực của bạn" v-model="code"></b-form-input>
					</b-form-group>
					<b-form-group class="mb-3" id="fieldset-1" label="Mật khẩu mới" label-for="input-1" label-class="lable-class">
						<div class="input-group mb-3">
							<b-form-input id="input-1" :type="`${showPass ? 'text' : 'password'}`" placeholder="Nhập mật khẩu mới của bạn" v-model="newPass"></b-form-input>
							<div class="input-group-append" style="height: 40px">
								<span class="input-group-text py-0" style="cursor: pointer" @click="showPass = !showPass">
									<i v-if="showPass" class="fas fa-eye"></i>
									<i v-else class="fas fa-eye-slash"></i>
								</span>
							</div>
						</div>
					</b-form-group>
					<b-form-group class="mb-3" id="fieldset-1" label="Xác nhận mật khẩu mới" label-for="input-1" label-class="lable-class">
						<div class="input-group mb-3">
							<b-form-input id="input-1" :type="`${showPassRe ? 'text' : 'password'}`" placeholder="Nhập lại mật khẩu mới của bạn"></b-form-input>
							<div class="input-group-append" style="height: 40px">
								<span class="input-group-text py-0" style="cursor: pointer" @click="showPassRe = !showPassRe">
									<i v-if="showPassRe" class="fas fa-eye"></i>
									<i v-else class="fas fa-eye-slash"></i>
								</span>
							</div>
						</div>
					</b-form-group>
					<b-button class="w-100 login-btn" type="submit">Lưu</b-button>
					<p class="mention w-100 text-center mt-2 mb-3">
						Mã xác thực có thời gian trong
						<span class="attribute">5:00</span>
						phút
						<span class="attribute">Gửi lại mã</span>
					</p>
				</b-form>
			</template>
			<template v-else-if="2">
				<div class="text-center">
					<img src="assets/image/Confirmed-rafiki 1.svg" />
					<p class="mt-3 mb-1 content-main"><strong>Chúc mừng!</strong></p>
					<p class="content m-0">Mật khẩu đã được khôi phục</p>
					<b-button class="w-100 login-btn" type="submit" @click="openLogin"> Trở về phần đăng nhập </b-button>
				</div>
			</template>
		</b-modal>
	</div>
</template>

<script>
import RepositoryFactory from "@/repository/factory";
const authenticationGuest = RepositoryFactory.get("authenticationGuest");
export default {
	props: {
		isShow: {
			type: Boolean,
			default: false,
		},
	},
	watch: {
		isShow(to) {
			if (to === false) {
				this.$emit("close");
				this.$bvModal.hide("forget-modal");
				this.step = 0;
			} else {
				this.$bvModal.show("forget-modal");
			}
		},
		showModal(to) {
			if (to === false) {
				this.$emit("close");
				this.$bvModal.hide("forget-modal");
			} else {
				this.$bvModal.show("forget-modal");
			}
		},
	},
	data() {
		return {
			showModal: false,
			step: 0,
			email: null,
			code: null,
			newPass: null,
			showPass: false,
			showPassRe: false,
		};
	},
	methods: {
		async onSubmit(value, event) {
			event.preventDefault();
			if (value === 1) {
				let body = {
					Email: this.email,
				};
				let res = await authenticationGuest.SendCodeForgetPassword(body);
				this.step = value;
			} else if (value === 2) {
				let body = {
					Email: this.email,
					SecurityCode: this.code,
					PassNew: this.newPass,
				};
				let res = await authenticationGuest.ChangePasswordBySecurityCode(body);
				this.step = value;
			}
		},
		openLogin() {
			this.$bvModal.hide("forget-modal");
			this.$emit("openLogin");
		},
	},
};
</script>

<style>
.modal-radius-forget {
	border-radius: 16px !important;
	/* height: 470px; */
}
</style>
<style scoped>
.content-main {
	color: var(--text-content-secondary-day, #27272a);
	text-align: center;

	/* Medium/16px - Bold */
	font-size: 16px;
	font-weight: 700;
	line-height: 150%; /* 24px */
}
.content {
	color: var(--text-content-secondary-day, #27272a);
	text-align: center;
	font-size: 14px;
	font-weight: 400;
	line-height: 150%; /* 21px */
}
.login-btn {
	border-radius: 8px !important;
	background: var(--brand-color, #029834) !important;
}
.modal-title {
	color: var(--brand-color, #029834);

	/* X-Large/20px - Semi Bold */
	font-size: 20px;
	font-weight: 600;
	line-height: 150%; /* 30px */
}
.mention {
	color: var(--label-main-text-default, #27272a);

	/* Base/14px - Regular */
	font-size: 14px;
	font-weight: 400;
	line-height: 150%; /* 21px */
}
.attribute {
	color: var(--brand-color, #029834);
	cursor: pointer;
	/* Small/12px - Regular */
	font-size: 14px;
	font-weight: 400;
	line-height: 150%; /* 18px */
}
</style>
