export const vapidKey_v1 = "BOJzrEu-3CUWUaSBF_LlNlahQl846xr4_NPdH-8ndcTGqE7QBfOx3nIgoCQohiwqzb5VmdhXxrlfIT7LLAW9dMU";
/** Load region default **/
export const listURL = {
    MobileDEV: {
        id: 13,
        urlAPI: "https://mobiledev2.namlongtekgroup.com",
        urlImage: "https://imgsmartcity.namlongtekgroup.com/",
        defaultMap: {
            url: "https://{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}",
            // url: "https://thuduc-maps.hcmgis.vn/thuducserver/gwc/service/wmts?layer=thuduc:thuduc_maps&style=&tilematrixset=EPSG:900913&Service=WMTS&Request=GetTile&Version=1.0.0&Format=image/png&TileMatrix=EPSG:900913:{z}&TileCol={x}&TileRow={y}",
            min: 1,
            max: 21,
            zoom: 13
        },
        vapidKey: vapidKey_v1,
        server: "SXDCT",
    },
}
