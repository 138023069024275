import Repository from "../../repository";

export default {
    Get: (payload) => Repository.post(`/api/Comments/Get`, payload),
    GetByCustomer: (payload) => Repository.post(`/api/Comments/GetByCustomer`, payload),
    GetTitle: (payload) => Repository.post(`/api/Comments/GetTitle`, payload),
    GetById: (payload) => Repository.post(`/api/Comments/GetById`, payload),
    Add: (payload) => Repository.post(`/api/Comments/Add`, payload),
    Edit: (payload) => Repository.post(`/api/Comments/Edit`, payload),
    EditResult: (payload) => Repository.post(`/api/Comments/EditResult`, payload),
    DeleteComment: (payload) => Repository.post(`/api/Comments/DeleteComment`, payload),
}
