import { listURL } from '@/constants/url';
/**
 * Set default type menu (sidebar)
 */
export const defaultMenuType = "menu-default" // "menu-default", "menu-sub-hidden", "menu-hidden"
export const defaultPath = process.env.VUE_APP_DEFAULT_URL;

/**
 * Set default load map && forecast
 */
export const defaultRegionCode = "CanTho"
export const defaultRegionWMS = "CanTho"
export const releaseCode = process.env.VUE_APP_SERVICE_RELEASE;

/**
 * Set default router && project deploy
 */
export const urlImage = listURL[releaseCode].urlImage
export const defaultMap = listURL[releaseCode].defaultMap
export const vapidKey = listURL[releaseCode].vapidKey
export const apiUrl = listURL[releaseCode].urlAPI
export const server = listURL[releaseCode].server
//OTHER==================================================
export const timer = 500;

export const subHiddenBreakpoint = 1440
export const menuHiddenBreakpoint = 768

export const defaultLocale = "vn" // vn, en
export const defaultDirection = "ltr"

export const firebaseConfig = {
  apiKey: "AIzaSyBHi0LFaSe7TfMtCWhlLVFGNktd6JJ0QgI",
  authDomain: "nlt-smarts-2946d.firebaseapp.com",
  projectId: "nlt-smarts-2946d",
  storageBucket: "nlt-smarts-2946d.appspot.com",
  messagingSenderId: "409637778044",
  appId: "1:409637778044:web:60d1cc301250f34b5e4ff9",
  measurementId: "G-SF4NMMXYCN"
};

export const themeRadiusStorageKey = "theme_radius"
export const defaultColor = "light.blue"
export const colors = [
  "light.purple",
  "dark.purple",
  "light.blue",
  "dark.blue",
  "light.green",
  "dark.green",
  "light.orange",
  "dark.orange",
  "light.red",
  "dark.red"
]
