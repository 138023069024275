<template>
  <div id="app">
    <router-view name="header" />
    <div class="wrapper">
      <router-view />
    </div>
    <router-view name="totop" />
    <router-view name="footer" />
  </div>
</template>
<script>
import { mapActions } from "vuex";
export default {
  beforeMount() {
    // const direction = getDirection();
    // if (direction.isRtl) {
    //   document.body.classList.add("rtl");
    //   document.dir = "rtl";
    //   document.body.classList.remove("ltr");
    // } else {
    //   document.body.classList.add("ltr");
    //   document.dir = "ltr";
    //   document.body.classList.remove("rtl");
    // }
  },
  mounted() {
    // Gọi hàm handleResize ban đầu để cập nhật chiều cao ban đầu
    // this.handleResize();
    // Đăng ký sự kiện resize và gọi hàm handleResize
    window.addEventListener("resize", this.handleResize);

    // document.addEventListener("contextmenu", function (event) {
    //     // Hủy bỏ sự kiện contextmenu
    //     event.preventDefault();
    // });
  },
  beforeDestroy() {
    // Hủy đăng ký sự kiện resize khi component bị hủy
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    ...mapActions(["setHeightWindow", "setWidthWindow"]),
    handleResize() {
      // Lấy chiều cao của cửa sổ trình duyệt
      const windowHeight = window.innerHeight;
      const windowWidth = window.innerWidth;
      // Lưu chiều cao, chiều rộng vào store Vuex
      // this.setHeightWindow(windowHeight);
      // this.setWidthWindow(windowWidth);
    },
  },
};
</script>
