import Repository from "../../repository";

export default {
    GetListLeaf: (payload) => Repository.post(`/api/Leafs/GetListLeaf`, payload),
    GetLeafListActive: (payload) => Repository.post(`/api/Leafs/GetLeafListActive`, payload),
    GetLeafById: (payload) => Repository.post(`/api/Leafs/GetLeafById`, payload),
    Add: (payload) => Repository.post(`/api/Leafs/Add`, payload),
    Edit: (payload) => Repository.post(`/api/Leafs/Edit`, payload),
    Delete: (payload) => Repository.post(`/api/Leafs/Delete`, payload),
    ChangeStatus: (payload) => Repository.post(`/api/Leafs/ChangeStatus`, payload),
}