import Repository from "../../repository";

export default {
    Get: (payload) => Repository.post(`/api/Notify/Get`, payload),
    GetTop30: (payload) => Repository.post(`/api/Notify/GetTop30`, payload),
    GetTotal: (payload) => Repository.post(`/api/Notify/GetTotal`, payload),
    UpdateView: (payload) => Repository.post(`/api/Notify/UpdateView`, payload),
    GetVer2: (payload) => Repository.post(`/api/NotifyVer2/Get`, payload),
    GetTop20Ver2: (payload) => Repository.post(`/api/NotifyVer2/GetTop20`, payload),
    GetTotalVer2: (payload) => Repository.post(`/api/NotifyVer2/GetTotal`, payload),
    UpdateViewVer2: (payload) => Repository.post(`/api/NotifyVer2/UpdateView`, payload),
    GetByEmployerIDVer2: (payload) => Repository.post(`/api/NotifyVer2/GetByEmployerID`, payload),
    GetTop20ByEmployerIDVer2: (payload) => Repository.post(`/api/NotifyVer2/GetTop20ByEmployerID`, payload),
    GetTotalByEmployerIDVer2: (payload) => Repository.post(`/api/NotifyVer2/GetTotalByEmployerID`, payload),
    GetByCustomerIDVer2: (payload) => Repository.post(`/api/NotifyVer2/GetByCustomerID`, payload),
    GetTop20ByCustomerIDVer2: (payload) => Repository.post(`/api/NotifyVer2/GetTop20ByCustomerID`, payload),
    GetTotalByCustomerIDVer2: (payload) => Repository.post(`/api/NotifyVer2/GetTotalByCustomerID`, payload),
    GetByShipperIDVer2: (payload) => Repository.post(`/api/NotifyVer2/GetByShipperID`, payload),
    GetTop20ByShipperIDVer2: (payload) => Repository.post(`/api/NotifyVer2/GetTop20ByShipperID`, payload),
    GetTotalByShipperIDVer2: (payload) => Repository.post(`/api/NotifyVer2/GetTotalByShipperID`, payload),
}
