import Repository from "../../repository";

export default {
    Get: (payload) => Repository.post(`/api/Stores/Get`, payload),
    GetActive: (payload) => Repository.post(`/api/Stores/Get`, payload),
    GetById: (payload) => Repository.post(`/api/Stores/GetById`, payload),
    Add: (payload) => Repository.post(`/api/Stores/Add`, payload),
    Edit: (payload) => Repository.post(`/api/Stores/Edit`, payload),
    Delete: (payload) => Repository.post(`/api/Stores/Delete`, payload),
    GetStoreByUser: (payload) => Repository.post(`/api/Stores/GetStoreByUser`, payload),
    GetEmployeeByStore: (payload) => Repository.post(`/api/Stores/GetEmployeeByStore`, payload),
    GetImagesByStore: (payload) => Repository.post(`/api/Stores/GetImagesByStore`, payload),
    UploadImages: (payload) => Repository.post(`/api/Stores/UploadImages`, payload),
}
