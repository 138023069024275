import Repository from "../../repository";

export default {
    GetListPowerType: (payload) => Repository.post(`/api/PowerTypes/GetListPowerType`, payload),
    GetListPowerTypeActive: (payload) => Repository.post(`/api/PowerTypes/GetListPowerTypeActive`, payload),
    GetPowerTypeById: (payload) => Repository.post(`/api/PowerTypes/GetPowerTypeById`, payload),
    AddPowerType: (payload) => Repository.post(`/api/PowerTypes/AddPowerType`, payload),
    EditPowerType: (payload) => Repository.post(`/api/PowerTypes/EditPowerType`, payload),
    DeletePowerType: (payload) => Repository.post(`/api/PowerTypes/DeletePowerType`, payload),
    ChangeStatusPowerType: (payload) => Repository.post(`/api/PowerTypes/ChangeStatusPowerType`, payload),
}
