import Repository from "../../repository";

export default {
    Get: (payload) => Repository.post(`/api/ProcessDriverRepairs/Get`, payload),
    GetRescueInfo: (payload) => Repository.post(`/api/ProcessDriverRepairs/GetRescueInfo`, payload),
    GetRescueInfoByOID: (payload) => Repository.post(`/api/ProcessDriverRepairs/GetRescueInfoByOID`, payload),
    GetById: (payload) => Repository.post(`/api/ProcessDriverRepairs/GetById`, payload),
    EditComplete: (payload) => Repository.post(`/api/ProcessDriverRepairs/EditComplete`, payload),
    EditReceive: (payload) => Repository.post(`/api/ProcessDriverRepairs/EditReceive`, payload),
    Edit: (payload) => Repository.post(`/api/ProcessDriverRepairs/Edit`, payload),
    ProcessReceive: (payload) => Repository.post(`/api/ProcessDriverRepairs/ProcessReceive`, payload),
    ProcessComplete: (payload) => Repository.post(`/api/ProcessDriverRepairs/ProcessComplete`, payload),
}
