import Repository from "../../repository";

export default {
    Get: (payload) => Repository.post(`/api/MaintenanceRequests/Get`, payload),
    GetById: (payload) => Repository.post(`/api/MaintenanceRequests/GetById`, payload),
    GetByDetailID: (payload) => Repository.post(`/api/MaintenanceRequests/GetByDetailID`, payload),
    Add: (payload) => Repository.post(`/api/MaintenanceRequests/Add`, payload),
    Edit: (payload) => Repository.post(`/api/MaintenanceRequests/Edit`, payload),
    Delete: (payload) => Repository.post(`/api/MaintenanceRequests/Delete`, payload),
    Confirm: (payload) => Repository.post(`/api/MaintenanceRequests/Confirm`, payload),
    Complete: (payload) => Repository.post(`/api/MaintenanceRequests/Complete`, payload),
    Commit: (payload) => Repository.post(`/api/MaintenanceRequests/Commit`, payload),
    Return: (payload) => Repository.post(`/api/MaintenanceRequests/Return`, payload),
    Close: (payload) => Repository.post(`/api/MaintenanceRequests/Close`, payload),
}
