import Repository from "../../repository";

export default {
    Get: (payload) => Repository.post(`/api/FireLocations/Get`, payload),
    GetActive: (payload) => Repository.post(`/api/FireLocations/GetActive`, payload),
    GetType1: (payload) => Repository.post(`/api/FireLocations/GetApartment`, payload),
    GetType2: (payload) => Repository.post(`/api/FireLocations/GetForReport`, payload),
    GetById: (payload) => Repository.post(`/api/FireLocations/GetById`, payload),
    Add: (payload) => Repository.post(`/api/FireLocations/Add`, payload),
    Edit: (payload) => Repository.post(`/api/FireLocations/Edit`, payload),
    Delete: (payload) => Repository.post(`/api/FireLocations/Delete`, payload),
    ChangeStatus: (payload) => Repository.post(`/api/FireLocations/ChangeStatus`, payload),
}