import Repository from "../../repository";

export default {
    GetListTree: (payload) => Repository.post(`/api/Trees/GetListTree`, payload),
    GetListTreeActive: (payload) => Repository.post(`/api/Trees/GetListTreeActive`, payload),
    GetTreeById: (payload) => Repository.post(`/api/Trees/GetTreeById`, payload),
    Add: (payload) => Repository.post(`/api/Trees/Add`, payload),
    Edit: (payload) => Repository.post(`/api/Trees/Edit`, payload),
    Delete: (payload) => Repository.post(`/api/Trees/Delete`, payload),
    ChangeStatus: (payload) => Repository.post(`/api/Trees/ChangeStatus`, payload),
}
