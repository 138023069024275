import Repository from "../../repository";

export default {
    Get: (payload) => Repository.post(`/api/AttachFiles/Get`, payload),
    Add: (payload) => Repository.post(`/api/AttachFiles/Add`, payload),
    AddFileCont: (payload) => Repository.post(`/api/AttachFiles/AddFileCont`, payload),
    AddFileDriver: (payload) => Repository.post(`/api/AttachFiles/AddFileDriver`, payload),
    Edit: (payload) => Repository.post(`/api/AttachFiles/Edit`, payload),
    Delete: (payload) => Repository.post(`/api/AttachFiles/Delete`, payload),
}
