import Repository from "../../repository";

export default {
    GetGroupEmployerList: (payload) => Repository.post(`/api/GroupEmployers/GetGroupEmployerList`, payload),
    GetGroupEmployerById: (payload) => Repository.post(`/api/GroupEmployers/GetGroupEmployerById`, payload),
    GetGroupEmployerActive: (payload) => Repository.post(`/api/GroupEmployers/GetGroupEmployerActive`, payload),
    Add: (payload) => Repository.post(`/api/GroupEmployers/Add`, payload),
    Edit: (payload) => Repository.post(`/api/GroupEmployers/Edit`, payload),
    Delete: (payload) => Repository.post(`/api/GroupEmployers/Delete`, payload),
    ChangeStatus: (payload) => Repository.post(`/api/GroupEmployers/ChangeStatus`, payload),
}