import Repository from "../../repository";

export default {
    Get: (payload) => Repository.post(`/api/ProposedRouteCosts/Get`, payload),
    GetActive: (payload) => Repository.post(`/api/ProposedRouteCosts/GetActive`, payload),
    GetById: (payload) => Repository.post(`/api/ProposedRouteCosts/GetById`, payload),
    Add: (payload) => Repository.post(`/api/ProposedRouteCosts/Add`, payload),
    Edit: (payload) => Repository.post(`/api/ProposedRouteCosts/Edit`, payload),
    Delete: (payload) => Repository.post(`/api/ProposedRouteCosts/Delete`, payload),
    ChangeStatus: (payload) => Repository.post(`/api/ProposedRouteCosts/ChangeStatus`, payload),
}