import Repository from "../../repository";

export default {
    GetListTreeCategory: (payload) => Repository.post(`/api/TreeCategories/GetListTreeCategory`, payload),
    GetListTreeCategoryActive: (payload) => Repository.post(`/api/TreeCategories/GetListTreeCategoryActive`, payload),
    GetTreeCategoryById: (payload) => Repository.post(`/api/TreeCategories/GetTreeCategoryById`, payload),
    Add: (payload) => Repository.post(`/api/TreeCategories/Add`, payload),
    Edit: (payload) => Repository.post(`/api/TreeCategories/Edit`, payload),
    Delete: (payload) => Repository.post(`/api/TreeCategories/Delete`, payload),
    ChangeStatus: (payload) => Repository.post(`/api/TreeCategories/ChangeStatus`, payload),
}
