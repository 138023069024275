import Repository from "../../repository";

export default {
    LoginUser: (payload) => Repository.post(`/api/authentication/LoginUser`, payload),
    LoginUserCmpnID: (payload) => Repository.post(`/api/authentication/LoginUserCmpnID`, payload),
    LoginEmployer: (payload) => Repository.post(`/api/authentication/LoginEmployer`, payload),
    LoginCustomer: (payload) => Repository.post(`/api/authentication/LoginCustomer`, payload),
    RefreshToken: (payload) => Repository.post(`/api/authentication/RefreshToken`, payload),
    CheckToken: (payload) => Repository.post(`/api/authentication/CheckToken`, payload),
    ChangePasswordUser: (payload) => Repository.post(`/api/authentication/ChangePasswordUser`, payload),
    ChangePasswordEmployer: (payload) => Repository.post(`/api/authentication/ChangePasswordEmployer`, payload),
    ChangePasswordCustomer: (payload) => Repository.post(`/api/authentication/ChangePasswordCustomer`, payload),
    SendCodeForgetPassword: (payload) => Repository.post(`/api/authentication/SendCodeForgetPassword`, payload),
    ChangePasswordBySecurityCode: (payload) => Repository.post(`/api/authentication/ChangePasswordBySecurityCode`, payload),
    CheckSecurityByEmployerID: (payload) => Repository.post(`/api/authentication/CheckSecurityByEmployerID`, payload),
}