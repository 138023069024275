import Repository from "../../repository";

export default {
    GetListIndicatorWarning: (payload) => Repository.post(`/api/IndicatorWarnings/GetListIndicatorWarning`, payload),
    GetListIndicatorWarningActive: (payload) => Repository.post(`/api/IndicatorWarnings/GetListIndicatorWarningActive`, payload),
    GetIndicatorWarningById: (payload) => Repository.post(`/api/IndicatorWarnings/GetIndicatorWarningById`, payload),
    AddIndicatorWarning: (payload) => Repository.post(`/api/IndicatorWarnings/AddIndicatorWarning`, payload),
    EditIndicatorWarning: (payload) => Repository.post(`/api/IndicatorWarnings/EditIndicatorWarning`, payload),
    DeleteIndicatorWarning: (payload) => Repository.post(`/api/IndicatorWarnings/DeleteIndicatorWarning`, payload),
    ChangeStatusIndicatorWarning: (payload) => Repository.post(`/api/IndicatorWarnings/ChangeStatusIndicatorWarning`, payload),
}
