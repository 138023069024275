<template>
  <div>
    <div class="header-tab">
      <p class="back-btn" @click="action('TAB', 0)">
        <img src="assets/image/icon/arrow-left.svg" class="mb-1" />Trở về
      </p>
      <template v-if="dataByID">
        <div class="item-card">
          <div class="container-fluid">
            <div class="row">
              <div class="col-lg-12">
                <div class="row">
                  <div class="col-lg-3 p-0">
                    <div
                      class="img-frame"
                      :style="`background-image: url(${dataByID.TreeImage})`"
                    ></div>
                  </div>
                  <div class="col-lg-8 p-0">
                    <strong class="router-name">
                      {{ dataByID.StationName }}
                    </strong>
                    <p
                      class="my-1 router-info"
                      :style="`color: ${dataByID.StatusColor};`"
                    >
                      <svg
                        class="mb-1"
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g id="Time_solid">
                          <g id="Group">
                            <path
                              id="Vector"
                              d="M8.00016 14.6666C4.3335 14.6666 1.3335 11.6666 1.3335 7.99992C1.3335 4.33325 4.3335 1.33325 8.00016 1.33325C11.6668 1.33325 14.6668 4.33325 14.6668 7.99992C14.6668 11.6666 11.6668 14.6666 8.00016 14.6666Z"
                              :fill="`${dataByID.StatusColor}`"
                            />
                          </g>
                        </g>
                      </svg>
                      {{ dataByID.StatusName }}
                    </p>
                    <p class="m-0 status-info">
                      <img
                        src="assets/image/icon/clock-rewind.svg"
                        class="pb-1"
                      />
                      {{ dataByID.UpdateTime }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
      <div class="route-card">
        <div class="d-flex justify-content-around text-center rail-tab">
          <div class="cursor-pointer w-100" @click="currentTab = 0">
            <div class="tab-btn" :class="{ 'is-active': currentTab === 0 }">
              Hình ảnh
            </div>
          </div>
          <div class="cursor-pointer w-100" @click="currentTab = 1">
            <div class="tab-btn" :class="{ 'is-active': currentTab === 1 }">
              Thông tin
            </div>
          </div>
          <div class="cursor-pointer w-100" @click="currentTab = 3">
            <div class="tab-btn" :class="{ 'is-active': currentTab === 3 }">
              Góp ý
            </div>
          </div>
        </div>
        <template v-if="dataByID">
          <b-tabs v-model="currentTab" nav-class="d-none" class="tab-height">
            <b-tab>
              <List :items="dataByID"></List>
            </b-tab>
            <b-tab> <Info :items="dataByID"> </Info> </b-tab>
            <b-tab><Table></Table></b-tab>
            <b-tab>
              <div class="text-center">
                <b-button
                  class="btn-changeroute py-2"
                  @click="handleShowModal"
                >
                  Viết góp ý
                </b-button>
              </div>
              <div
                v-for="(item, index) in listComment"
                :key="index"
                class="comment-card"
              >
                <div class="d-flex flex-row align-items-center mb-2">
                  <img src="assets/image/icon/avatar.svg" class="mr-2">
                  <div>
                    <strong class="font-16
                    ">{{ item.Name }}</strong>
                    <p class="m-0 font-time mt-1">{{ convertDateTimeNonSecond(item.CreateDate) }}</p>
                  </div>
                </div>
                <span class="in-16-re">{{ item.Comment }}</span>
              </div>
            </b-tab>
          </b-tabs>
        </template>
      </div>
      <b-modal
        id="feedback-modal"
        centered
        scrollable
        header-class="pt-3"
        content-class="modal-radius"
        hide-footer
        size="md"
        v-model="isShow"
      >
        <template #modal-header="{ close }">
          <div class="d-flex align-items-center w-100">
            <p v-if="dataByID" class="modal-title">Góp ý</p>
            <img
              src="/assets/image/icon/x-close.svg"
              class="ml-auto"
              @click="close"
              style="cursor: pointer"
            />
          </div>
        </template>
        <b-form @submit="onSubmit">
          <!-- <b-form-group
            id="fieldset-1"
            label="Nội dung góp ý"
            label-for="input-1"
            label-class="lable-class"
            :invalid-feedback="invalidFeedback"
            :state="state"
          >
            <b-form-select
              id="input-1"
              v-model="choseFeedback"
              :options="options"
              :state="state"
              trim
            ></b-form-select>
          </b-form-group> -->
          <b-form-group
            id="fieldset-1"
            label="Góp ý"
            label-for="input-1"
            label-class="lable-class"
            :invalid-feedback="invalidFeedback"
            :state="state"
          >
            <b-form-textarea
              id="input-1"
              rows="3"
              v-model="feedback"
              :state="state"
            ></b-form-textarea>
          </b-form-group>
          <!-- <b-form-group
            id="fieldset-1"
            label="Góp ý"
            label-for="input-1"
            label-class="lable-class"
          >
            <VueFileAgent
              :multiple="true"
              :deletable="true"
              :helpText="'Chọn hình ảnh'"
            ></VueFileAgent>
          </b-form-group> -->
          <b-button class="btn-submit" type="submit">Gửi</b-button>
        </b-form>
      </b-modal>
      <b-modal
        id="succes-modal"
        size="md"
        hide-footer
        hide-header
        centered
        scrollable
        content-class="border-radius-24"
      >
        <div class="text-center">
          <img src="/assets/image/icon/c-check 1.svg" class="mb-4" />
          <p class="succes-title mb-4">Cám ơn</p>
          <p class="mb-4" style="font-size: 16px">
            Chúng tôi đã nhận được tin nhắn của bạn. Nếu yêu cầu là khẩn cấp,
            vui lòng gọi đến số
            <strong class="font-weight-bold">(+84) 0389 150 134</strong> để nói
            chuyện với một trong những nhân viên của chúng tôi. Nếu không, chúng
            tôi sẽ liên hệ với bạn qua email trong 2 ngày làm việc.
          </p>
          <b-button
            pill
            style="background-color: #029834"
            class="px-5"
            @click="$bvModal.hide('succes-modal')"
            >ĐÃ HIỂU</b-button
          >
        </div>
      </b-modal>
      <b-modal
        id="warning-modal"
        size="md"
        hide-footer
        hide-header
        centered
        scrollable
        content-class="border-radius-24"
      >
        <div class="text-center">
          <img src="/assets/image/icon/danger.svg" height="50px" class="mb-4" />
          <!-- <p class="succes-title mb-4">Cảnh báo</p> -->
          <p class="mb-4" style="font-size: 16px">
            Vui lòng đăng nhập để có thể sử dụng tính năng này
          </p>
          <b-button
            pill
            style="background-color: #029834"
            class="px-5"
            @click="$bvModal.hide('warning-modal')"
            >ĐÃ HIỂU</b-button
          >
        </div>
      </b-modal>
    </div>
  </div>
</template>

<script>
import { server } from "@/constants/config";
import List from "./ListImage.vue";
import Info from "./TreeInfor.vue";
import Table from "@/components/table/TimelineTable";
import {convertDateTimeNonSecond} from "@/utils"
import RepositoryFactory from "@/repository/factory";

const SystemGuestComments = RepositoryFactory.get("SystemGuestComments");
export default {
  components: {
    List,
    Info,
    Table,
  },
  props: {
    dataByID: {
      type: Object,
    },
    listBusStop: {
      type: Array,
    },
    listComment: {
      type: Array,
    },
  },
  computed: {
    invalidFeedback() {
      return "Vui lòng nhập đủ thông tin được yêu cầu ";
    },
  },
  watch: {
    isShow(to) {
      if (to === false) {
        this.feedback = null;
        this.choseFeedback = null;
        this.state = null;
      }
    },
  },
  data() {
    return {
      isShow: false,
      currentTab: 0,
      feedback: "",
      choseFeedback: null,
      state: null,
      options: [
        { value: 1, text: "Tài xế" },
        { value: 2, text: "Thời gian đợi xe" },
        { value: 5, text: "Giá vế" },
        { value: 4, text: "Nhân viên" },
        { value: 3, text: "Khác" },
      ],
    };
  },
  methods: {
    handleShowModal(){
      if(!localStorage.getItem('user')){
        this.$bvModal.show('warning-modal')
        return
      }
      this.$bvModal.show('feedback-modal')
    },
    convertDateTimeNonSecond(value){
      return convertDateTimeNonSecond(value)
    },
    action(action, value) {
      this.$emit("action", action, value);
    },
    async onSubmit(event) {
      event.preventDefault();
      if (this.feedback) {
        this.state = true;
      } else {
        this.state = false;
      }
      if (!this.state) return;
      this.$bvModal.hide("feedback-modal");
      let body = {
        ServerName: server,
        OID: this.dataByID.StationID.toString(),
        GeoCode: "TREE",
        Name: JSON.parse(localStorage.getItem("user")).FullName,
        Phone: JSON.parse(localStorage.getItem("user")).Phone,
        Email: JSON.parse(localStorage.getItem("user")).Email,
        Comment: this.feedback,
        Ratings: "4.0",
      };
      let res = await SystemGuestComments.Add(body);
      if (res.status) {
        this.$bvModal.show("succes-modal");
        this.$emit("reload", this.dataByID.StationID);
      } else {
        alert("Fail");
      }
    },
  },
};
</script>
<style>
.border-radius-24 {
  border-radius: 24px !important;
}
.lable-class {
  color: var(--label-main-text-default, #27272a);
  /* Base/14px - Bold */
  font-size: 14px !important;
  font-weight: 700;
  line-height: 150%; /* 21px */
}
.modal-radius {
  border-radius: 8px !important;
}
</style>
<style scoped>
.font-time{
  color: var(--labels-primary, #000);

/* Text-sm-12/L100/12 - Regular */
font-family: Inter;
font-size: 12px;
font-style: normal;
font-weight: 400;
line-height: 100%; /* 12px */
}
.comment-card {
  border-radius: 12px;
  background: var(--bg-secondary, #f2f2f7);
  padding: 12px 16px;
  margin-bottom: 16px;
}
.router-name {
  color: var(--labels-primary, #000);
  margin-bottom: 8px !important;
  /* Medium-16/L150/16 - Bold */
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%; /* 24px */
  letter-spacing: -0.31px;
}
.font-16{
  color: var(--labels-primary, #063);
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
  letter-spacing: -0.31px;
}
.router-info {
  color: var(--label-color-primary, #000);
  font-size: 12px;
  font-weight: 700;
  line-height: 18px;
  letter-spacing: -0.4px;
}
.status-info {
  color: var(--label-color-primary, #000);
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: -0.4px;
}
.rail-tab {
  border-radius: 8px;
  background: var(--fill-color-light-tertiary, rgba(118, 118, 128, 0.12));
  margin: 0 12px;
}
.img-frame {
  margin-right: 12px !important;
  width: 69px;
  height: 72px;
  border-radius: 8px;
  background-repeat: no-repeat, repeat;
  background-position: center; /* Center the image */
  background-size: cover; /* Resize the background image to cover the entire container */
}
.item-card {
  border-radius: 12px;
  /* border: 1px solid var(--bottom-bar-border-inactive, #ebebf0); */
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05);
  background: var(--system-background-primary, #fff);
  padding: 16px;
  margin-bottom: 16px;
  transition: 0.5s;
}
.succes-title {
  color: var(--website-neutral-60, #18191a);
  /* Website & tablet/Header 1 */
  font-size: 48px;
  font-weight: 400;
  line-height: 60px; /* 125% */
}
.btn-submit {
  border-radius: 8px;
  background: var(--brand-color, #029834) !important;
  width: 100%;
}

.modal-title {
  color: var(--brand-color, #029834);

  /* X-Large/20px - Semi Bold */
  font-size: 20px;
  font-weight: 600;
  line-height: 150%; /* 30px */
}
.btn-feedback {
  background-color: white;
  color: #000;
  box-shadow: 0px 1px 4px 0px rgba(66, 71, 76, 0.12),
    0px 0px 0.5px 0px rgba(198, 204, 198, 0.32);
}
.btn-feedback:hover {
  background-color: #029834;
  color: white;
  stroke: white !important;
}
.btn-feedback:focus {
  background-color: #029834;
  color: white;
  stroke: white !important;
}
.btn-feedback:hover svg path {
  stroke: white !important;
}
.btn-feedback:focus svg path {
  stroke: white !important;
}
.tab-btn {
  padding: 3px;
  font-size: 14px;
  font-weight: 700;
  text-align: center;
}
.tab-height {
  height: calc(100vh - 270px - 67px);
  overflow-y: auto;
  padding: 16px;
}
.tab-height::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  position: absolute !important;
  transition: transform 0.3s;
}
/* .tab-height:hover {
  overflow-y: auto;
} */
.tab-height::-webkit-scrollbar-thumb {
  visibility: hidden;
}
.tab-height:hover::-webkit-scrollbar-thumb {
  visibility: visible;
}
.is-active {
  border-radius: 7px;
  border: 0.5px solid rgba(0, 0, 0, 0.04);
  background: var(--Brand, #029834);
  box-shadow: 0px 3px 1px 0px rgba(0, 0, 0, 0.04),
    0px 3px 8px 0px rgba(0, 0, 0, 0.12);
  color: #fff;
  margin: 0 5px;
}
.cursor-pointer {
  cursor: pointer;
  padding: 2px 0;
}
.route-card {
  padding-top: 12px;
  margin-top: 24px;
  border-radius: 8px;
  height: calc(100vh - 280px);
  /* App/Shadow 3 App */
  box-shadow: 0px 0px 0.5px 0px rgba(66, 71, 76, 0.32);
  background-color: #fff;
  /* overflow: auto; */
}
.btn-changeroute {
  border-radius: 12px;
  padding: 8px 12px;
  width: 100%;
  background: var(--system-background-primary, #029834);
  color: var(--website-brand-color, #fafafa);
  /* App/Shadow 1 App */
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05);
  /* stroke: #c71313 !important; */
}
.btn-changeroute:hover {
  background: var(--system-background-primary, #029834);
  color: var(--website-brand-color, #fff);
  stroke: #fff !important;
}
.btn-changeroute svg path {
  stroke: #c71313 !important;
}
.btn-changeroute:hover svg path {
  stroke: white !important;
}
.btn-changeroute:focus svg path {
  stroke: white !important;
}
.btn-changeroute:focus {
  background: var(--system-background-primary, #029834);
}
.route-name {
  color: var(--label-color-primary, #000);
  font-size: 17px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: -0.4px;
  margin-bottom: 16px !important;
}
.back-btn {
  color: var(--Brand, #029834);

  /* Medium-16/L100/16 - Regular */
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%; /* 16px */
  letter-spacing: -0.31px;
  cursor: pointer;
}
.header-tab {
  padding: 24px 16px 0;
}
.tab-title {
  margin-bottom: 4px;
  font-size: 20px;
  font-weight: 600;
  line-height: 25px;
  letter-spacing: -0.4px;
}
</style>
