import Repository from "../../repository";

export default {
    GetListIndicators: (payload) => Repository.post(`/api/Indicators/GetListIndicators`, payload),
    GetListIndicatorsActive: (payload) => Repository.post(`/api/Indicators/GetListIndicatorsActive`, payload),
    GetListIndicatorsById: (payload) => Repository.post(`/api/Indicators/GetListIndicatorsById`, payload),
    AddIndicators: (payload) => Repository.post(`/api/Indicators/AddIndicators`, payload),
    EditIndicators: (payload) => Repository.post(`/api/Indicators/EditIndicators`, payload),
    DeleteIndicators: (payload) => Repository.post(`/api/Indicators/DeleteIndicators`, payload),
    ChangeStatusIndicators: (payload) => Repository.post(`/api/Indicators/ChangeStatusIndicators`, payload),
    GetListIndicatorRanges: (payload) => Repository.post(`/api/Indicators/GetListIndicatorRanges`, payload),
    GetIndicatorRangesByID: (payload) => Repository.post(`/api/Indicators/GetIndicatorRangesByID`, payload),
    AddIndicatorRanges: (payload) => Repository.post(`/api/Indicators/AddIndicatorRanges`, payload),
    EditIndicatorRanges: (payload) => Repository.post(`/api/Indicators/EditIndicatorRanges`, payload),
    DeleteIndicatorRanges: (payload) => Repository.post(`/api/Indicators/DeleteIndicatorRanges`, payload),
}