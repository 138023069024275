import Repository from "../../repository";

export default {
    Get: (payload) => Repository.post(`/api/StationNodes/Get`, payload),
    GetActive: (payload) => Repository.post(`/api/StationNodes/GetActive`, payload),
    GetById: (payload) => Repository.post(`/api/StationNodes/GetById`, payload),
    Add: (payload) => Repository.post(`/api/StationNodes/Add`, payload),
    Edit: (payload) => Repository.post(`/api/StationNodes/Edit`, payload),
    Delete: (payload) => Repository.post(`/api/StationNodes/Delete`, payload),
    ChangeStatus: (payload) => Repository.post(`/api/StationNodes/ChangeStatus`, payload),
}
