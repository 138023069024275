import Repository from "../../repository";

export default {
    GetRadioScheduleList: (payload) => Repository.post(`/api/RadioSchedules/GetRadioScheduleList`, payload),
    GetRadioScheduleListActive: (payload) => Repository.post(`/api/RadioSchedules/GetRadioScheduleListActive`, payload),
    GetRegionRight: payload => Repository.post('/api/RadioSchedules/GetRegionRight', payload),
    GetStationRight: payload => Repository.post('/api/RadioSchedules/GetStationRight', payload),
    GetRadioScheduleById: (payload) => Repository.post(`/api/RadioSchedules/GetRadioScheduleById`, payload),
    Add: (payload) => Repository.post(`/api/RadioSchedules/Add`, payload),
    Edit: (payload) => Repository.post(`/api/RadioSchedules/Edit`, payload),
    ChangeStatus: (payload) => Repository.post(`/api/RadioSchedules/ChangeStatus`, payload),
    Delete: (payload) => Repository.post(`/api/RadioSchedules/Delete`, payload),
    UpdatePlayback: (payload) => Repository.post(`/api/RadioSchedules/UpdatePlayback`, payload),
    Volume: (payload) => Repository.post(`/api/RadioSchedules/Volume`, payload),
    VolumeByListStation: (payload) => Repository.post(`/api/RadioSchedules/VolumeByListStation`, payload),
    Get: (payload) => Repository.post(`/api/RadioSchedulesReports/Get`, payload),
    Chart: (payload) => Repository.post(`/api/RadioSchedulesReports/Chart`, payload),
    Report: (payload) => Repository.post(`/api/RadioSchedulesReports/Report`, payload),
}
