import Repository from "../../repository";

export default {
    Get: (payload) => Repository.post(`/api/TreeDataCurrents/Get`, payload),
    GetTreeCurrentDetailByID: (payload) => Repository.post(`/api/TreeDataCurrents/GetTreeCurrentDetailByID`, payload),
    Add: (payload) => Repository.post(`/api/TreeDataCurrents/Add`, payload),
    Edit: (payload) => Repository.post(`/api/TreeDataCurrents/Edit`, payload),
    Delete: (payload) => Repository.post(`/api/TreeDataCurrents/Delete`, payload),
    GetByRouteID: (payload) => Repository.post(`/api/TreeDataCurrents/GetByRouteID`, payload),
    GetMapByID: (payload) => Repository.post(`/api/TreeDataCurrents/GetMapByID`, payload),
    GetMapNow:  (payload) => Repository.post(`/api/GuestContacts/GetMapNow`, payload),
    GetMapNow_Filter:  (payload) => Repository.post(`/api/GuestContacts/GetMapNow_Filter`, payload),
    GetMapNow_ByID:  (payload) => Repository.post(`/api/GuestContacts/GetMapNow_ByID`, payload),
}
