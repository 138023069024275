<template>
	<div>
		<b-modal v-model="showModal" id="create-modal" content-class="modal-radius" centered hide-footer scrollable no-fade>
			<template #modal-header="{close}">
				<div class="d-flex align-items-center w-100">
					<p class="modal-title">Đăng ký</p>
					<img src="/assets/image/icon/x-close.svg" class="ml-auto" @click="close" style="cursor: pointer" />
				</div>
			</template>
			<b-form autocomplete="off" @submit="onSubmit">
				<b-form-group class="mb-3" id="fieldset-1" label="Tên" label-class="lable-class" invalid-feedback="Không được bỏ trống tên" :state="state">
					<b-form-input v-model="name" :state="state"></b-form-input>
				</b-form-group>
				<b-form-group class="mb-3" id="fieldset-2" label="Số điện thoại" label-class="lable-class" invalid-feedback="Không được bỏ trống sđt" :state="state">
					<b-form-input v-model="phone" :state="state"></b-form-input>
				</b-form-group>
				<b-form-group class="mb-3" id="fieldset-3" label="Email" label-class="lable-class" invalid-feedback="Không được bỏ trống Email" :state="stateEmail">
					<b-form-input autocomplete="off" v-model="email" :state="stateEmail"></b-form-input>
				</b-form-group>
				<b-form-group class="mb-3" id="fieldset-4" label="Mật khẩu" label-class="lable-class" invalid-feedback="Không được bỏ trống mật khẩu" :state="statePass">
					<b-input-group >
						<b-form-input autocomplete="off" :type="`${showPass ? 'text' : 'password'}`" v-model="pass" :state="statePass"></b-form-input>
						<div class="input-group-append">
							<span class="input-group-text py-0 px-3" :style="statePass === false ? 'border-color: #dc3545': ''" style="cursor: pointer; background-color: none !important" @click="showPass = !showPass">
								<img v-if="showPass" src="assets/image/icon/eye.svg" />
								<img v-else src="assets/image/icon/eye-off.svg" />
							</span>
						</div>
					</b-input-group>
				</b-form-group>
				<b-form-group class="mb-3" id="fieldset-5" label="Xác nhận mật khẩu" label-class="lable-class" invalid-feedback="Mật khẩu không trùng khớp" :state="statePassRe">
					<div class="input-group mb-3">
						<b-form-input autocomplete="off" :type="`${showPassRe ? 'text' : 'password'}`" v-model="passRepeat" :state="statePassRe"></b-form-input>
						<div class="input-group-append" style="border-color: red ;">
							<span class="input-group-text py-0 px-3" :style="statePassRe === false ? 'border-color: #dc3545': ''" style="cursor: pointer" @click="showPassRe = !showPassRe">
								<img v-if="showPassRe" src="assets/image/icon/eye.svg" />
								<img v-else src="assets/image/icon/eye-off.svg" />
							</span>
						</div>
					</div>
				</b-form-group>
				<p v-if="error" class="m-0 text-danger" style="font-size: medium">
					{{ error }}
				</p>
				<b-button type="submit" class="w-100 btn-submit">Đăng ký</b-button>
				<p class="mention w-100 text-center mt-3">
					Bạn đã có tài khoản?
					<span class="attribute" @click="openModalLogin">Đăng nhập</span>
				</p>
			</b-form>
		</b-modal>
	</div>
</template>

<script>
import RepositoryFactory from "@/repository/factory";
const authenticationGuest = RepositoryFactory.get("authenticationGuest");
export default {
	props: {
		isShow: {
			type: Boolean,
			default: false,
		},
	},
	watch: {
		isShow(to) {
			if (to === false) {
				this.$emit("close");
				this.$bvModal.hide("create-modal");
			} else {
				this.$bvModal.show("create-modal");
			}
		},
		showModal(to) {
			if (to === false) {
				this.$emit("close");
				this.$bvModal.hide("create-modal");
			} else {
				this.$bvModal.show("create-modal");
			}
		},
	},
	data() {
		return {
			showModal: false,
			state: null,
			statePass: null,
			statePassRe: null,
			stateEmail: null,
			name: null,
			pass: null,
			passRepeat: null,
			phone: null,
			error: "",
			email: null,
			showPass: false,
			showPassRe: false,
		};
	},
	methods: {
		openModalLogin() {
			this.$emit("openLogin");
		},
		async onSubmit(event) {
			event.preventDefault();
			if (this.name && this.phone && this.pass && this.email) {
				this.state = null;
				this.statePass = null;
				this.stateEmail = null;
			} else {
				this.state = false;
				this.statePass = false;
				this.stateEmail = false;
				return;
			}
			if (this.pass !== this.passRepeat) {
				return (this.statePassRe = false);
			}

			let body = {
				FullName: this.name,
				Phone: this.phone,
				Email: this.email,
				Password: this.pass,
			};
			let res = await authenticationGuest.Register(body);
			if (res.status) {
				this.$bvModal.hide("create-modal");
				this.$bvModal.show("succes-created-modal");
				setTimeout(() => {
					this.$bvModal.hide("succes-created-modal");
					this.openModalLogin();
				}, 3000);
			} else {
				this.error = res.message;
			}
		},
	},
};
</script>
<style>
.modal-radius {
	border-radius: 16px !important;
}
</style>
<style scoped>
.mention {
	color: var(--label-main-text-default, #27272a);

	/* Base/14px - Regular */
	font-size: 14px;
	font-weight: 400;
	line-height: 150%; /* 21px */
}
.attribute {
	color: var(--brand-color, #029834);
	cursor: pointer;
	/* Small/12px - Regular */
	font-size: 14px;
	font-weight: 400;
	line-height: 150%; /* 18px */
}
.modal-title {
	color: var(--brand-color, #029834);

	/* X-Large/20px - Semi Bold */
	font-size: 20px;
	font-weight: 600;
	line-height: 150%; /* 30px */
}
.btn-submit {
	border-radius: 8px;
	background: var(--brand-color, #029834) !important;
}
</style>
