import Axios from "axios"
import { apiUrl, defaultLocale } from "../constants/config"

const axiosInstance = Axios.create({
    baseURL: apiUrl,
    headers: {
        "Accept": "text/plain",
        "Content-Type": "application/json-patch+json",
        "Cache-Control": "no-cache",
        "Access-Control-Allow-Origin": "*"
    },
    timeout: 60000,
})

axiosInstance.interceptors.response.use(
    (response) => response,
    async (error) => {
        const originalRequest = error.config
        if (error.response.status === 401 && !originalRequest._retry) {
            originalRequest._retry = true
            let tokenRefresh = localStorage.getItem("tokenRefresh")
            if (tokenRefresh) {
                let _response = await refreshToken({refreshToken: tokenRefresh})
                let result = _response.status ? _response.data : null
                if (result) {
                    localStorage.setItem('token', result.Token)
                    localStorage.setItem('tokenRefresh', result.RefreshToken)
                } else {
                    localStorage.removeItem('token')
                    localStorage.removeItem('tokenRefresh')
                    localStorage.removeItem('user')
                }
            }
            // location.reload()
            return axiosInstance(originalRequest)
        }
        return Promise.reject(error)
    }
)

axiosInstance.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem("token") ? localStorage.getItem("token") : null
        if (token) {
            config.headers.Authorization =  `Bearer ${token}`;
        }
        const locale = (localStorage.getItem("currentLanguage") && JSON.parse(localStorage.getItem('listLanguage'))?.length > 0 && JSON.parse(localStorage.getItem('listLanguage')).filter(x => x.id === localStorage.getItem("currentLanguage")).length > 0) ? localStorage.getItem("currentLanguage") : defaultLocale;
        if (locale) {
            config.headers.Language =  locale;
        }
        return config;
    }
);

const processError = (err) => console.log(err)

const responseBody = (val) => {
    let response = val.status === 200 ? val.data : null
    if (response && response.ErrorCode === "0" && response.StatusCode === 200 && response.Result) {
        return({
            status: true,
            message: response.Message,
            data: response.Result,
        })
    } else {
        return({
            status: false,
            message: response.Message,
            data: null,
        })
    }
}

const refreshToken = (token) => Repository.post(`/api/authentication/RefreshToken`, token)

const Repository = {
    get: (url, params = {}) => axiosInstance.get(url, { params: params }).then(responseBody).catch(processError),
    post: (url, data) => axiosInstance.post(url, data).then(responseBody).catch(processError),
    put: (url, data) => axiosInstance.put(url, data).then(responseBody).catch(processError),
    delete: (url) => axiosInstance.delete(url).then(responseBody).catch(processError),
    postWithFile: (url, data) => axiosInstance.post(url, data, { headers: { "Content-Type": "multipart/form-data"} }).then(responseBody).catch(processError),
}

export default Repository