import Repository from "../../repository";

export default {
    GetListExport: (payload) => Repository.post(`/api/StationByGasFoodCourts/GetListExport`, payload),
    ExportProduct: (payload) => Repository.post(`/api/StationByGasFoodCourts/ExportProduct`, payload),
    EditDeploy: (payload) => Repository.post(`/api/StationByGasFoodCourts/EditDeploy`, payload),
    DeleteDeploy: (payload) => Repository.post(`/api/StationByGasFoodCourts/DeleteDeploy`, payload),
    GetById: (payload) => Repository.post(`/api/StationByGasFoodCourts/GetById`, payload),
    Get: (payload) => Repository.post(`/api/StationByGasFoodCourts/Get`, payload),
    Delete: (payload) => Repository.post(`/api/StationByGasFoodCourts/Delete`, payload),
    ChangeStatus: (payload) => Repository.post(`/api/StationByGasFoodCourts/ChangeStatus`, payload),
}
