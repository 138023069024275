import Repository from "../../repository";

export default {
    GetListExport: (payload) => Repository.post(`/api/StationByRestaurents/GetListExport`, payload),
    ExportProduct: (payload) => Repository.post(`/api/StationByRestaurents/ExportProduct`, payload),
    EditDeploy: (payload) => Repository.post(`/api/StationByRestaurents/EditDeploy`, payload),
    DeleteDeploy: (payload) => Repository.post(`/api/StationByRestaurents/DeleteDeploy`, payload),
    GetById: (payload) => Repository.post(`/api/StationByRestaurents/GetById`, payload),
    Get: (payload) => Repository.post(`/api/StationByRestaurents/Get`, payload),
    Delete: (payload) => Repository.post(`/api/StationByRestaurents/Delete`, payload),
    ChangeStatus: (payload) => Repository.post(`/api/StationByRestaurents/ChangeStatus`, payload),
}
