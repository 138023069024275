import Repository from "../../repository";

export default {
    Get: (payload) => Repository.post(`/api/LedStations/Get`, payload),
    GetActive: (payload) => Repository.post(`/api/LedStations/GetActive`, payload),
    GetByID: (payload) => Repository.post(`/api/LedStations/GetByID`, payload),
    Edit: (payload) => Repository.post(`/api/LedStations/Edit`, payload),
    Delete: (payload) => Repository.post(`/api/LedStations/Delete`, payload),
    ChangeStatus: (payload) => Repository.post(`/api/LedStations/ChangeStatus`, payload),
    GetExport: (payload) => Repository.post(`/api/LedStations/GetExport`, payload),
    ExportProduct: (payload) => Repository.post(`/api/LedStations/ExportProduct`, payload),
}
