import Repository from "../../repository";

export default {
    Get: (payload) => Repository.post(`/api/RadioRegions/Get`, payload),
    GetByLevel: (payload) => Repository.post(`/api/RadioRegions/GetByLevel`, payload),
    GetByParentID: (payload) => Repository.post(`/api/RadioRegions/GetByParentID`, payload),
    GetActive: (payload) => Repository.post(`/api/RadioRegions/GetActive`, payload),
    GetById: (payload) => Repository.post(`/api/RadioRegions/GetById`, payload),
    Add: (payload) => Repository.post(`/api/RadioRegions/Add`, payload),
    Edit: (payload) => Repository.post(`/api/RadioRegions/Edit`, payload),
    ChangeStatus: (payload) => Repository.post(`/api/RadioRegions/ChangeStatus`, payload),
    Delete: (payload) => Repository.post(`/api/RadioRegions/Delete`, payload),
}
