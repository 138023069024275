import Repository from "../../repository";

export default {
    GetCurrentMaintenance: (payload) => Repository.post(`/api/ProcessRequestVehicles/GetCurrentMaintenance`, payload),
    Get: (payload) => Repository.post(`/api/ProcessRequestVehicles/Get`, payload),
    GetHandoverLicensePlate: (payload) => Repository.post(`/api/ProcessRequestVehicles/GetHandoverLicensePlate`, payload),
    GetEvictionLicensePlateByDriverID: (payload) => Repository.post(`/api/ProcessRequestVehicles/GetEvictionLicensePlateByDriverID`, payload),
    GetVehicleByDriverID: (payload) => Repository.post(`/api/ProcessRequestVehicles/GetVehicleByDriverID`, payload),
    GetById: (payload) => Repository.post(`/api/ProcessRequestVehicles/GetById`, payload),
    Edit: (payload) => Repository.post(`/api/ProcessRequestVehicles/Edit`, payload),
    EditEvict: (payload) => Repository.post(`/api/ProcessRequestVehicles/EditEvict`, payload),
    ProcessReceive: (payload) => Repository.post(`/api/ProcessRequestVehicles/ProcessReceive`, payload),
    ProcessComplete: (payload) => Repository.post(`/api/ProcessRequestVehicles/ProcessComplete`, payload),
}
