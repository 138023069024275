<template>
	<div>
		<div class="">
			<div class="page-header clear-filter" style="min-height: 640px">
				<parallax class="page-header-image" style="background-image: url('/assets/image/bg/bg_1.png')"> </parallax>
				<div class="container px-0 d-flex justify-content-center align-items-center h-100">
					<div class="title-content">
						<h1 class="font-weight-bold text-uppercase ma-56-semi m-0">
							{{ $t("feature") }}
						</h1>
						<h3 class="mb-4 mx-auto in-20-re">
							{{ $t("feature-description") }}
						</h3>
					</div>
				</div>
			</div>
		</div>
		<Feature></Feature>
	</div>
</template>
<script>
import Feature from "./components/FeatureForm.vue";
export default {
	name: "feature",
	bodyClass: "feature-page",
	components: {
		Feature,
	},
	created() {
		localStorage.setItem("currentUrl", "HOME");
		window.addEventListener("scroll", this.handleScroll);
	},
	unmounted() {
		window.removeEventListener("scroll", this.handleScroll);
	},
	methods: {
		handleScroll(value) {
			let reveals = document.querySelectorAll(".reveal");
			let height = window.innerHeight;
			for (let i = 0; i < reveals.length; i++) {
				let windowHeight = window.innerHeight;
				let elementTop = reveals[i].getBoundingClientRect().top;
				let elementVisible = 150;

				if (elementTop < windowHeight - elementVisible) {
					reveals[i].classList.add("active");
				} else {
					reveals[i].classList.remove("active");
				}
			}
		},
	},
};
</script>
<style scoped>
.space {
	margin-bottom: 120px;
}
.title-content {
	margin: 120px 0 0 0;
	text-align: center;
}
.parallax-height {
	min-height: 100vh;
	max-height: 999px;
	padding: 0;
	color: #ffffff;
	position: relative;
	overflow: hidden;
}
</style>
