import Repository from "../../repository";

export default {
    Get: (payload) => Repository.post(`/api/WaterwaySign/Get`, payload),
    GetActive: (payload) => Repository.post(`/api/WaterwaySign/GetActive`, payload),
    GetById: (payload) => Repository.post(`/api/WaterwaySign/GetById`, payload),
    Add: (payload) => Repository.post(`/api/WaterwaySign/Add`, payload),
    Edit: (payload) => Repository.post(`/api/WaterwaySign/Edit`, payload),
    Delete: (payload) => Repository.post(`/api/WaterwaySign/Delete`, payload),
    ChangeStatus: (payload) => Repository.post(`/api/WaterwaySign/ChangeStatus`, payload),
}
