import Repository from "../../repository";

export default {
    Get: (payload) => Repository.post(`/api/GasItemGroups/Get`, payload),
    GetActive: (payload) => Repository.post(`/api/GasItemGroups/GetActive`, payload),
    GetById: (payload) => Repository.post(`/api/GasItemGroups/GetById`, payload),
    Add: (payload) => Repository.post(`/api/GasItemGroups/Add`, payload),
    Edit: (payload) => Repository.post(`/api/GasItemGroups/Edit`, payload),
    Delete: (payload) => Repository.post(`/api/GasItemGroups/Delete`, payload),
    ChangeStatus: (payload) => Repository.post(`/api/GasItemGroups/ChangeStatus`, payload),
}