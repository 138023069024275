<template>
	<div style="margin: 120px auto 120px">
		<div class="custom-container">
			<div class="row card-content">
				<div class="col-lg-6 col-md-12">
					<h2 class="text-green ma-28-semi mb-0">Sở xây dựng Thành phố Cần Thơ</h2>
					<p class="mb-1 mt-3"><b class="in-16-semi">Địa chỉ:</b> 25 Ngô Hữu Hạnh, Phường Tân An, Quận Ninh Kiều, TP Cần Thơ</p>
					<p class="mb-1 mt-3"><b class="in-16-semi">Điện thoại:</b> 02923820335</p>
          <p class="mb-1 mt-3"><b class="in-16-semi">Email:</b> soxd@cantho.gov.vn</p>
				</div>
				<div class="col-lg-6 col-md-12 pr-0">
					<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3928.758442055832!2d105.785862!3d10.036781399999997!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31a062a13a56bf09%3A0x6d0c773ec56fbf82!2zU-G7nyBYw6J5IEThu7FuZyB0aMOgbmggcGjhu5EgQ-G6p24gVGjGoQ!5e0!3m2!1svi!2s!4v1693298695011!5m2!1svi!2s" style="border: 0; height: 531px; width: 100%" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
export default {};
</script>
<style scoped>
.title-block {
	font-size: 16px;
	color: #636466;
	width: 50%;
}
.title-block {
	font-size: 12px;
	font-weight: 300;
	color: #636466;
	width: 75%;
}
.title-secondary {
	font-size: 16px;
	font-weight: 300;
	color: #636466;
	width: 75%;
	margin-bottom: 40px !important;
}
.content-slogan {
	color: #c71313;
	font-size: 48px !important;
	width: 50%;
	margin: 16px 0 !important;
}
.card-content {
	overflow: hidden;
}
</style>
