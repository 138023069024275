<template>
  <div>
    <div class="page-header clear-filter" style="min-height: 640px">
      <parallax
        class="page-header-image"
        style="background-image: url('/assets/image/bg/bg_1.png')"
      >
      </parallax>
      <div
        class="container px-0 d-flex justify-content-center align-items-center h-100 mt-4"
      >
        <div class="title-content">
          <h3 class="ma-56-semi m-0 text-uppercase">{{ $t('user-guideline') }}</h3>
          <p class="in-20-re">
            {{ $t('guideline-description') }}
          </p>
        </div>
      </div>
    </div>
    <div class="custom-container" style="margin-top: 120px;margin-bottom: 120px;">
      <router-view />
    </div>
  </div>
</template>
<script>
export default {
  name: "introduce",
  bodyClass: "introduce-page",
  components: {},
};
</script>
<style scoped>
.font-64 {
  font-size: 64px;
}
.font-24 {
  font-size: 24px;
}
.title-block {
  font-size: 12px;
  font-weight: 300;
  color: #636466;
  width: 75%;
}
.title-secondary {
  font-size: 16px;
  font-weight: 300;
  color: #636466;
  width: 75%;
  margin-bottom: 40px !important;
}
.content-slogan {
  color: #c71313;
  font-size: 48px !important;
  width: 50%;
  margin: 16px 0 !important;
}
.content-card {
  border: 1px solid #e6e8ec;
  border-radius: 24px;
  overflow: hidden;
  transition: 500ms;
  cursor: pointer;
}
.content-card p {
  height: 100px;
  text-overflow: ellipsis;
  overflow: hidden;
}
.content-card:hover {
  box-shadow: 0px 40px 32px -24px rgba(15, 15, 15, 0.12);
}
.title-content {
  margin: 120px 0 0 0;
  text-align: center;
}
</style>
