import Repository from "../../repository";

export default {
    Get: (payload) => Repository.post(`/api/FireLocationDetails/Get`, payload),
    GetActive: (payload) => Repository.post(`/api/FireLocationDetails/GetActive`, payload),
    GetType1: (payload) => Repository.post(`/api/FireLocationDetails/GetType1`, payload),
    GetType2: (payload) => Repository.post(`/api/FireLocationDetails/GetType2`, payload),
    GetById: (payload) => Repository.post(`/api/FireLocationDetails/GetById`, payload),
    Add: (payload) => Repository.post(`/api/FireLocationDetails/Add`, payload),
    Edit: (payload) => Repository.post(`/api/FireLocationDetails/Edit`, payload),
    EditNameLocationDetails: (payload) => Repository.post(`/api/FireLocationDetails/EditNameLocationDetails`, payload),
    Delete: (payload) => Repository.post(`/api/FireLocationDetails/Delete`, payload),
    ChangeStatus: (payload) => Repository.post(`/api/FireLocationDetails/ChangeStatus`, payload),
}